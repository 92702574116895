import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_DROPDOWN_LIST_REQUEST,
  CLIENT_DROPDOWN_LIST_SUCCESS,
  CLIENT_DROPDOWN_LIST_FAIL,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAIL,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  CLIENT_DETAILS_REQUEST_CLEAN,
  CONTACTS_LIST_CLEAN,
  CONTACTS_LIST_FAIL,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_LIST_REQUEST,
  CLIENT_TRANSPORTATION_DROPDOWN_LIST_FAIL,
  CLIENT_TRANSPORTATION_DROPDOWN_LIST_SUCCESS,
  CLIENT_TRANSPORTATION_DROPDOWN_LIST_REQUEST,
} from '../constants/clientConstants';

export const clientListReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return { loading: true, clients: [] };

    case CLIENT_LIST_SUCCESS:
      return { loading: false, clients: action.payload };

    case CLIENT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const contactsListReducer = (state = { contactsList: [] }, action) => {
  switch (action.type) {
    case CONTACTS_LIST_REQUEST:
      return { loading: true, contactsList: [] };

    case CONTACTS_LIST_SUCCESS:
      return { loading: false, contactsList: action.payload };

    case CONTACTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    
      case CONTACTS_LIST_CLEAN:
        return { loading: false, contactsList: [] };

    default:
      return state;
  }
};

export const clientDropdownListReducer = (state = { clients: [] }, action) => {
  switch (action.type) {
    case CLIENT_DROPDOWN_LIST_REQUEST:
      return { loading: true, clients: [] };

    case CLIENT_DROPDOWN_LIST_SUCCESS:
      return { loading: false, clients: action.payload };

    case CLIENT_DROPDOWN_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const clientDropdownTransportationListReducer = (state = { transportation: [] }, action) => {
  switch (action.type) {
    case CLIENT_TRANSPORTATION_DROPDOWN_LIST_REQUEST:
      return { loading: true, transportation: [] };

    case CLIENT_TRANSPORTATION_DROPDOWN_LIST_SUCCESS:
      return { loading: false, transportation: action.payload };

    case CLIENT_TRANSPORTATION_DROPDOWN_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const clientDetailsReducer = (state = { client: [] }, action) => {
  switch (action.type) {
    case CLIENT_DETAILS_REQUEST:
      return { loading: true, ...state };

    case CLIENT_DETAILS_SUCCESS:
      return { loading: false, client: action.payload };

    case CLIENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    
    case CLIENT_DETAILS_REQUEST_CLEAN:
      return { ...state, client: [] };

    default:
      return state;
  }
};

export const clientCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_CREATE_REQUEST:
      return { loading: true };

    case CLIENT_CREATE_SUCCESS:
      return { loading: false, client: action.payload };

    case CLIENT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const clientDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_DELETE_REQUEST:
      return { loading: true };

    case CLIENT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case CLIENT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
