/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Text, Container, Button, Flex, IconButton } from '@chakra-ui/react';

import {
  approveTripRequest,
  approveTripRequestByTraffic,
  completeTripRequest,
  deleteTripRequest,
  getTripRequestDetails,
} from '../actions/tripRequestActions';
import EditTripRequestDetails from './EditTripRequestDetails';
import SureModal from './SureModal';
import TripDetails from './tripRequest/TripDetail';

const TripRequestDetails = (props) => {
  const navigate = useNavigate();

  const { isOpen, onClose, id, postUpdateCallback } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [isCreateTripLoading, setCreateTripLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editDetail, setEditDetail] = useState(false);

  useEffect(() => {
    if (!id && !isOpen) return;
    dispatch(getTripRequestDetails(id));
  }, [id, isOpen]);

  const handleAprovedByMC = async () => {
    try {
      await dispatch(approveTripRequest(id, true));
      await dispatch(getTripRequestDetails(id));
      toast.success('Viaje aprobado exitosamente');
    } catch (error) {
      console.error('Error updating trip request:', error);
      toast.error('Oh no! Hubo un error 😢');
    } finally {
      postUpdateCallback();
    }
  };
  const handleAprovedByTraffic = async () => {
    try {
      await dispatch(approveTripRequestByTraffic(id, true));
      await dispatch(getTripRequestDetails(id));
      toast.success('Viaje aprobado exitosamente');
    } catch (error) {
      console.error('Error updating trip request:', error);
      toast.error('Oh no! Hubo un error 😢');
    } finally {
      postUpdateCallback();
    }
  };

  const createTripHandler = async () => {
    try {
      setCreateTripLoading(true);
      await dispatch(completeTripRequest(id));
      toast.success('Viaje enviado exitosamente');
    } catch (error) {
      console.error('Error completing trip request:', error);
      toast.error('Oh no! Hubo un error 😢');
    } finally {
      setCreateTripLoading(false);
    }
  };
  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const deleteHandler = async () => {
    await dispatch(deleteTripRequest(id));
    setShowDeleteModal(false);
    onClose();
    setEditDetail(false);
    navigate('/');
  };

  const tripRequestDetails = useSelector((state) => state.tripRequestDetails);
  const { tripRequest } = tripRequestDetails;
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  const hasSupplierCoordination = userDetails?.roles.some((role) => role.id_verbose === 'supplier_coordination');

  const hasClientCoordination = userDetails?.roles.some((role) => role.id_verbose === 'client_coordination');

  return (
    <Container
      maxWidth={'100%'}
      boxShadow={'md'}
      border="1px solid"
      borderColor={'gray.200'}
      py={{ base: 2, lg: 4 }}
      mx={0}
      borderRadius={'lg'}
      height={{ lg: '570px' }}
      overflowY={'scroll'}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Box
        borderBottom={'1px solid'}
        borderColor="gray.200"
        pr={0}
        pb={2}
        m={0}
        display={{ base: 'block', sm: 'flex' }}
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex alignItems={'center'} gap={2}>
          <Text fontWeight={'normal'} color="gray.800" fontSize={20} m={0} p={0}>
            {!editDetail ? 'Detalles del Viaje' : 'Editar viaje'}
          </Text>
        </Flex>
        {!editDetail && (
          <Flex justify={{ base: 'flex-end', sm: 'space-between' }} m={0} gap={1}>
            {hasClientCoordination &&
              (!tripRequest?.approved_by ? (
                <Button
                  colorScheme="blue"
                  onClick={handleAprovedByMC}
                  width="100px"
                  p={{ base: 0, md: 3 }}
                  size={{ base: 'xs', md: 'sm' }}
                  mb={0}
                  cy="aproved-trip-detail-button"
                >
                  Aprobar MC
                </Button>
              ) : (
                <Button
                  colorScheme="blue"
                  onClick={createTripHandler}
                  isDisabled={isCreateTripLoading || !tripRequest?.assigned_transporter}
                  width="105px"
                  size={{ base: 'xs', md: 'sm' }}
                  p={{ base: 0, md: 3 }}
                  mb={0}
                >
                  Mandar a TMS
                </Button>
              ))}
            {hasSupplierCoordination && !tripRequest?.is_approved_by_traffic && (
              <Button
                colorScheme="blue"
                onClick={handleAprovedByTraffic}
                width="120px"
                size={{ base: 'xs', md: 'sm' }}
                p={{ base: 0, md: 3 }}
                mb={0}
                cy="aproved-trip-detail-button"
              >
                Aprobar Tráfico
              </Button>
            )}

            <Button
              width="80px"
              size={{ base: 'xs', md: 'sm' }}
              color="orange.400"
              borderColor={'orange.400'}
              variant={'outline'}
              borderWidth={'2px'}
              p={{ base: 0, md: 3 }}
              mb={0}
              leftIcon={<EditIcon />}
              cy="edit-trip-detail-button"
              onClick={() => setEditDetail(true)}
            >
              Editar
            </Button>

            <IconButton
              color="red.500"
              borderColor={'red.500'}
              variant={'outline'}
              onClick={handleOpenDeleteModal}
              size={{ base: 'xs', md: 'sm' }}
              borderWidth={'2px'}
              cy="delete-trip-detail-button"
              p={{ base: 0, md: 3 }}
              icon={<DeleteIcon />}
            />
          </Flex>
        )}
      </Box>
      <Box p={0} width="100%" m={0}>
        {editDetail ? (
          <EditTripRequestDetails id={id} setEditDetail={setEditDetail} openModal={handleOpenDeleteModal} />
        ) : (
          <TripDetails
            id={id}
            postUpdateCallback={postUpdateCallback}
            isLoading={isLoading}
            setIsloading={setIsloading}
          />
        )}
      </Box>
      <SureModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={deleteHandler}
        title="Confirm Delete"
        message="Are you sure you want to delete this trip?"
      />
    </Container>
  );
};

export default TripRequestDetails;
