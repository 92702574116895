import React from 'react';

import { Box, Flex, Spacer } from '@chakra-ui/react';

import Footer from '../../Footer';
import LogoIcon from '../../icons/LogoIcon';
const AppLAyoutUnprotected = ({ children }) => {
  const maxWidth = '1500px';
  return (
    <Box
      position={'relative'}
      w={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      m={0}
      p={0}
      overflowY="hidden"
      height={'100vh'}
    >
      <Box as="header" w="100%" borderBottom="1px solid" bg="#F5F5F5" borderColor="gray.200">
        <Flex px={20} py={5} maxWidth={maxWidth} margin="auto">
          <LogoIcon />
          <Spacer />
        </Flex>
      </Box>
      <Box
        as="main"
        maxHeight="100%"
        w="100%"
        maxWidth={maxWidth}
        height={'100vh'}
        m={0}
        px={5}
        overflowY="auto"
        sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      >
        {children}
      </Box>
      <Box as="footer" w="100%">
        <Footer />
      </Box>
    </Box>
  );
};

export default AppLAyoutUnprotected;
