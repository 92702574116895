/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Text, Container, Flex, IconButton, Button } from '@chakra-ui/react';

import Message from '../Message';
import SureModal from '../SureModal';
import CommentsDetailTrip from '../tripRequest/CommentsDetailTrip';
import DetailSkeleton from '../utils/skeletons.jsx/DetailSkeleton';
import CopyTextTooltip from '../utils/tooltips/CopyTextTooltip';
export const ContanerText = ({ title, description, description2, cy, id }) => {
  return (
    <Box
      display={{ base: 'flex' }}
      alignItems={'flex-start'}
      justifyContent={{ base: 'flex-start' }}
      gap={2}
      m={0}
      p={0}
      pb={1}
      mt={2}
      cy={cy}
      id={id}
    >
      <Text fontSize={{ base: 'xs', lg: 'sm' }} fontWeight="normal" color="gray.900" m={0} p={0}>
        {title}
      </Text>
      <Text
        fontWeight={{ base: 'bold', lg: 'bold' }}
        fontSize={{ base: 'xs', lg: 'sm' }}
        color="gray.900"
        m={0}
        p={0}
        ml={{ base: 0, lg: 2 }}
        display={'flex'}
        flexDirection={'column'}
        alignContent={'flex-start'}
        justifyContent={'flex-start'}
        cy={`${cy}-description`}
      >
        {description}
        {description2 && (
          <Text fontWeight="normal" fontSize="xs" m={0} p={0} ml={1}>
            {description2}
          </Text>
        )}
      </Text>
    </Box>
  );
};

const AvailabilityDetails = (props) => {
  const availabilitiyDetail = useSelector((state) => state.availabilityDetail);
  const { availability, error, loading } = availabilitiyDetail;
  const { id, deleteHandler, handleOpenDeleteModal, showDeleteModal, setShowDeleteModal, setEditDetail } = props;
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  return (
    <Container
      maxWidth={'100%'}
      boxShadow={'md'}
      border="1px solid"
      borderColor={'gray.200'}
      py={{ base: 2, lg: 4 }}
      mx={0}
      borderRadius={'lg'}
      height={{ lg: '570px' }}
      overflowY={'scroll'}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <Box
        borderBottom={'1px solid'}
        borderColor="gray.200"
        pr={0}
        pb={2}
        m={0}
        display={{ base: 'block', sm: 'flex' }}
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontWeight={'normal'} color="gray.800" fontSize={20} m={0} p={0}>
          Detalles del Viaje
        </Text>
        <Flex gap={2}>
          <Button
            width="80px"
            size={{ base: 'xs', md: 'sm' }}
            color="orange.400"
            borderColor={'orange.400'}
            variant={'outline'}
            borderWidth={'2px'}
            p={{ base: 0, md: 3 }}
            mb={0}
            leftIcon={<EditIcon />}
            cy="edit-trip-detail-button"
            onClick={() => setEditDetail(true)}
          >
            Editar
          </Button>

          <IconButton
            color="red.500"
            borderColor={'red.500'}
            variant={'outline'}
            onClick={handleOpenDeleteModal}
            size={{ base: 'xs', md: 'sm' }}
            borderWidth={'2px'}
            cy="delete-trip-detail-button"
            p={{ base: 0, md: 3 }}
            icon={<DeleteIcon />}
          />
        </Flex>
      </Box>
      <Box p={0} width="100%" h="100%" m={0}>
        <Container mt={4} width="100%" pl={{ base: 0, lg: 2 }}>
          {error ? <Message variant="error">{error}</Message> : null}
          {!availability ? (
            <Message variant="info">No trip details available.</Message>
          ) : loading ? (
            <DetailSkeleton />
          ) : (
            <Box
              h="100%"
              pl={{ base: 0, lg: 4 }}
              mt={0}
              pt={0}
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={'column'}
              flex={1}
              width={'100%'}
            >
              <Flex
                flexDirection={{ base: 'column' }}
                alignItems={{
                  base: 'flex-start',
                }}
                justifyContent={{
                  base: 'flex-start',
                }}
                gap={{ base: 1, lg: 0 }}
                borderBottom={'2px solid'}
                borderColor="gray.200"
                mb={3}
                pb={6}
                h="100%"
              >
                <Box m={0} p={0}>
                  {userDetails?.is_staff && (
                    <ContanerText title="ID interno: " description={<CopyTextTooltip text={id} />} cy="id" />
                  )}
                  <ContanerText
                    title="Cliente: "
                    description={availability?.channel?.client?.name}
                    cy="client"
                    id={id}
                  />
                  <ContanerText title="Fecha:" description={availability?.created_at || '-'} cy="created_at" />
                  <ContanerText
                    title="Fecha Disponible:"
                    description={availability?.start_date || '-'}
                    cy="start_date"
                  />
                  <ContanerText
                    title="Hora Disponible:"
                    description={availability?.start_time || '-'}
                    cy="start_time"
                  />
                </Box>
                <Box m={0} p={0}>
                  <ContanerText cy="origin" title="Origen: " description={availability?.origin?.name || '-'} />
                  <ContanerText
                    cy="destinations"
                    title="Destino: "
                    description={availability?.destinations || '-'}
                  />
                  <ContanerText title="Unidad: " description={availability?.unit_type?.name || '-'} />
                </Box>
              </Flex>

              <CommentsDetailTrip
                availability={availability}
                id={id}
                isLoading={props.isLoading}
                setIsloading={props.setIsloading}
              />
            </Box>
          )}
        </Container>
      </Box>
      <SureModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={deleteHandler}
        title="Confirm Delete"
        message="Are you sure you want to delete this trip?"
      />
    </Container>
  );
};

export default AvailabilityDetails;
