import axios from 'axios';

import { BASE_URL } from '../appSettings';
import {
  UNIT_TYPE_DROPDOWN_LIST_REQUEST,
  UNIT_TYPE_DROPDOWN_LIST_SUCCESS,
  UNIT_TYPE_DROPDOWN_LIST_FAIL,
  UNIT_TYPE_CREATE_REQUEST,
  UNIT_TYPE_CREATE_SUCCESS,
  UNIT_TYPE_CREATE_FAIL,
} from '../constants/unitTypeConstants';

export const listUnitTypesDropdown = () => async (dispatch, getState) => {
  try {
    dispatch({ type: UNIT_TYPE_DROPDOWN_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const params = { company_origin: true };
    const { data } = await axios.get(`${BASE_URL}/api/v1/unit-types/dropdown/`, {
      ...config,
      params: {
        ...params,
      },
    });

    dispatch({
      type: UNIT_TYPE_DROPDOWN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UNIT_TYPE_DROPDOWN_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const createUnitType = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UNIT_TYPE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(`${BASE_URL}/api/v1/unit-types/dropdown/`, formData, config);
    dispatch({
      type: UNIT_TYPE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UNIT_TYPE_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
