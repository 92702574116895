import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';

import { EmailIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Link as ChakraLink,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { logout } from '../actions/userActions.js';
import LogoLightIcon from './icons/LogoLightIcon.jsx';

function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  const hasSupplierCoordination = userDetails?.roles.some((role) => role.id_verbose === 'supplier_coordination');
  const hasClientCoordination = userDetails?.roles.some((role) => role.id_verbose === 'client_coordination');

  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <Box
      as="header"
      style={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000,
        borderBottomWidth: '2px',
        backgroundColor: '#2C59A6',
        height: '70px',
      }}
    >
      <Container
        px={4}
        display={'flex'}
        alignItems={'center'}
        justifyContent="space-between"
        flexDirection={'row'}
        h={'100%'}
        w="100%"
        bg="blue.500"
        maxWidth={'1360px'}
      >
        <Flex alignItems={'center'} flexDirection="row" gap={1}>
          <ChakraLink as={ReactRouterLink} to="/" variant="link" textDecoration="none" m={0} color="white">
            <LogoLightIcon />
          </ChakraLink>
          {userDetails ? (
            <Text
              fontWeight={'normal'}
              color="white"
              p={0}
              m={0}
              ml={4}
              mt={1.5}
              display={['none', 'none', 'none', 'block']}
            >
              {userDetails?.company?.name}
            </Text>
          ) : null}
        </Flex>
        <Flex>
          <Flex display={['none', 'none', 'none', 'flex']} m={0}>
            {userDetails?.is_staff && (
              <Text
                color="white"
                p={0}
                m={0}
                mr={6}
                mt={2}
                alignContent={'center'}
                alignItems={'center'}
                fontWeight={'medium'}
                _hover={{ color: 'blue.400' }}
              >
                <ChakraLink
                  as={ReactRouterLink}
                  to="/admin"
                  _hover={{
                    color: 'blue.50',
                  }}
                >
                  <EmailIcon mr="5px" mb={1} />
                  Mensajes
                </ChakraLink>
              </Text>
            )}

            {hasSupplierCoordination && (
              <>
                <Text
                  color="white"
                  p={0}
                  m={0}
                  mr={6}
                  mt={2}
                  alignContent={'center'}
                  alignItems={'center'}
                  fontWeight={'medium'}
                  _hover={{ color: 'blue.400' }}
                >
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/bid-request/set"
                    _hover={{
                      color: 'blue.50',
                    }}
                  >
                    Bid Requests
                  </ChakraLink>
                </Text>
                <Text
                  color="white"
                  p={0}
                  m={0}
                  mr={6}
                  mt={2}
                  alignContent={'center'}
                  alignItems={'center'}
                  fontWeight={'medium'}
                  _hover={{ color: 'blue.400' }}
                >
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/availabilities"
                    _hover={{
                      color: 'blue.50',
                    }}
                  >
                    Disponibilidades
                  </ChakraLink>
                </Text>
              </>
            )}
          </Flex>

          <Box display={['block', 'block', 'block', 'block']}>
            <Popover isOpen={isOpen} placement="bottom" onClose={onClose} cy="hamburger-button">
              <PopoverTrigger>
                <IconButton
                  display={['flex', 'flex', 'flex', 'flex']}
                  colorScheme="white"
                  aria-label="Menu"
                  fontSize={30}
                  icon={<HamburgerIcon />}
                  onClick={onOpen}
                />
              </PopoverTrigger>

              <PopoverContent>
                <PopoverArrow />
                <PopoverHeader>
                  {userDetails ? (
                    <Text
                      fontWeight={'normal'}
                      color="gray.800"
                      p={0}
                      m={0}
                      mt={1.5}
                      display={['block', 'block', 'block', 'none']}
                    >
                      {userDetails?.company?.name}
                    </Text>
                  ) : null}
                  <Text
                    color="gray.800"
                    p={0}
                    m={0}
                    mt={2}
                    alignContent={'center'}
                    alignItems={'center'}
                    fontWeight={'medium'}
                  >
                    {hasClientCoordination && 'Mesa de Control'}
                    {hasSupplierCoordination && 'Tráfico'}
                  </Text>
                  <Text
                    color="gray.800"
                    p={0}
                    m={0}
                    mt={2}
                    alignContent={'center'}
                    alignItems={'center'}
                    fontWeight={'medium'}
                    mb={3}
                  >
                    {userDetails?.email}
                  </Text>
                </PopoverHeader>

                <PopoverBody>
                  {userDetails?.is_staff && (
                    <Text
                      color="blue.500"
                      p={0}
                      m={0}
                      mr={6}
                      mt={3}
                      mb={3}
                      alignContent={'center'}
                      alignItems={'center'}
                      fontWeight={'medium'}
                      textDecoration={'underline'}
                      _hover={{ color: 'gray' }}
                      display={['block', 'block', 'block', 'none']}
                    >
                      <ChakraLink
                        as={ReactRouterLink}
                        to="/admin"
                        _hover={{
                          color: 'blue.200',
                        }}
                      >
                        Mensajes
                      </ChakraLink>
                    </Text>
                  )}
                  {hasSupplierCoordination && (
                    <>
                      <Text
                        color="gray.900"
                        p={0}
                        m={0}
                        mr={6}
                        mt={2}
                        mb={4}
                        alignContent={'center'}
                        alignItems={'center'}
                        fontWeight={'medium'}
                        _hover={{ color: 'gray' }}
                        display={['block', 'block', 'block', 'none']}
                      >
                        <ChakraLink as={ReactRouterLink} to="/bid-request/set">
                          Bid Requests
                        </ChakraLink>
                      </Text>
                      <Text
                        color="gray.900"
                        p={0}
                        m={0}
                        mr={6}
                        mt={2}
                        mb={4}
                        alignContent={'center'}
                        alignItems={'center'}
                        fontWeight={'medium'}
                        _hover={{ color: 'gray' }}
                        display={['block', 'block', 'block', 'none']}
                      >
                        <ChakraLink as={ReactRouterLink} to="/availabilities">
                          Disponibilidades
                        </ChakraLink>
                      </Text>
                    </>
                  )}
                  <Text
                      color="blue.500"
                      p={0}
                      m={0}
                      mr={6}
                      mt={3}
                      mb={3}
                      alignContent={'center'}
                      alignItems={'center'}
                      fontWeight={'medium'}
                      _hover={{ color: 'gray' }}
                      display={['block', 'block', 'block', 'block']}
                    >
                      <ChakraLink as={ReactRouterLink} 
                        to="/change-password"
                        _hover={{
                          color: 'blue.200',
                        }}
                      >
                        Cambiar la contraseña
                      </ChakraLink>
                  </Text>
                  <Button
                    variant="ghost"
                    color="blue.500"
                    p={0}
                    m={0}
                    onClick={logoutHandler}
                    size={'sm'}
                    _hover={{ color: 'blue.800', bg: 'none' }}
                  >
                    <i className="fas fa-sign-out-alt" style={{ marginRight: '5px' }}></i>
                    Logout
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

export default Header;
