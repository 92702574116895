/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { HiOutlineTruck } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';

import { assignTripRequest, getTripRequestSupplierMessages } from '../../actions/tripRequestActions';
import Loader from '../Loader';
import { assingProcesStatuses } from '../utils/AssingProcesStatuses';
import ModalContainer from '../utils/ModalContainer';

const customStyles = {
  backgroundColor: 'white',
  color: 'darkblue',
  // Add more custom styles as needed
};

const CardSupplier = ({ name = '', status = '', onHandle, shouldSend }) => {
  const canAssign = shouldSend && status === 'accepted';
  return (
    <Box
      color="gray.800"
      m={0}
      p={0}
      border="2px solid"
      borderColor="gray.200"
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      borderRadius={'sm'}
      py={2}
      px={5}
      h={'55px'}
      mt={1}
    >
      <Text fontSize="md" m={0} p={0} fontWeight="normal" color="gray.800">
        {name}
      </Text>

      <Flex flexDirection={'column'} justifyContent="center" alignItems={'center'}>
        <Box bg={assingProcesStatuses(status).color} rounded={'xl'} px={2}>
          <Text textAlign={'center'} fontWeight={'bold'} fontSize="13px" color="white" m={0}>
            {assingProcesStatuses(status).text}
          </Text>
        </Box>
      </Flex>
      <Tooltip hasArrow label="Asignar">
        <Button
          size="sm"
          variant="ghost"
          color="blue.500"
          _hover={{ bg: 'blue.50' }}
          onClick={onHandle}
          isDisabled={!canAssign}
        >
          <Box>
            <HiOutlineTruck
              style={{
                fontSize: '20px',
                color: 'blue.500',
              }}
            />
          </Box>
        </Button>
      </Tooltip>
    </Box>
  );
};
const AssignSupplierModal = ({
  isOpen,
  onClose,
  isRejected,
  handleOpenConversationsModal,
  conversationsIds,
  id,
  handleRejectedModal,
  tripData,
}) => {
  const dispatch = useDispatch();
  const tripRequestSupplierMessages = useSelector((state) => state.tripRequestSupplierMessages);
  const { loadingSuppliers, bidSuppliers } = tripRequestSupplierMessages;
  useEffect(() => {
    if (!isOpen) return;
    dispatch(getTripRequestSupplierMessages(id, 0));
  }, [dispatch, id]);

  const handleAssignSupplier = async (supplierId) => {
    await dispatch(assignTripRequest(id, supplierId))
      .then(() => {
        onClose();
      })
      .catch((error) => {
        toast.error('Error al asignar transportista 😢');
        console.error('Error sending message:', error);
        console.error('Error sending message:', error.response.data);
      });
  };
  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="100%"
      height={'550px'}
      overflowY="auto"
      maxWidth="500px"
      bodyContent={
        loadingSuppliers ? (
          <Loader />
        ) : (
          <Box width={'100%'} m={0} p={0}>
            <Box color="gray.800" m={0} p={0}>
              <Text fontSize="md" fontWeight="normal" color="gray.800">
                Cliente: <span style={{ fontWeight: 'bold' }}>{tripData?.client_name || '-'}</span>
              </Text>
              <Flex m={0} p={0}>
                <Text m={0} p={0} fontSize="sm" fontWeight={tripData?.origin ? 'bold' : 'normal'} color="gray.800">
                  {tripData?.origin || 'Origen no definido '}
                </Text>
                -
                <Text m={0} p={0} fontSize="sm" fontWeight={tripData?.destination ? 'bold' : 'normal'} color="gray.800">
                  {tripData?.destination || ' Destino no definido'}
                </Text>
              </Flex>
            </Box>
            {bidSuppliers?.length > 0 ? (
              bidSuppliers?.map((supplier, index) => {
                return (
                  <CardSupplier
                    key={index}
                    name={supplier.suppliers?.[0]?.name}
                    status={supplier?.bid?.item_status}
                    onHandle={() => handleAssignSupplier(supplier?.suppliers?.[0]?.id)}
                    shouldSend={!supplier?.bid?.is_taken}
                  />
                );
              })
            ) : (
              <Box border={'1px solid'} borderColor="gray.200" p={2} rounded={'md'} mt={4}>
                <Text fontSize="md" fontWeight="medium" color="gray.900" m={0}>
                  No hay transportistas disponibles para asignar
                </Text>
              </Box>
            )}
            <Flex m={0} p={0} pt={5} mb={-2} justifyContent={'space-between'}>
              {!isRejected && (
                <Button
                  bg="gray.50"
                  color="gray.500"
                  size="sm"
                  onClick={() => handleRejectedModal(id, isRejected)}
                  isDisabled={false}
                  m={0}
                >
                  Sin asignar/colocar
                </Button>
              )}
              {conversationsIds?.length > 0 && (
                <Button
                  isLoading={false}
                  size="sm"
                  variant={'outline'}
                  borderColor={'blue.500'}
                  color={'blue.500'}
                  onClick={() => handleOpenConversationsModal(conversationsIds, id)}
                  m={0}
                >
                  Ver Conversaciones
                </Button>
              )}
            </Flex>
          </Box>
        )
      }
    />
  );
};

export default AssignSupplierModal;
