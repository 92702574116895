import React from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

const ModalContainer = ({
  isOpen,
  onClose,
  headerContent,
  footerContent,
  bodyContent,
  customStyles,
  size = 'xl',
  initialFocusRef,
  headerColor = 'blue.800',
  overflowY = 'auto',
  height = 'auto',
  maxWidth = '1200px',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      scrollBehavior="inside"
      initialFocusRef={initialFocusRef}
      height={height}
    >
      <ModalOverlay />
      <ModalContent style={customStyles} maxWidth={maxWidth}>
        <ModalCloseButton cy="close-modal" />
        <ModalHeader color={headerColor}>{headerContent}</ModalHeader>
        <ModalBody maxH="70vh" overflowY={overflowY} sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
          {bodyContent}
        </ModalBody>
        <ModalFooter>{footerContent}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalContainer;
