/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import { MdDoNotDisturbAlt } from 'react-icons/md';

import { Box, Center, Flex, Icon, Text } from '@chakra-ui/react';
const FileInput = ({ setFileUploaded, fileUploaded }) => {
  const onDrop = React.useCallback((acceptedFiles) => {
    setFileUploaded(acceptedFiles[0]);
  }, []);

  const { getInputProps, getRootProps, isDragReject, isDragActive, open } = useDropzone({
    onDrop,
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  return (
    <Box
      {...getRootProps()}
      position="relative"
      display="flex"
      h="full"
      w="full"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      borderRadius="lg"
      border="2px"
      borderColor={isDragReject ? 'red' : fileUploaded ? 'blue,500' : 'gray.500'}
      borderStyle="dashed"
      bg="white"
      p={12}
      onClick={open}
      opacity={1}
      pointerEvents={'auto'}
    >
      <input {...getInputProps()} accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
      {/* <input {...getInputProps()} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /> */}
      <Box>
        <Center>
          <HiOutlineCloudUpload
            style={{
              fontSize: '70px',
              opacity: fileUploaded ? 0.3 : 0.4,
            }}
            color="gray"
          />
        </Center>

        {!fileUploaded && (
          <Text
            color={fileUploaded ? 'gray.400' : 'gray.700'}
            fontWeight={'normal'}
            mt={4}
            fontSize={'sm'}
            textAlign="center"
          >
            <span style={{ color: '#344D99', fontWeight: '900' }}>Elige un archivo </span>o arrastra aquí
          </Text>
        )}
        <Center mt={4} fontSize="xs" color="gray.400">
          {fileUploaded && (
            <Text fontWeight="bold" color="blue.500" fontSize={'md'} isTruncated>
              Archivo seleccionado: {fileUploaded.name}
            </Text>
          )}
        </Center>
      </Box>

      {isDragActive && (
        <Box
          position="absolute"
          top={0}
          left={0}
          display="flex"
          h="full"
          w="full"
          alignItems="center"
          justifyContent="center"
          bg="gray.50"
        >
          {isDragReject ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="inline-block" borderRadius="full" bg="red.100" p={2}>
                <Icon as={MdDoNotDisturbAlt} boxSize={35} color="red" />
              </Box>
              <Text mt={6} textAlign="center" fontSize="md" color="red" fontWeight="medium">
                Tipo de archivo no permitido
              </Text>
            </Box>
          ) : (
            <Flex alignItems={'center'} justifyContent="center" flexDirection="column">
              <HiOutlineCloudUpload
                style={{
                  fontSize: '70px',
                  opacity: 0.4,
                }}
                color="gray"
              />
              <Text mt={3} textAlign="center" fontWeight="normal" color="gray.700">
                Arrastra tus archivos aquí
              </Text>
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FileInput;
