import axios from 'axios';

import { BASE_URL } from '../appSettings';
import {
  METRO_AREA_DROPDOWN_LIST_REQUEST,
  METRO_AREA_DROPDOWN_LIST_SUCCESS,
  METRO_AREA_DROPDOWN_LIST_FAIL,
  METRO_AREA_CREATE_REQUEST,
  METRO_AREA_CREATE_SUCCESS,
  METRO_AREA_CREATE_FAIL,
} from '../constants/metroAreaConstants';

export const listMetroAreasDropdown = () => async (dispatch, getState) => {
  try {
    dispatch({ type: METRO_AREA_DROPDOWN_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const params = {};

    const { data } = await axios.get(`${BASE_URL}/api/v1/metro-areas/`, {
      ...config,
      params: {
        ...params,
      },
    });

    dispatch({
      type: METRO_AREA_DROPDOWN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: METRO_AREA_DROPDOWN_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const createMetroArea = () => async (dispatch) => {
  try {
    dispatch({
      type: METRO_AREA_CREATE_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const { data } = await axios.post('/api/v1/metro-areas/', {}, config);

    dispatch({
      type: METRO_AREA_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: METRO_AREA_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
