import React from 'react';

import { Box, Grid } from '@chakra-ui/react';

import { colorStatus } from './GridBidRequestSet';

const SuppliersGrid = ({ showRate, bidItem, suppliers }) => {
  const suppliersObject = Object.keys(suppliers).map((key) => {
    let supplier = suppliers[key];
    return {
      accessorKey: `supplier_${supplier.id}`,
      header: supplier.name,
      color: bidItem?.status?.details?.find((detail) => detail.supplier.id === supplier.id)?.status_box?.color,
      rate: bidItem?.status?.details?.find((detail) => detail.supplier.id === supplier.id)?.rate,
    };
  });

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });
  return (
    <Box
      height={'61px'}
      bg="white"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'space-between'}
      pl={2}
    >
      <Grid templateColumns={`repeat(${suppliersObject.length}, 1fr)`} gap={6}>
        {suppliersObject.map(({ color, rate }, index) => {
          if (!rate) {
            rate = '';
          } else {
            rate = formatter.format(rate);
          }
          return (
            <Box
              key={index}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={'8px'}
              w={'80px'}
              h={'45px'}
              fontSize={'xs'}
              color={'#000000'}
              fontWeight={'normal'}
              bg={colorStatus(color)}
              borderBottom={'1px solid #e1e1e1'}
              borderRight={'1px solid #e1e1e1'}
            >
              {showRate ? rate : ''}
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SuppliersGrid;
