/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChatGroupVisualizer } from '../actions/chatVisualizerAction';
import AppLayout from '../components/utils/layouts/AppLayout';
import MessagesTemplates from '../templates/MessagesTemplates';
const MessagesScreen = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userDetails);
  const { chats, loading } = useSelector((state) => state.chatGroupVisualizer);
  const companyId = userDetails?.company?.id;

  useEffect(() => {
    if (!userDetails) return;
    dispatch(getChatGroupVisualizer(companyId));
  }, [userDetails]);

  return (
    <AppLayout showFooter={false}>
      <MessagesTemplates chats={chats} loading={loading} />
    </AppLayout>
  );
};

export default MessagesScreen;
