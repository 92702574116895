import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { createUnitType } from '../actions/unitTypeActions';

const CreateUnitTypeModal = ({ isOpen, onClose, refreshDropdowns }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await dispatch(createUnitType(formData));
      refreshDropdowns();
      toast.success('Unidad creado exitosamente');
      onClose();
    } catch (error) {
      toast.error('Oh no! Something went wrong.');
      console.error('Error creating unit type:', error);
    }
  };

  const customStyles = {
    backgroundColor: 'white',
    color: 'darkblue',
    // Add more custom styles as needed
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent style={customStyles}>
        <ModalHeader>Create New Unit Type</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} />
          </FormControl>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Create Unit Type
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateUnitTypeModal;
