import {
  BULK_APROVED_FAIL,
  BULK_APROVED_LOADING,
  BULK_APROVED_SUCCESS,
  BULK_DELETE_LOADING,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAIL,
} from '../constants/bulkConstants';

export const aprovedBulkReducer = (state = {}, action) => {
  switch (action.type) {
    case BULK_APROVED_LOADING:
      return { loading: true };

    case BULK_APROVED_SUCCESS:
      return { loading: false };

    case BULK_APROVED_FAIL:
      return { loading: false };

    default:
      return state;
  }
};
export const deleteBulkReducer = (state = {}, action) => {
  switch (action.type) {
    case BULK_DELETE_LOADING:
      return { loading: true };

    case BULK_DELETE_SUCCESS:
      return { loading: false };

    case BULK_DELETE_FAIL:
      return { loading: false };

    default:
      return state;
  }
};
