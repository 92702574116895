import {
  BID_REQUEST_DETAIL_FAIL,
  BID_REQUEST_DETAIL_REQUEST,
  BID_REQUEST_DETAIL_SUCCESS,
  BID_REQUEST_FAIL,
  BID_REQUEST_REQUEST,
  BID_REQUEST_SUCCESS,
} from '../constants/bidRequestConstants';
export const bidRequestReducer = (state = { bidRequests: [], supplier: [] }, action) => {
  switch (action.type) {
    case BID_REQUEST_REQUEST:
      return {
        loading: true,
        updating: false,
        bidRequests: [],
        supplier: [],

        showRate: null,
      };

    case BID_REQUEST_SUCCESS:
      return {
        loading: false,
        updating: false,
        supplier: action.payload,
        bidRequests: action.payload?.bid_requests,
        showRate: action.payload?.show_rate,
        pagination: action.payload.pagination,
      };

    case BID_REQUEST_FAIL:
      return {
        loading: false,
        updating: false,
        bidRequests: [],
        supplier: [],

        error: action.payload,
      };

    default:
      return state;
  }
};

export const bidRequestDetailReducer = (state = { bidRequest: {} }, action) => {
  switch (action.type) {
    case BID_REQUEST_DETAIL_REQUEST:
      return { loading: true, updating: false, bidRequest: {} };

    case BID_REQUEST_DETAIL_SUCCESS:
      return {
        loading: false,
        updating: false,
        bidRequest: action.payload,
      };

    case BID_REQUEST_DETAIL_FAIL:
      return {
        loading: false,
        updating: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
