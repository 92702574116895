import {
  STATE_DROPDOWN_LIST_REQUEST,
  STATE_DROPDOWN_LIST_SUCCESS,
  STATE_DROPDOWN_LIST_FAIL,
} from '../constants/stateConstants';
import httpClient from './utils/axiosInstance';

export const listStatesDropdown = () => async (dispatch) => {
  try {
    dispatch({ type: STATE_DROPDOWN_LIST_REQUEST });

    const { data } = await httpClient.get(`/api/v1/states/`);

    dispatch({
      type: STATE_DROPDOWN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STATE_DROPDOWN_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
