import React from 'react';

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Input, FormControl, InputGroup, InputRightElement, IconButton, FormLabel, Text } from '@chakra-ui/react';

const InputPassword = ({ value, onChange, label, error, name, placeholder }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <FormControl mt={2}>
      <FormLabel fontSize={14} letterSpacing={0} color={`${error ? 'red' : ' gray.700'}`}>
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          name={name}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          borderColor={error ? 'red.500' : 'gray.400'}
        />
        <InputRightElement width="3rem">
          <IconButton
            h="1.75rem"
            size="md"
            backgroundColor={'transparent'}
            onClick={handleTogglePassword}
            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
            aria-label={''}
          />
        </InputRightElement>
      </InputGroup>
      {error && (
        <Text color="red" fontSize={12} fontWeight="normal" ml={1}>
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default InputPassword;
