import { Checkbox, Text, Tooltip } from '@chakra-ui/react';

import RegularCell from '../RegularCell';
// import { assingProcesStatuses } from "../utils/AssingProcesStatuses";
import ContainerTripRequestButton from '../utils/buttons/ActionsTripRequestButtonOutlines';
import MessageToolTip from '../utils/tooltips/MessageToolTip';
import StatusesToolTip from '../utils/tooltips/StatusesToolTip';
import PendingStatusProcess from './utils/PendingStatusProcess';

export const columns = (
  openChatModal,
  hasClientCoordination,
  handleApprove,
  hasSupplierCoordination,
  handleOpenConversationsModal,
  openForwardMessageModal,
  openAssignSupplierModal,
  setShowModal,
  readStatus,
  disabledButtons,
  handleReadStatusToggle,
  openTripRequestModal,
  checkedItems,
  handleCheckboxChange,
  handleRejectedModal,
  selectAll,
  handleSelectAll,
  handleAssignModal,
  openAssignSupplierTrafficModal,
  refreshTripRequests
) => [
  {
    accessorKey: 'selected',
    header: (
      <Tooltip title="Seleccionar todo" hasArrow placement="bottom">
        <Checkbox isChecked={selectAll} borderColor={'gray.600'} onChange={(e) => handleSelectAll(e)} />
      </Tooltip>
    ),

    isInteractive: true,
    cell: ({ row }) => {
      return (
        <Checkbox
          isChecked={checkedItems?.some((item) => item.id === row.original.id)}
          id={row.original.id}
          borderColor={'gray.600'}
          onChange={(e) =>
            handleCheckboxChange(
              { index: row.index, id: row.original.id, supplierId: row.original?.preassigned_transporter },
              e
            )
          }
        />
      );
    },
    size: 30,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: '',
    isInteractive: false,
    cell: StatusesToolTip,
    size: 45,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'client_name', // client_name_tag
    header: 'Cliente',
    isInteractive: false,
    size: 100,
    cell: RegularCell, // Render a regular text cell
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'requested_at',
    header: 'Fecha',
    isInteractive: false,
    size: 120,
    cell: RegularCell,
  },
  {
    accessorKey: 'main_msg',
    header: 'Mensajes',
    size: 120, // Set the desired size for the column
    isInteractive: false,
    isOpenMessage: true,
    cell: ({ row }) => {
      if (row?.original?.file !== null) {
        return '';
      } else {
        return <MessageToolTip row={row} openChatModal={openChatModal} />;
      }
    },
  },
  {
    accessorKey: 'origin',
    header: 'Origen',
    isInteractive: false,
    size: 120,
    cell: RegularCell,
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'destination',
    header: 'Destino',
    isInteractive: false,
    size: 100,
    cell: RegularCell,
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'folio_number',
    header: 'N. confirmación',
    isInteractive: false,
    size: 110,
    cell: RegularCell,
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'shipment_number',
    header: 'N. viaje',
    isInteractive: false,
    size: 100,
    cell: RegularCell,
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'loading_date',
    header: 'Fecha Carga',
    isInteractive: false,
    size: 100,
    cell: ({ getValue, row }) => {
      return (
        <>
          <RegularCell row={row} getValue={getValue} />
          <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
            {row?.original.loading_time}
          </Text>
        </>
      );
    },
  },
  {
    accessorKey: 'unloading_date',
    header: 'Fecha descarga',
    isInteractive: false,
    size: 110,
    cell: ({ getValue, row }) => {
      return (
        <>
          <RegularCell row={row} getValue={getValue} />
          <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
            {row?.original.unloading_time}
          </Text>
        </>
      );
    },
  },

  {
    accessorKey: 'assigned_transporter',
    header: hasClientCoordination ? 'Asignado a' : 'PROCESO ASIGNACION',
    isInteractive: false,
    size: 150,
    isOpenAssigned: true,
    cell: ({ row }) => {
      return hasClientCoordination ? (
        <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
          {row?.original.assigned_transporter || '-'}
        </Text>
      ) : (
        row?.original?.file && (
          <PendingStatusProcess
            row={row}
            isMessageTrip={!row?.original?.file}
            contactedSupplier={row?.original.suppliers_have_been_contacted}
            isAssignedATrasnporter={row?.original.assigned_transporter}
          />
        )
      );
    },
    filterFn: 'includesString',
  },
  {
    accessorKey: 'unit_type',
    header: 'Unidad',
    isInteractive: false,
    size: 90,
    cell: RegularCell,
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'assigned_transporter',
    header: 'Asignado a',
    isInteractive: false,
    size: 150,
    cell: RegularCell,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'respond',
    header: null,
    size: 0, // Set the desired size for the column
    isInteractive: true,
    cell: ({ row }) => {
      return (
        <ContainerTripRequestButton
          openChatModal={openChatModal}
          hasSupplierCoordination={hasSupplierCoordination}
          handleOpenConversationsModal={handleOpenConversationsModal}
          openForwardMessageModal={openForwardMessageModal}
          openAssignSupplierModal={openAssignSupplierModal}
          setShowModal={setShowModal}
          handleReadStatusToggle={handleReadStatusToggle}
          readStatus={readStatus}
          disabledButtons={disabledButtons}
          id={row.original?.id}
          forwarded_supplier_chats={row.original?.forwarded_supplier_chats}
          assigned_transporter={row.original?.assigned_transporter}
          handleRejectedModal={handleRejectedModal}
          isRejected={row.original?.is_rejected}
          isApprovedByMC={row.original?.is_approved}
          item={row.original}
          isABid={!!row.original?.file}
          handleAssignModal={handleAssignModal}
          openAssignSupplierTrafficModal={openAssignSupplierTrafficModal}
          refreshTripRequests={refreshTripRequests}
        />
      );
    },
  },
];
