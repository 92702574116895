import React from 'react';
import { HiOutlineMailOpen, HiOutlineMail, HiOutlineTrash, HiOutlineExclamation } from 'react-icons/hi';
import { HiOutlineChatBubbleLeftRight, HiOutlineDocumentPlus, HiOutlineTruck } from 'react-icons/hi2';

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

import Buttom from './Buttom';
import { duplicateTrip } from '../../../actions/clientActions';
import { useDispatch, useSelector } from 'react-redux';

const ContainerButtom = ({ children }) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="justify-between"
      p={0}
      m={0}
      height="auto"
      width="fit-content"
      border="none"
    >
      {children}
    </Box>
  );
};

const ContainerTripRequestButton = ({
  // openChatModal,
  hasSupplierCoordination,
  handleOpenConversationsModal,
  openForwardMessageModal,
  // openAssignSupplierModal,
  setShowModal,
  handleReadStatusToggle,
  readStatus,
  disabledButtons,
  id,
  forwarded_supplier_chats,
  // assigned_transporter,
  handleRejectedModal,
  isRejected,
  isApprovedByMC,
  handleAssignModal,
  item,
  isABid,
  openAssignSupplierTrafficModal,
  refreshTripRequests,
}) => {
  const dispatch = useDispatch();
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  return (
    <ContainerButtom>
      {/* <Buttom
        variant="ghost"
        size="xs"
        text={
          <div>
            <ChatIcon />
          </div>
        }
        onClick={() => openChatModal(id)}
        mb={1}
        width="30px"
        mt={1}
        py={1}
        px={1}
        textColor="blue.700"
        tooltipLabel="Mensajes"
        maxHeight="100px"
        cy="messages-button"
        //
      /> */}

      {hasSupplierCoordination && (
        <div
          style={{
            display: 'flex',
            gap: '3px',
          }}
        >
          {forwarded_supplier_chats?.length > 0 ? (
            <Buttom
              variant="ghost"
              size="xs"
              // color="purple.100"
              text={
                <div>
                  <HiOutlineChatBubbleLeftRight
                    style={{
                      fontSize: 18,
                    }}
                  />
                </div>
              }
              onClick={() => handleOpenConversationsModal(forwarded_supplier_chats, id)}
              mb={1}
              width="30px"
              mt={1}
              py={1}
              px={1}
              textColor="blue.700"
              tooltipLabel="Ver conversaciones"
              cy="conversations-button"
            />
          ) : (
            <Buttom
              variant="ghost"
              size="xs"
              // color="purple.100"
              text={<ExternalLinkIcon fontSize={16} />}
              onClick={() => {
                return openForwardMessageModal(id, isABid);
              }}
              mb={1}
              width="30px"
              mt={1}
              py={1}
              px={1}
              textColor="blue.700"
              tooltipLabel="Reenviar"
              cy="forward-button"
            />
          )}
          <Buttom
            variant="ghost"
            size="xs"
            // color="orange.100"
            text={
              <div>
                <HiOutlineTruck
                  style={{
                    fontSize: '16px',
                  }}
                />
              </div>
            }
            onClick={
              isABid
                ? () => {
                    return handleAssignModal({
                      id: item.id,
                      forwarded_supplier_chats: item.forwarded_supplier_chats,
                      is_rejected: item.is_rejected,
                      origin: item?.origin,
                      destination: item.destination,
                      client_name: item.client_name,
                    });
                  }
                : () => {
                    return openAssignSupplierTrafficModal(id);
                  }
            }
            mb={1}
            mt={1}
            py={1}
            px={1}
            textColor="blue.700"
            tooltipLabel={'Asignar'}
            isDisabled={!isABid && !isApprovedByMC}
            cy="asignated-button"
          />
        </div>
      )}

      <Buttom
        variant="ghost"
        size="xs"
        // color="pink.100"
        text={
          <div>
            <HiOutlineTrash
              style={{
                fontSize: '17px',
              }}
            />
          </div>
        }
        onClick={() =>
          setShowModal({
            id: id,
            show: true,
          })
        }
        mb={1}
        width="30px"
        mt={1}
        py={1}
        pr={0}
        pl={1}
        mr={0}
        textColor="blue.700"
        tooltipLabel="Eliminar"
        cy="delete-button"
      />
      <Buttom
        variant="ghost"
        size="xs"
        // color="blue.100"
        text={
          readStatus[id] ? (
            <div>
              <HiOutlineMailOpen
                style={{
                  fontSize: '17px',
                }}
              />
            </div>
          ) : (
            <div>
              <HiOutlineMail
                style={{
                  fontSize: '17px',
                }}
              />
            </div>
          )
        }
        onClick={() => handleReadStatusToggle(id)}
        mb={1}
        width="30px"
        mt={1}
        py={1}
        pr={0}
        pl={1}
        mr={0}
        textColor="blue.700"
        isLoading={disabledButtons[id]}
        tooltipLabel={readStatus[id] ? 'Marcar como no leído' : 'Marcar como leído'}
        cy="read-unread-button"
      />
      <Buttom
        variant="ghost"
        size="xs"
        text={
          <div>
            <HiOutlineExclamation
              style={{
                fontSize: '17px',
              }}
            />
          </div>
        }
        onClick={() => handleRejectedModal(id, isRejected)}
        mb={1}
        width="30px"
        mt={1}
        py={1}
        pr={0}
        pl={1}
        mr={0}
        cy="reject-button"
        textColor="blue.700"
        tooltipLabel={isRejected ? 'Activar viaje' : 'Sin asignar/ sin colocar'}
      />
      {userDetails?.is_staff && (
        <Buttom
          variant="ghost"
          type="button"
          size="xs"
          text={
            <div>
              <HiOutlineDocumentPlus
                style={{
                  fontSize: '17px',
                }}
              />
            </div>
          }
          onClick={(e) => {
            e.preventDefault();
            dispatch(duplicateTrip(id, 'trip-requests'));
            refreshTripRequests();
          }}
          mb={1}
          width="30px"
          mt={1}
          py={1}
          pr={0}
          pl={1}
          mr={0}
          cy="reject-button"
          textColor="blue.700"
          tooltipLabel={'Duplicar viaje'}
        />
      )}
    </ContainerButtom>
  );
};

export default ContainerTripRequestButton;
