import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, Box, IconButton, Text, Checkbox, Textarea } from '@chakra-ui/react';

import { respondToTripRequest, resetTripRequestResponse } from '../actions/tripRequestActions';
import Message from './Message';

function ChatResponseBox({ id, isCurrentChat, convesations = false }) {
  const dispatch = useDispatch();
  const [messageText, setMessageText] = useState('');
  const { loading, error } = useSelector((state) => state.tripRequestResponse);
  const [stayAnonymous, setStayAnonymous] = useState(true);
  const handleSendMessage = async () => {
    try {
      // Dispatch the action to send the message
      const messageData = {
        message: messageText,
        to: id,
        anonymous: stayAnonymous,
      };
      await dispatch(respondToTripRequest(id, messageData));

      // Set the success message and clear the input
      //   setSuccessMessage('Message sent successfully!');
      setMessageText('');
    } catch (error) {
      console.error('Error sending message:', error);
      console.error('Error sending message:', error.response.data);
    }
  };

  const handleInputClick = () => {
    // Dispatch an action to reset the error state
    dispatch(resetTripRequestResponse());
  };

  const handleCheckboxChange = () => {
    setStayAnonymous(!stayAnonymous);
  };

  const isSubmitDisabled = messageText.trim() === '';
  const eventIsLoading = convesations ? isCurrentChat && loading : loading;
  return (
    <Box width="100%">
      <Flex mt={4} align="center" justifyContent="space-between" w={'100%'} mb={2}>
        <Textarea
          cy="message-input"
          border="1px solid #E2E8F0"
          borderRadius="lg"
          value={messageText}
          onClick={handleInputClick}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Type your message..."
          flex="1"
          isDisabled={eventIsLoading}
          mr={2}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setMessageText(e.target.value);
              handleSendMessage();
            }
          }}
        />
        <IconButton
          colorScheme="blue"
          aria-label="Send Message"
          icon={<i className="fas fa-paper-plane"></i>}
          onClick={handleSendMessage}
          isDisabled={isSubmitDisabled}
          isLoading={eventIsLoading}
          cy="message-send-button"
        />
      </Flex>
      {error && isCurrentChat ? <Message variant="error">{error}</Message> : null}

      <Box display="flex" alignItems="center" justify="flex-start">
        <Checkbox
          style={{ borderColor: 'gray.600', backgroundColor: 'white' }}
          isChecked={stayAnonymous}
          onChange={handleCheckboxChange}
          isDisabled={isCurrentChat && loading}
          cy="message-anonymous-checkbox"
        />
        <Text mt={4} ml={2} color="gray.600" fontWeight="normal">
          Mandar anonimo
        </Text>
      </Box>
    </Box>
  );
}

export default ChatResponseBox;
