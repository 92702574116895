// components/utils/HomeScreen.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TripRequestListScreen from '../../screens/TripRequestListScreen';

const HomeScreen = ({ userDetails, redirectedToHomeScreen }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const debounced = setTimeout(() => {
      if (userDetails && !redirectedToHomeScreen.current) {
        redirectedToHomeScreen.current = true;
        userDetails?.home_screen && navigate(userDetails?.home_screen, { replace: true });
      }
    }); //Wait for next tick to avoid race condition, since TripRequestListScreen calls navigate()
    return () => clearTimeout(debounced);
  }, [userDetails, navigate, redirectedToHomeScreen]);

  return <TripRequestListScreen />;
};

export default HomeScreen;
