import React from 'react';

import { Button, Flex, Spacer } from '@chakra-ui/react';

import LogoIcon from '../../icons/LogoIcon';

const HeaderNavigator = () => {
  return (
    <Flex borderBottom="1px solid" borderColor="gray.200" px={20} py={5} bg="#F5F5F5" maxWidth="1200px" margin="auto">
      <LogoIcon />
      <Spacer />
      <Button borderWidth={0} width={130} fontSize="18px" letterSpacing={1}>
        Login
      </Button>
    </Flex>
  );
};

export default HeaderNavigator;
