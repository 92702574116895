import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../actions/userActions';

import '../assets/css/ChangePasswordForm.css';

const ChangePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const updatedPassword = useSelector((state) => state.userLogin.updatedPassword);
  const error = useSelector((state) => state.userLogin.error);

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage("New passwords don't match.");
      return;
    }

    dispatch(updatePassword(
      currentPassword,
      newPassword,
      confirmPassword
    ));

  };

  useEffect(() => {
    setSuccessMessage('');
    setErrorMessage('');
    if (updatedPassword == 'failed') {
        setErrorMessage(error);
      } else if (updatedPassword == 'updated') {
        setSuccessMessage('Contraseña actualizada exitosamente');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }

  }, [updatedPassword]); 

  return (
    <div className="change-password-form">
      <h2>Cambiar la contraseña</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Contraseña actual</label>
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Nueva contraseña</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Confirmar nueva contraseña</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        <button type="submit">Actualizar</button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;