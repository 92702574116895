/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Container, Flex, Text } from '@chakra-ui/react';

import Message from '../../components/Message';
import DetailSkeleton from '../utils/skeletons.jsx/DetailSkeleton';
import CopyTextTooltip from '../utils/tooltips/CopyTextTooltip';
import CommentsDetailTrip from './../tripRequest/CommentsDetailTrip';

export const ContanerText = ({ title, description, description2, cy, id }) => {
  return (
    <Box
      display={{ base: 'block', lg: 'flex' }}
      alignItems={'flex-start'}
      justifyContent={{ base: 'space-between', lg: 'flex-start' }}
      m={0}
      p={0}
      pb={1}
      gap={0}
      cy={cy}
      id={id}
    >
      <Text fontSize={{ base: 'xs', lg: 'sm' }} fontWeight="normal" color="gray.900" m={0} p={0}>
        {title}
      </Text>
      <Text
        fontWeight={{ base: 'bold', lg: 'bold' }}
        fontSize={{ base: 'xs', lg: 'sm' }}
        color="gray.900"
        m={0}
        p={0}
        ml={{ base: 0, lg: 2 }}
        display={'flex'}
        flexDirection={'column'}
        alignContent={'flex-start'}
        justifyContent={'flex-start'}
        cy={`${cy}-description`}
      >
        {description}
        {description2 && (
          <Text fontWeight="normal" fontSize="xs" m={0} p={0} ml={1}>
            {description2}
          </Text>
        )}
      </Text>
    </Box>
  );
};
function TripDetails(props) {
  const { id } = props;
  const tripRequestDetails = useSelector((state) => state.tripRequestDetails);
  const { loading, error, tripRequest } = tripRequestDetails;
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  if (loading) {
    return <DetailSkeleton />;
  }
  return (
    <Container mt={4} width="100%" pl={{ base: 0, lg: 2 }}>
      {error ? <Message variant="error">{error}</Message> : null}
      {!loading && !error && !tripRequest ? (
        <Message variant="info">No trip details available.</Message>
      ) : (
        <Box pl={{ base: 0, lg: 4 }} mt={0} pt={0}>
          <Box display="flex" justifyContent={'flex-end'} w="100%" m={0} mt={-3} p={0}></Box>
          <Flex
            flexDirection={{ base: 'column', sm: 'row', lg: 'column' }}
            alignItems={{
              base: 'flex-start',
              sm: 'space-between',
              lg: 'flex-start',
            }}
            justifyContent={{
              base: 'flex-start',
              sm: 'space-between',
              lg: 'flex-start',
            }}
            gap={{ base: 1, lg: 0 }}
            borderBottom={'2px solid'}
            borderColor="gray.200"
            mb={3}
            pb={6}
          >
            <Box mt={2}>
              {userDetails?.is_staff && (
                <ContanerText title="ID interno: " description={<CopyTextTooltip text={id} />} cy="id" />
              )}
              <ContanerText title="Cliente: " description={tripRequest?.channel?.client?.name} cy="client" id={id} />
              <ContanerText title="Fecha de carga: " description={tripRequest?.loading_date || '-'} cy="loading" />
              <ContanerText title="Hora de carga:" description={tripRequest?.loading_time || '-'} cy="loading-time" />

              <ContanerText
                title="Fecha de descarga: "
                description={tripRequest?.unloading_date || '-'}
                cy="unloading"
              />
              <ContanerText
                title="Hora de descarga:"
                description={tripRequest?.unloading_time || '-'}
                cy="unloading-time"
              />
            </Box>
            <Box my={{ base: 0, lg: 5 }} mb={{ base: 0, lg: 5 }}>
              <ContanerText cy="origin" title="Origen: " description={tripRequest?.origin?.name} />
              <ContanerText cy="destination" title="Destino: " description={tripRequest?.destination?.name} />
              <ContanerText title="Unidad: " description={tripRequest?.unit_type?.name || '-'} />
              <ContanerText title="Asignado a:" description={tripRequest?.assigned_transporter?.name || '-'} />
              <ContanerText title="Número de viaje:" description={tripRequest?.shipment_number || '-'} />
              <ContanerText title="Número de confirmacion:" description={tripRequest?.folio_number || '-'} />
            </Box>
            <Box>
              <ContanerText
                title="Aprobado por MC:"
                description={`${tripRequest?.approved_by || '-'} `}
                description2={`${tripRequest?.approved_at || ''}`}
              />
              <ContanerText
                title="Aprobado por tráfico:  "
                description={`${tripRequest?.approved_by_traffic_by || '-'}`}
                description2={`${tripRequest?.approved_by_traffic_at || ''}`}
              />
            </Box>
          </Flex>
          <CommentsDetailTrip
            tripRequest={tripRequest}
            id={id}
            isLoading={props.isLoading}
            setIsloading={props.setIsloading}
          />
        </Box>
      )}
    </Container>
  );
}

export default TripDetails;
