import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, ButtonGroup, IconButton, Select, Text, useColorModeValue } from '@chakra-ui/react';

const TripRequestPaginator = ({
  pagination = {},
  fetchNextPage,
  fetchPreviousPage,
  elementShow,
  setElementShow,
  withMaxScreen,
  setTablePage,
}) => {
  const buttonColor = useColorModeValue('blue', 'gray'); // Adjust the shade as needed

  const handleChange = (event) => {
    setTablePage(1);
    setElementShow(event.target.value);
  };

  return (
    <Box
      m={0}
      p={0}
      px={4}
      mb={3}
      pt={1}
      pb={1}
      border="1px solid"
      borderTop={'none'}
      borderColor="gray.200"
      bg="white"
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      borderRadius={{ base: 'md', lg: 'none' }}
      width={'100%'}
      maxW={withMaxScreen}
    >
      <Box>
        <Text mb={0} fontWeight={'normal'} mt={{ base: '6px', lg: '0px' }} color="gray.600" fontSize="12px">
          Page {pagination.currentPage} of {pagination.totalPages}
        </Text>
        <ButtonGroup size="sm" isAttached variant="outline">
          <IconButton
            onClick={fetchPreviousPage}
            isDisabled={!pagination.previousPageURL}
            colorScheme={buttonColor}
            icon={<ChevronLeftIcon fontSize={'20px'} />}
            variant={'outline'}
            size="xs"
          />
          <IconButton
            onClick={fetchNextPage}
            isDisabled={!pagination.nextPageURL}
            colorScheme={buttonColor}
            icon={<ChevronRightIcon fontSize={'20px'} />}
            variant={'outline'}
            size="xs"
          />
        </ButtonGroup>
      </Box>
      <Box width={'auto'}>
        <Text mb={0} fontWeight={'normal'} mt={{ base: '6px', lg: '0px' }} color="gray.600" fontSize="12px">
          Viajes por página
        </Text>
        <Select value={elementShow} onChange={handleChange} size={'xs'} borderRadius="md">
          <option value={10}>10</option>
          <option value={40}>40</option>
          <option value={50}>50</option>
          <option value={70}>70</option>
          <option value={100}>100</option>
        </Select>
      </Box>
    </Box>
  );
};

export default TripRequestPaginator;
