export const UNIT_TYPE_LIST_REQUEST = 'UNIT_TYPE_LIST_REQUEST';
export const UNIT_TYPE_LIST_SUCCESS = 'UNIT_TYPE_LIST_SUCCESS';
export const UNIT_TYPE_LIST_FAIL = 'UNIT_TYPE_LIST_FAIL';

export const UNIT_TYPE_DROPDOWN_LIST_REQUEST = 'UNIT_TYPE_DROPDOWN_LIST_REQUEST';
export const UNIT_TYPE_DROPDOWN_LIST_SUCCESS = 'UNIT_TYPE_DROPDOWN_LIST_SUCCESS';
export const UNIT_TYPE_DROPDOWN_LIST_FAIL = 'UNIT_TYPE_DROPDOWN_LIST_FAIL';

export const UNIT_TYPE_DETAILS_REQUEST = 'UNIT_TYPE_DETAILS_REQUEST';
export const UNIT_TYPE_DETAILS_SUCCESS = 'UNIT_TYPE_DETAILS_SUCCESS';
export const UNIT_TYPE_DETAILS_FAIL = 'UNIT_TYPE_DETAILS_FAIL';

export const UNIT_TYPE_CREATE_REQUEST = 'UNIT_TYPE_CREATE_REQUEST';
export const UNIT_TYPE_CREATE_SUCCESS = 'UNIT_TYPE_CREATE_SUCCESS';
export const UNIT_TYPE_CREATE_FAIL = 'UNIT_TYPE_CREATE_FAIL';

export const UNIT_TYPE_DELETE_REQUEST = 'UNIT_TYPE_DELETE_REQUEST';
export const UNIT_TYPE_DELETE_SUCCESS = 'UNIT_TYPE_DELETE_SUCCESS';
export const UNIT_TYPE_DELETE_FAIL = 'UNIT_TYPE_DELETE_FAIL';
