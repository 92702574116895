import React from 'react';

import { Box, Flex, Grid, Button, Text, useDisclosure, Input } from '@chakra-ui/react';

import GridTripSkeleton from '../utils/skeletons.jsx/GridTripSkeleton';

const CardText = ({ name, description, onClick }) => {
  return (
    <Box display={'flex'} fontSize="xs" m={0} p={1} pl={0} onClick={onClick} cursor={'pointer'}>
      <Text m={0} p={0} pr={1} fontWeight="bold">
        {name}:
      </Text>
      <Text m={0} p={0}>
        {description}
      </Text>
    </Box>
  );
};
const CardItem = ({
  client,
  origin,
  destination,
  loading_date,
  unit,
  item,
  rateValue,
  onChangeInputRate,
  rate,
  handleAcceptedBidRequest,
  index,
  isAcceptedLoading,
  showRate,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box
      bg={'white'}
      border="1px solid"
      shadow={'md'}
      borderColor={'gray.200'}
      rounded={5}
      px={4}
      pt={2}
      pb={2}
      color="gray.900"
      fontWeight={'normal'}
      width={'100%'}
      m={0}
      mt={1}
    >
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Cliente" description={client} />
        <CardText name="Origen" description={origin} />
      </Grid>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Destino" description={destination} />
        <CardText name="Unidad" description={unit || ' -'} />
      </Grid>
      {isOpen && (
        <Box>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText name="Fecha de carga" description={`${loading_date || ''} / ${item.loading_time || ''}`} />
            <CardText
              name="Fecha de descarga"
              description={`${item?.unloading_date || ''} / ${item.unloading_time || ''}`}
            />
          </Grid>

          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText name="Número de confirmación" description={item?.folio_number || ' -'} />
          </Grid>
        </Box>
      )}
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={2}>
        <Button
          onClick={onToggle}
          variant="ghost"
          color="gray.500"
          p={0}
          m={0}
          textAlign={'center'}
          size="sm"
          textDecoration={'underline'}
          _hover={{
            backgroundColor: 'transparent',
            color: 'blue.500',
          }}
        >
          {!isOpen ? 'Ver más' : 'Ver menos'}
        </Button>
        <Flex alignItems={'center'} gap={1}>
          {showRate && (
            <Box>
              <Input
                type="number"
                placeholder="Tarifa"
                size="sm"
                value={rateValue?.[index] || rate || ''}
                onChange={(e) => onChangeInputRate(index, e)}
                onBlur={(e) => onChangeInputRate(index, e)}
                borderRadius={5}
                color="gray.900"
                isDisabled={item?.button_settings?.disabled}
              />
            </Box>
          )}
          <Button
            color="white"
            p={0}
            px={2}
            m={0}
            textAlign={'center'}
            size="sm"
            _hover={{
              backgroundColor: 'transparent',
              color: 'blue.500',
            }}
            colorScheme={item?.is_accepted ? 'green' : 'blue'}
            onClick={() => handleAcceptedBidRequest(item?.id, index)}
            isDisabled={item?.button_settings?.disabled}
            isLoading={isAcceptedLoading}
          >
            {item?.button_settings?.text || 'Aceptar'}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
const GridGroupTokenRequest = ({
  data,
  isLoading,
  handleAcceptedBidRequest,
  onChangeInputRate,
  rateValue,
  isAcceptedLoading,
  showRate,
}) => {
  return (
    <Box
      overflowY="auto"
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      // height={{ base: '90%', xl: '0px' }}
      width={'100%'}
    >
      {isLoading ? (
        <GridTripSkeleton />
      ) : data.length === 0 ? (
        <Box
          ml="10px"
          w={'100%'}
          p={0}
          m={0}
          pt={4}
          height={240}
          justifyContent="center"
          alignItems="center"
          bg="white"
          shadow={'md'}
          rounded={5}
          mb={3}
          display={{ base: 'flex', xl: 'none' }}
        >
          <Text fontWeight="normal">No hay solicitudes de viaje</Text>
        </Box>
      ) : (
        <Grid
          templateColumns={{ base: '1fr' }}
          width={'100%'}
          display={{ base: 'block', xl: 'none' }}
          m={0}
          p={0}
          mb={4}
        >
          {data?.map((detail, i) => (
            <CardItem
              key={i}
              client={detail?.item?.content_object?.client} //check
              origin={detail?.item?.content_object?.origin} // check
              destination={detail?.item?.content_object?.destination} //check
              requested_at={detail?.item?.content_object?.requested_at} //date
              loading_date={detail?.item?.content_object?.loading_date} //charge date
              unit={detail?.item?.content_object?.unit_type} // check
              rate={detail?.rate} // check
              item={detail}
              index={i}
              handleAcceptedBidRequest={handleAcceptedBidRequest}
              onChangeInputRate={onChangeInputRate}
              rateValue={rateValue}
              isAcceptedLoading={isAcceptedLoading}
              showRate={showRate}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GridGroupTokenRequest;
