import React from 'react';

import { Box, Spinner } from '@chakra-ui/react';

function Loader({ size = 'xl' }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={10} mb={10}>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.700" size={size} />
      {/* <Text
        color="gray.500"
        fontWeight="normal"
        fontSize="14px"
        textAlign="center"
        ml={2}
        mt={4}
      >
        Loading...
      </Text> */}
    </Box>
  );
}

export default Loader;
