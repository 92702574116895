/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Flex, Text, Textarea, Checkbox } from '@chakra-ui/react';

import {
  getTripRequestSupplierMessages,
  // forwardSupplierMessage,
  // cleanlistTripRequestForwardMessages,
  getTripRequestDetails,
  forwardForwardBidRequestMessage,
} from '../actions/tripRequestActions';
import Loader from './Loader';
import Message from './Message';
import Buttom from './utils/buttons/Buttom';
import { formatMoney } from './utils/currency';
import ModalContainer from './utils/ModalContainer';
import ForwardSkeleton from './utils/skeletons.jsx/ForwardSkeleton';

const ForwardMessage = (props) => {
  const { isOpen, onClose, id, numberId } = props;
  const dispatch = useDispatch();
  const tripRequestSupplierMessages = useSelector((state) => state.tripRequestSupplierMessages);
  const {
    suppliers,
    client_rate,
    singleMessage,
    loadingSuppliers: isLoading,
    sending,
    error,
  } = tripRequestSupplierMessages;
  const [outgoingMessage, setOutgoingMessage] = useState('');
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState('Enviar');
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  const handleOnclose = () => {
    onClose();
    setOutgoingMessage('');
  };
  const textAreaRef = useRef(null);
  useEffect(() => {
    if (!isOpen) return;
    dispatch(getTripRequestSupplierMessages(id, numberId));
  }, [id, numberId, isOpen]);

  useEffect(() => {
    setOutgoingMessage(singleMessage);
  }, [singleMessage]);

  useEffect(() => {
    if (suppliers && suppliers.length > 0) {
      // Get the list of supplier ids where should_send is true
      const shouldSendSupplierIds = suppliers.filter((supplier) => supplier.should_send).map((supplier) => supplier.id);

      // Update selectedSuppliers with the new list
      setSelectedSuppliers(shouldSendSupplierIds);
    }
  }, [suppliers]);

  const handleCheckboxChange = (supplierId) => {
    const updatedSelectedSuppliers = selectedSuppliers.includes(supplierId)
      ? selectedSuppliers.filter((id) => id !== supplierId)
      : [...selectedSuppliers, supplierId];
    setSelectedSuppliers(updatedSelectedSuppliers);
  };

  const handleOutgoingMessageChange = (e) => {
    // Update local state for singleMessage
    setOutgoingMessage(e.target.value);
  };

  useEffect(() => {
    console.log('selectedSuppliers:', selectedSuppliers);
  }, [selectedSuppliers]);

  // };
  const handleSendMessages = async () => {
    try {
      // Dispatch the action to send the message
      setSubmitDisabled(true);
      setSubmitButtonText('Enviando...');
      const messagePairs = [];
      selectedSuppliers.map((supplierId) => {
        messagePairs.push({
          company_channel_id: supplierId,
          message: outgoingMessage,
        });
      });
      setSelectedSuppliers([]);

      // const messageData = {
      //   message: outgoingMessage,
      //   to: selectedSuppliers,
      // };
      const messageData = {
        trip_id: id,
        message: outgoingMessage,
        send_to: selectedSuppliers,
      };

      // // await dispatch(forwardSupplierMessage(id, messageData));
      await dispatch(forwardForwardBidRequestMessage([messageData]));

      setSubmitDisabled(false);
      setSubmitButtonText('Enviar');
      handleOnclose();
      dispatch(getTripRequestDetails(id));
    } catch (error) {
      toast.error('Oh no! Algo salió mal😢');
      console.error('Error sending message:', error);
      console.error('Error sending message:', error.response.data);
    }
  };

  const customStyles = {
    backgroundColor: 'white',
    color: 'darkblue',
    // Add more custom styles as needed
  };
  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={() => {
        handleOnclose();
      }}
      size="xl"
      headerContent={'Reenviar'}
      initialFocusRef={textAreaRef}
      bodyContent={
        isLoading ? (
          <ForwardSkeleton />
        ) : suppliers ? (
          <Box p={4}>
            {/* Editable textarea for singleMessage */}
            <Textarea
              ref={textAreaRef}
              value={outgoingMessage}
              onChange={handleOutgoingMessageChange}
              resize="vertical"
              minHeight="160px"
              color={'gray.900'}
              borderColor={'gray.400'}
              cy="forward-message-textarea"
            />
            {client_rate && (
              <Box>
                <Text fontWeight="bold" fontSize="14px" color="gray.900" mt={6}>
                  Tarifa cliente
                </Text>
                <Text fontWeight="bold" fontSize="20px" color="gray.900" mt={0}>
                  {formatMoney(client_rate)} Pesos
                </Text>
              </Box>
            )}
            {/* Row for each supplier */}
            <Text fontWeight="bold" fontSize="14px" color="gray.900" mt={6}>
              Grupos a los cuales se enviará
            </Text>
            {suppliers.length > 0 ? (
              suppliers.map((supplier) => (
                <Flex
                  key={supplier.id}
                  align="center"
                  mt={5}
                  width={'100%'}
                  justify={'space-between'}
                  alignItems={'center'}
                >
                  <Box ml={2} display="flex" alignItems={'center'} m={0}>
                    <Text fontWeight="normal" fontSize="14px" color="gray.900" m={0}>
                      {supplier.name}
                    </Text>
                    <Text fontWeight="normal" fontSize="14px" color="gray.900" m={0}>
                      {supplier.chat_name}
                    </Text>
                    <Text fontWeight="normal" fontSize="14px" color="gray.900" m={0}>
                      - Costo {supplier.rate ? formatMoney(supplier.rate) : 'No disponible'}
                    </Text>
                  </Box>

                  <Checkbox
                    style={{
                      borderColor: 'gray',
                      backgroundColor: 'white',
                    }}
                    isChecked={selectedSuppliers.includes(supplier.id)}
                    onChange={() => handleCheckboxChange(supplier.id)}
                    cy="forward-checkbox-button"
                  />
                </Flex>
              ))
            ) : (
              <Text color="gray.800" fontWeight={'normal'} fontSize="14px">
                Ya se han enviado a todos los grupos.
              </Text>
            )}
          </Box>
        ) : (
          <Loader />
        )
      }
      footerContent={
        <>
          {sending ? <Loader /> : error ? <Message variant="error">{error}</Message> : null}
          <Box width="100%" display="flex" justifyContent="space-between">
            <Buttom
              color="gray.400"
              variant="ghost"
              onClick={handleOnclose}
              disable={isSubmitDisabled}
              isLoading={sending}
              text={'Cancelar'}
              cy="forward-cancel-button"
            />
            <Buttom
              color="blue.500"
              onClick={handleSendMessages}
              isDisabled={
                isSubmitDisabled ||
                selectedSuppliers?.length === 0 ||
                outgoingMessage === '' ||
                outgoingMessage.length === 0
              }
              isLoading={sending || isSubmitDisabled}
              text={submitButtonText}
              rightIcon={<i className="fas fa-paper-plane"></i>}
              cy="forward-send-button"
            />
          </Box>
        </>
      }
    />
  );
};

export default ForwardMessage;
