export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL';

export const CLIENT_DROPDOWN_LIST_REQUEST = 'CLIENT_DROPDOWN_LIST_REQUEST';
export const CLIENT_DROPDOWN_LIST_SUCCESS = 'CLIENT_DROPDOWN_LIST_SUCCESS';
export const CLIENT_DROPDOWN_LIST_FAIL = 'CLIENT_DROPDOWN_LIST_FAIL';

export const CLIENT_DETAILS_REQUEST = 'CLIENT_DETAILS_REQUEST';
export const CLIENT_DETAILS_SUCCESS = 'CLIENT_DETAILS_SUCCESS';
export const CLIENT_DETAILS_FAIL = 'CLIENT_DETAILS_FAIL';
export const CLIENT_DETAILS_REQUEST_CLEAN = 'CLIENT_DETAILS_REQUEST_CLEAN';

export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_FAIL = 'CLIENT_CREATE_FAIL';

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL';

export const CONTACTS_LIST_REQUEST = 'CONTACTS_LIST_REQUEST';
export const CONTACTS_LIST_SUCCESS = 'CONTACTS_LIST_SUCCESS';
export const CONTACTS_LIST_FAIL = 'CONTACTS_LIST_FAIL';
export const CONTACTS_LIST_CLEAN = 'CONTACTS_LIST_CLEAN';

export const CLIENT_TRANSPORTATION_DROPDOWN_LIST_REQUEST = 'CLIENT_TRANSPORTATION_DROPDOWN_LIST_REQUEST';
export const CLIENT_TRANSPORTATION_DROPDOWN_LIST_SUCCESS = 'CLIENT_TRANSPORTATION_DROPDOWN_LIST_SUCCESS';
export const CLIENT_TRANSPORTATION_DROPDOWN_LIST_FAIL = 'CLIENT_TRANSPORTATION_DROPDOWN_LIST_FAIL';