import React from 'react';
import { HiArrowsRightLeft, HiOutlineMap, HiOutlineMapPin, HiOutlineTruck, HiOutlineUsers } from 'react-icons/hi2';

import { Field, Form, Formik } from 'formik';

import { Button, Flex, Grid, InputGroup, InputLeftElement, background } from '@chakra-ui/react';

import InputSelectFormik from '../../utils/inputs/InputSelectFormik';
import { useSelector } from 'react-redux';
import TripTableDateSelect from '../../TripTableDateSelect';

const TripFilters = ({ withMaxScreen, handleSearchLocation, selectedOptions, filters, handleSelectChange }) => {
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);
  const { clients } = useSelector((state) => state.clientDropdownList);
  const { municipalities } = useSelector((state) => state.municipalityList);

  const adaptedClients = clients.map((client) => ({
    id: client.id,
    verbose_name: client.name,
  }));

  const switchLocations = (values, setFieldValue) => {
    const temp = values.origin;
    setFieldValue('origin', values.destination);
    setFieldValue('destination', temp);

    setTimeout(() => {
      handleSearchLocation({
        origin: values.destination,
        destination: values.origin,
      });
    }, 400);
  };
  const handleCleaning = (name) => {
    const validNames = ['origin', 'destination', 'unit_type', 'client_id'];
    if (validNames.includes(name)) {
      handleSearchLocation({ [name]: '' });
    }
  };

  return (
    <Formik
      initialValues={{ origin: '', destination: '' }}
      onSubmit={handleSearchLocation}
      enableReinitialize
      style={{ position: 'relative', zIndex: 9999 }}
    >
      {({ values, setFieldValue }) => (
        <Form position="relative" m={0}>
          <Flex gap={2} mb={0} mt={2} justifyContent={'start'} w={{ xl: withMaxScreen }} alignItems={'center'}>
            <InputGroup m={0}>
              <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                <HiOutlineMap />
              </InputLeftElement>
              <Field name="origin" component={InputSelectFormik}>
                {({ field, form, meta }) => (
                  <InputSelectFormik
                    options={municipalities}
                    field={field}
                    meta={meta}
                    form={form}
                    placeholder="Origen"
                    handleCleaning={handleCleaning}
                    isCleaning={selectedOptions?.origin !== ''}
                    onChange={(option) => {
                      setFieldValue('origin', option.value);
                      handleSearchLocation({ ...values, origin: option.value });
                    }}
                  />
                )}
              </Field>
            </InputGroup>

            <Button
              variant={'ghost'}
              onClick={() => switchLocations(values, setFieldValue)}
              m={0}
              pb={3}
              size="md"
              color={'#2C5282'}
              isDisabled={!values.origin && !values.destination}
              _hover={{
                color: 'orange',
              }}
            >
              <HiArrowsRightLeft style={{ fontSize: '3rem' }} />
            </Button>
            <InputGroup m={0}>
              <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                <HiOutlineMapPin />
              </InputLeftElement>
              <Field name="destination">
                {({ field, form, meta }) => (
                  <InputSelectFormik
                    options={municipalities}
                    field={field}
                    meta={meta}
                    form={form}
                    placeholder="Destino"
                    handleCleaning={handleCleaning}
                    isCleaning={selectedOptions?.destination !== ''}
                    onChange={(option) => {
                      setFieldValue('destination', option.value);
                      handleSearchLocation({ ...values, destination: option.value });
                    }}
                  />
                )}
              </Field>
            </InputGroup>
          </Flex>
          <Grid
            mb={0}
            templateColumns={{
              base: 'repeat(2, 1fr)',
              md: 'repeat(2, 1fr)',
              xl: 'repeat(4, 1fr)',
            }}
            gap={2}
          >
            <InputGroup m={0}>
              <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                <HiOutlineUsers />
              </InputLeftElement>
              <Field name="client_id">
                {({ field, form, meta }) => {
                  return (
                    <InputSelectFormik
                      options={adaptedClients}
                      field={field}
                      meta={meta}
                      form={form}
                      placeholder="Cliente"
                      onChange={(option) => {
                        setFieldValue('client_id', option.value);
                        handleSearchLocation({ ...values, client_id: option.value });
                      }}
                      isCleaning
                      handleCleaning={handleCleaning}
                    />
                  );
                }}
              </Field>
            </InputGroup>

            <TripTableDateSelect
              columnId={'requested_at'}
              options={filters?.unloading_date || []}
              onSelectChange={handleSelectChange}
              emptyText={'Fecha '}
            />
            <TripTableDateSelect
              columnId={'unloading_date'}
              options={filters?.unloading_date || []}
              onSelectChange={handleSelectChange}
              emptyText={'Fecha descarga'}
            />
            <InputGroup m={0}>
              <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                <HiOutlineTruck />
              </InputLeftElement>
              <Field name="unit_type">
                {({ field, form, meta }) => {
                  return (
                    <InputSelectFormik
                      options={unitTypes}
                      field={field}
                      meta={meta}
                      form={form}
                      placeholder="Unidad"
                      onChange={(option) => {
                        setFieldValue('unit_type', option.value);
                        handleSearchLocation({ ...values, unit_type: option.value });
                      }}
                      isCleaning={selectedOptions?.unit_type !== ''}
                      handleCleaning={handleCleaning}
                    />
                  );
                }}
              </Field>
            </InputGroup>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TripFilters;
