import React from 'react';

import { Field, Formik, useFormik, Form } from 'formik';

import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  ModalCloseButton,
  Text,
  ModalBody,
  RadioGroup,
  FormControl,
  Flex,
  ModalFooter,
  Box,
} from '@chakra-ui/react';

import InputText from '../utils/inputs/InputText';

const RadioCard = React.memo(({ value, label }) => {
  return (
    <label
      style={{
        border: '1px solid',
        borderColor: '#F4f4f4',
        borderRadius: '5px',
        padding: '7px',
        cursor: 'pointer',
      }}
      cy="radio-card"
    >
      <Field type="radio" name="reason" value={value} /> {label}
    </label>
  );
});

const RejectedModal = ({ show, onHide, onSubmit, isLoading, isRejected }) => {
  const formik = useFormik({
    onSubmit: onSubmit,
    initialValues: {
      reasonOther: '',
      reason: 'Sin disponibilidad de unidades',
    },
  });

  return (
    <Modal isOpen={show} onClose={onHide} size="md" isCentered>
      <ModalOverlay />
      <ModalContent zIndex="1500" position="absolute">
        <ModalCloseButton />
        <ModalHeader>
          {isRejected && (
            <Box display={'flex'} justifyContent={'center'} mt={3}>
              <WarningTwoIcon color="blue.200" fontSize={'4xl'} />
            </Box>
          )}
          <Text fontSize="md" textAlign={'center'} color="gray.800" fontWeight="medium" m={0} mt={4}>
            {isRejected ? '¿Quieres activar de nuevo este viaje? ' : '¿Por qué este viaje no será cumplido/asignado?'}
          </Text>
        </ModalHeader>
        {!isRejected && (
          <ModalBody>
            <Formik onSubmit={onSubmit} enableReinitialize initialValues={formik.initialValues}>
              {({ values }) => {
                return (
                  <Form style={{ margin: 0, padding: 0 }}>
                    <RadioGroup display={'flex'} flexDirection={'column'} fontWeight="light" gap={2}>
                      <RadioCard value="Sin disponibilidad de unidades" label="Sin disponibilidad de unidades" />
                      <RadioCard value="Tarifa no negociada con cliente" label="Tarifa no negociada con cliente" />
                      <RadioCard value="Carga tomada por otra línea" label="Carga tomada por otra línea" />
                      <RadioCard value="Otro" label="Otro" />
                    </RadioGroup>
                    {values.reason === 'Otro' && (
                      <FormControl mb={4} color="gray.900" width={'auto'}>
                        <Field name="reasonOther">
                          {({ field, meta, helpers }) => {
                            return (
                              <InputText
                                placeholder="Especifique"
                                rounded={'md'}
                                type={'text'}
                                {...field}
                                {...meta}
                                {...helpers}
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                                cy="reason-other-input"
                              />
                            );
                          }}
                        </Field>
                      </FormControl>
                    )}
                    <Flex justifyContent="space-between" mt={6}>
                      <Button variant="secondary" onClick={onHide} type="button">
                        Cancelar
                      </Button>
                      <Button
                        variant="ghost"
                        bg="blue.500"
                        textColor="white"
                        isLoading={isLoading}
                        type="submit"
                        cy="reject-button"
                      >
                        Confirmar
                      </Button>
                    </Flex>
                  </Form>
                );
              }}
            </Formik>
          </ModalBody>
        )}
        {isRejected && (
          <ModalFooter justifyContent={'space-between'}>
            <Button variant="secondary" onClick={onHide} type="button">
              Cancelar
            </Button>
            <Button
              variant="ghost"
              bg="blue.500"
              textColor="white"
              isLoading={isLoading}
              onClick={onSubmit}
              cy="activate-button"
            >
              Sí
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default RejectedModal;
