/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Text } from '@chakra-ui/react';

import { cleanAvailabilitiesMessages, listAvailabilitiesMessages } from '../../../actions/availabilityActions';
import ChatResponseBox from '../../ChatResponseBox';
import FullChatComponent from '../../FullChatComponent';
import ModalContainer from '../ModalContainer';

const AvailabilityScrollableChatModal = (props) => {
  const { isOpen, onClose, id } = props;
  const dispatch = useDispatch();
  const messagesRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [onlyLoading, setOnlyLoading] = useState(true);

  const { loading: loadingMessageSent } = useSelector((state) => state.tripRequestResponse);
  const tripRequestMessages = useSelector((state) => state?.tripRequestMessages);

  const { loading, messages, priorMessages, laterMessages, chatName, isEndOfConversation, chatProviderId, error } =
    tripRequestMessages;

  useEffect(() => {
    if (!error || !isOpen) return;
    if (error?.status === 404) {
      toast.error('Este mensaje no existe 😔');
    } else {
      toast.error(error.data?.[0] + '😔');
    }
    onClose();
    dispatch(cleanAvailabilitiesMessages());
  }, [error]);

  useEffect(() => {
    if (!isOpen) return;
    setOnlyLoading(true);
    dispatch(listAvailabilitiesMessages(id));
  }, [dispatch, isOpen, id, loadingMessageSent]);

  useEffect(() => {
    // Scroll to the "messages" section when the component mounts
    if (messages?.length === 0 || !onlyLoading) return;

    messagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, onlyLoading]);

  const TIMER_MS = 5000;
  useEffect(() => {
    if (!tripRequestMessages || !isOpen) return;
    const interval = setInterval(() => {
      setOnlyLoading(false);
      dispatch(listAvailabilitiesMessages(id));
    }, TIMER_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [tripRequestMessages]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const customStyles = {
    backgroundColor: 'white',
    color: 'darkblue',
    // Add more custom styles as needed
  };

  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        dispatch(cleanAvailabilitiesMessages());
      }}
      size="xl"
      maxWidth="700px"
      headerContent={
        <Text fontSize="xl" fontWeight="bold" cy="message-title">
          {chatName}
        </Text>
      }
      bodyContent={
        <Box p={4}>
          <FullChatComponent
            handleImageClick={handleImageClick}
            handleCloseModal={handleCloseModal}
            loading={loading}
            messages={messages}
            priorMessages={priorMessages}
            laterMessages={laterMessages}
            messagesRef={messagesRef}
            isEndOfConversation={isEndOfConversation}
            selectedImage={selectedImage}
          />
        </Box>
      }
      footerContent={<ChatResponseBox id={chatProviderId} />}
    />
  );
};

export default AvailabilityScrollableChatModal;
