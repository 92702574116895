import {
  TRIP_REQUEST_LIST_REQUEST,
  TRIP_REQUEST_LIST_SUCCESS,
  TRIP_REQUEST_LIST_FAIL,
  TRIP_REQUEST_DETAILS_REQUEST,
  TRIP_REQUEST_DETAILS_SUCCESS,
  TRIP_REQUEST_DETAILS_FAIL,
  TRIP_REQUEST_MESSAGES_REQUEST,
  TRIP_REQUEST_MESSAGES_CLEAN,
  TRIP_REQUEST_MESSAGES_SUCCESS,
  TRIP_REQUEST_MESSAGES_FAIL,
  TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS,
  TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL,
  TRIP_REQUEST_CREATE_REQUEST,
  TRIP_REQUEST_CREATE_SUCCESS,
  TRIP_REQUEST_CREATE_FAIL,
  TRIP_REQUEST_UPDATE_REQUEST,
  TRIP_REQUEST_UPDATE_SUCCESS,
  TRIP_REQUEST_UPDATE_FAIL,
  TRIP_REQUEST_APPROVE_REQUEST,
  TRIP_REQUEST_APPROVE_SUCCESS,
  TRIP_REQUEST_APPROVE_FAIL,
  TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST,
  TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS,
  TRIP_REQUEST_TRAFFIC_APPROVE_FAIL,
  TRIP_REQUEST_ASSIGN_REQUEST,
  TRIP_REQUEST_ASSIGN_SUCCESS,
  TRIP_REQUEST_ASSIGN_FAIL,
  TRIP_REQUEST_COMPLETE_REQUEST,
  TRIP_REQUEST_COMPLETE_SUCCESS,
  TRIP_REQUEST_COMPLETE_FAIL,
  TRIP_REQUEST_DELETE_REQUEST,
  TRIP_REQUEST_DELETE_SUCCESS,
  TRIP_REQUEST_DELETE_FAIL,
  TRIP_REQUEST_RESPONSE_REQUEST,
  TRIP_REQUEST_RESPONSE_SUCCESS,
  TRIP_REQUEST_RESPONSE_FAIL,
  RESET_TRIP_REQUEST_RESPONSE,
  TRIP_REQUEST_SEND_COMMENT_FAIL,
  TRIP_REQUEST_SEND_COMMENT_SUCCESS,
  TRIP_REQUEST_SEND_COMMENT_REQUEST,
  TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN,
} from '../constants/tripRequestConstants';

export const tripRequestListReducer = (state = { tripRequests: [] }, action) => {
  switch (action.type) {
    case TRIP_REQUEST_LIST_REQUEST:
      return { loading: true, updating: false, tripRequests: [], filters: {} };

    case TRIP_REQUEST_LIST_SUCCESS:
      return {
        loading: false,
        updating: false,
        tripRequests: action.payload.results,
        pagination: action.payload.pagination,
        filters: action.payload.filters,
      };

    case TRIP_REQUEST_LIST_FAIL:
      return {
        loading: false,
        updating: false,
        error: action.payload,
        filters: {},
      };

    default:
      return state;
  }
};

export const tripRequestDetailsReducer = (state = { tripRequest: {} }, action) => {
  switch (action.type) {
    case TRIP_REQUEST_DETAILS_REQUEST:
      return { loading: true, updating: false, tripRequest: {} };

    case TRIP_REQUEST_DETAILS_SUCCESS:
      return { loading: false, updating: false, tripRequest: action.payload };

    case TRIP_REQUEST_DETAILS_FAIL:
      return { loading: false, updating: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestUpdateReducer = (state = { tripRequest: {} }, action) => {
  switch (action.type) {
    case TRIP_REQUEST_UPDATE_REQUEST:
      return { loading: true, updating: true, ...state };

    case TRIP_REQUEST_UPDATE_SUCCESS:
      return { loading: false, updating: false, tripRequest: action.payload };

    case TRIP_REQUEST_UPDATE_FAIL:
      return { loading: false, updating: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_REQUEST_APPROVE_REQUEST:
      return { loading: true, updating: true, ...state };

    case TRIP_REQUEST_APPROVE_SUCCESS:
      // if (action.payload.id) {
      //     const updatedTripRequest = action.payload;
      //     const updatedTripRequests = state.tripRequests.map((tripRequest) =>
      //       tripRequest.id === updatedTripRequest.id ? updatedTripRequest : tripRequest
      //     );
      //     console.log('updatedTripRequests', updatedTripRequests);
      //     return { ...state, loading: false, tripRequests: updatedTripRequests };
      // }

      return { loading: false, updating: false, ...state };

    case TRIP_REQUEST_APPROVE_FAIL:
      return { loading: false, updating: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestTrafficApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST:
      return { loading: true, updating: true, ...state };

    case TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS:
      return { loading: false, updating: false, ...state };

    case TRIP_REQUEST_TRAFFIC_APPROVE_FAIL:
      return { loading: false, updating: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestAssignReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_REQUEST_ASSIGN_REQUEST:
      return { loading: true, updating: true, ...state };

    case TRIP_REQUEST_ASSIGN_SUCCESS:
      return { loading: false, updating: false, success: true };

    case TRIP_REQUEST_ASSIGN_FAIL:
      return { loading: false, updating: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestCompleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_REQUEST_COMPLETE_REQUEST:
      return { loading: true, updating: true, ...state };

    case TRIP_REQUEST_COMPLETE_SUCCESS:
      return { loading: false, updating: false, success: true };

    case TRIP_REQUEST_COMPLETE_FAIL:
      return { loading: false, updating: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestMessagesReducer = (
  state = {
    messages: [],
    priorMessages: [],
    laterMessages: [],
    chatName: null,
  },
  action
) => {
  switch (action.type) {
    case TRIP_REQUEST_MESSAGES_REQUEST:
      return { loading: true, updating: false, ...state };

    case TRIP_REQUEST_MESSAGES_SUCCESS:
      return {
        loading: false,
        updating: false,
        messages: action.payload.messages,
        priorMessages: action.payload.context.prior_messages,
        laterMessages: action.payload.context.later_messages,
        chatName: action.payload.chat_name,
        chatProviderId: action.payload.chat_provider_id,
        isEndOfConversation: action.payload.context.is_latest_messages,
      };

    case TRIP_REQUEST_MESSAGES_FAIL:
      return { loading: false, updating: false, error: action.payload };

    case TRIP_REQUEST_MESSAGES_CLEAN:
      return {
        messages: [],
        priorMessages: [],
        laterMessages: [],
        chatName: null,
        loading: true,
      };

    default:
      return state;
  }
};

export const tripRequestSupplierMessagesReducer = (
  state = { suppliers: [], singleMessage: [], bidSuppliers: [], client_rate: '' },
  action
) => {
  switch (action.type) {
    case TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST:
      return { ...state, loadingSuppliers: true, updating: false };

    case TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS:
      return {
        loadingSuppliers: false,
        updating: false,
        suppliers: action.payload.all_suppliers,
        bidSuppliers: action.payload.suppliers,
        singleMessage: action.payload.message,
        client_rate: action.payload.client_rate,
      };

    case TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL:
      return {
        loadingSuppliers: false,
        updating: false,
        error: action.payload,
      };

    case TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN:
      return {
        loadingSuppliers: null,
        updating: false,
        suppliers: [],
        singleMessage: [],
        client_rate: '',
        bidSuppliers: [],
      };

    default:
      return state;
  }
};

export const tripRequestSendSupplierMessagesReducer = (state = { sending: [] }, action) => {
  switch (action.type) {
    case TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST:
      return { sending: true, ...state };

    case TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS:
      return { sending: false, success: true };

    case TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL:
      return { sending: false, success: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_REQUEST_CREATE_REQUEST:
      return { loading: true };

    case TRIP_REQUEST_CREATE_SUCCESS:
      return { loading: false, tripRequestDetails: action.payload };

    case TRIP_REQUEST_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_REQUEST_DELETE_REQUEST:
      return { loading: true };

    case TRIP_REQUEST_DELETE_SUCCESS:
      return { loading: false, success: true };

    case TRIP_REQUEST_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tripRequestResponseReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_REQUEST_RESPONSE_REQUEST:
      return { loading: true, success: false };

    case TRIP_REQUEST_RESPONSE_SUCCESS:
      return { loading: false, success: true };

    case TRIP_REQUEST_RESPONSE_FAIL:
      return { loading: false, error: action.payload };

    case RESET_TRIP_REQUEST_RESPONSE:
      return { loading: false, error: false, success: false };

    default:
      return state;
  }
};

export const tripRequestSendCommentReducer = (state = { sendingComment: [] }, action) => {
  switch (action.type) {
    case TRIP_REQUEST_SEND_COMMENT_REQUEST:
      return { ...state, sendingComment: true };
    case TRIP_REQUEST_SEND_COMMENT_SUCCESS:
      return { sendingComment: false, success: true };
    case TRIP_REQUEST_SEND_COMMENT_FAIL:
      return { sendingComment: false, success: false, error: action.payload };
    default:
      return state;
  }
};
