import React from 'react';

import { Box, Skeleton } from '@chakra-ui/react';

const ForwardSkeleton = () => {
  return (
    <Box>
      <Skeleton height="100px" />
      <Skeleton height="30px" mt={5} />
      <Skeleton height="20px" mt={5} />
      <Skeleton height="30px" mt={5} />
    </Box>
  );
};

export default ForwardSkeleton;
