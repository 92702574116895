import React from 'react';

import { Box, Button, Flex } from '@chakra-ui/react';

import ModalContainer from '../utils/ModalContainer';
import FileInput from './utils/FileInput';

const customStyles = {
  backgroundColor: 'white',
  color: 'darkblue',
  // Add more custom styles as needed
};
const UploadFileModal = ({
  isOpen,
  onClose,
  setFileUploaded,
  isUploadLoading,
  fileUploaded,
  handleUploadFile,
  handleUploadAndSendFile,
}) => {
  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="100%"
      height={'550px'}
      overflowY="auto"
      maxWidth="500px"
      bodyContent={
        <Box width={'100%'} m={0} p={0}>
          <FileInput setFileUploaded={setFileUploaded} isUploadLoading={isUploadLoading} fileUploaded={fileUploaded} />
          <Flex m={0} p={0} pt={5} mb={-2} justifyContent={'space-between'}>
            <Button bg="gray.200" color="gray.900" size="sm" onClick={onClose} isDisabled={isUploadLoading} m={0}>
              Cancelar
            </Button>
            <Flex gap={2}>
              <Button
                bg="orange.500"
                textColor="white"
                isLoading={isUploadLoading}
                size="sm"
                onClick={handleUploadAndSendFile}
                m={0}
              >
                Subir y enviar
              </Button>
              <Button
                bg="blue.500"
                textColor="white"
                isLoading={isUploadLoading}
                size="sm"
                onClick={handleUploadFile}
                m={0}
              >
                Subir
              </Button>
            </Flex>
          </Flex>
        </Box>
      }
    />
  );
};

export default UploadFileModal;
