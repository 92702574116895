/* eslint-disable no-unused-vars */
import {
  MUNICIPALITY_LIST_REQUEST,
  MUNICIPALITY_LIST_SUCCESS,
  MUNICIPALITY_LIST_FAIL,
  MUNICIPALITY_DROPDOWN_LIST_REQUEST,
  MUNICIPALITY_DROPDOWN_LIST_SUCCESS,
  MUNICIPALITY_DROPDOWN_LIST_FAIL,
  MUNICIPALITY_DETAILS_REQUEST,
  MUNICIPALITY_DETAILS_SUCCESS,
  MUNICIPALITY_DETAILS_FAIL,
  MUNICIPALITY_CREATE_REQUEST,
  MUNICIPALITY_CREATE_SUCCESS,
  MUNICIPALITY_CREATE_FAIL,
  MUNICIPALITY_DELETE_REQUEST,
  MUNICIPALITY_DELETE_SUCCESS,
  MUNICIPALITY_DELETE_FAIL,
  MUNICIPALITY_DROPDOWN_SUCCESS,
  MUNICIPALITY_DROPDOWN_REQUEST,
  MUNICIPALITY_DROPDOWN_FAIL,
} from '../constants/municipalityConstants';
import httpClient from './utils/axiosInstance';

export const listMunicipalities = () => async (dispatch) => {
  try {
    dispatch({ type: MUNICIPALITY_LIST_REQUEST });
    const { data } = await httpClient.get('/api/v1/transport/municipalities/');

    dispatch({
      type: MUNICIPALITY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MUNICIPALITY_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listMunicipalityDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: MUNICIPALITY_DETAILS_REQUEST });

    const { data } = await httpClient.get(`/api/v1/transport/municipalities/${id}`);

    dispatch({
      type: MUNICIPALITY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MUNICIPALITY_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const createMunicipality = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: MUNICIPALITY_CREATE_REQUEST,
    });

    const { data } = await httpClient.post(`/api/v1/municipalities/dropdown/`, formData);

    dispatch({
      type: MUNICIPALITY_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MUNICIPALITY_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
//municipalityList
export const listDropdownMunicipality = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: MUNICIPALITY_DROPDOWN_REQUEST,
    });

    const { data } = await httpClient.get(`/api/v1/municipalities/dropdown/`, formData);

    dispatch({
      type: MUNICIPALITY_DROPDOWN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MUNICIPALITY_DROPDOWN_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};
export const listMunicipalitiesDropdown = () => async (dispatch) => {
  try {
    dispatch({ type: MUNICIPALITY_DROPDOWN_LIST_REQUEST });

    const params = {};

    const { data } = await httpClient.get(`/api/v1/locations/dropdown/`, {
      params: {
        ...params,
      },
    });

    dispatch({
      type: MUNICIPALITY_DROPDOWN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MUNICIPALITY_DROPDOWN_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const deleteMunicipality = (id) => async (dispatch) => {
  try {
    dispatch({ type: MUNICIPALITY_DELETE_REQUEST });
    const { data } = await httpClient.delete(`/api/v1/municipalities/delete/${id}`);
    dispatch({
      type: MUNICIPALITY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: MUNICIPALITY_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
