import React from 'react';

import { Box, Button, Text, Container, FormControl, Flex, Spacer } from '@chakra-ui/react';

import BackgroundIcon from '../components/icons/BackgroundIcon';
import Message from '../components/Message';
import InputPassword from '../components/utils/inputs/InputPassword';
import InputText from '../components/utils/inputs/InputText';

const LoginTemplate = ({
  submitHandler,
  error,
  loading,
  email,
  setEmail,
  password,
  setPassword,
  errorMessage,
  setErrorMessage,
}) => {
  return (
    <Container maxWidth="100vw" width="100%" display={'flex'} justifyContent={'center'}>
      <Flex minHeight="700px" alignItems="center" justifyContent={'center'} width="100%" maxW="1500">
        <Box alignItems="flex-end" display={['none', 'none', 'none', 'flex']} height="100%">
          <BackgroundIcon />
        </Box>
        <Spacer display={['none', 'none', 'none', 'flex']} />
        <Box
          width="420px"
          bg="white"
          pt={10}
          rounded="lg"
          p={4}
          shadow="md"
          border={'1px solid'}
          borderColor="gray.100"
          height={'fit-content'}
          mr={[0, 0, 0, '3%', '10%']}
        >
          <Box borderBottom="1px solid" borderColor="gray.100" mb={4}>
            <Text color="blue.500" fontWeight="bold" fontSize={28}>
              Sign In
            </Text>
          </Box>
          <form style={{ width: '100%' }} onSubmit={submitHandler}>
            <FormControl>
              <InputText
                name="email"
                label="Email"
                placeholder="Enter email"
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMessage({
                    ...errorMessage,
                    email: null,
                  });
                }}
                error={errorMessage?.email}
              />

              <InputPassword
                name="password"
                type="password"
                label="Password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorMessage({
                    ...errorMessage,
                    password: null,
                  });
                }}
                error={errorMessage?.password}
              />
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Button colorScheme="blue" type="submit" mt={10} size="lg" py={1} isLoading={loading}>
                  Sign In
                </Button>
              </Box>
            </FormControl>
          </form>
          {error && <Message variant="error">{error}</Message>}
        </Box>
      </Flex>
    </Container>
  );
};

export default LoginTemplate;
