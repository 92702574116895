import React from 'react';
import { HiArrowsRightLeft, HiOutlineMap, HiOutlineMapPin, HiOutlineTruck } from 'react-icons/hi2';

import { Field, Form, Formik } from 'formik';

import { Button, Flex, Grid, InputGroup, InputLeftElement } from '@chakra-ui/react';

import InputSelectFormik from '../../utils/inputs/InputSelectFormik';
import { FiFilter } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import TripTableDateSelect from '../../TripTableDateSelect';

const AvailabilitiesFilters = ({
  withMaxScreen,
  handleSearchLocation,
  selectedOptions,
  filters,
  handleSelectChange,
}) => {
  const [showAnothersFilters, setShowAnothersFilters] = React.useState(false);
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);
  const { municipalities } = useSelector((state) => state.municipalityList);
  const { transportation } = useSelector((state) => state.transportationClientDropdownList);
  const switchLocations = (values, setFieldValue) => {
    const temp = values.origin;
    setFieldValue('origin', values.destination);
    setFieldValue('destination', temp);

    setTimeout(() => {
      handleSearchLocation({
        origin: values.destination,
        destination: values.origin,
      });
    }, 400);
  };

  const handleCleaning = (name) => {
    if (name === 'origin') {
      handleSearchLocation({ origin: '' });
    } else if (name === 'destination') {
      handleSearchLocation({ destination: '' });
    } else if (name === 'unit_type') {
      handleSearchLocation({ unit_type: '' });
    } else if (name === 'carrier_id') {
      handleSearchLocation({ carrier_id: '' });
    }
  };
  const transportationsArray = transportation.map((transportation) => {
    return {
      id: transportation.id,
      verbose_name: transportation.name,
    };
  });

  return (
    <Formik
      initialValues={{ origin: '', destination: '' }}
      onSubmit={handleSearchLocation}
      enableReinitialize
      style={{ position: 'relative', zIndex: 9999 }}
    >
      {({ values, setFieldValue }) => (
        <Form position="relative">
          <Flex gap={2} mb={5} mt={4} justifyContent={'start'} w={{ xl: withMaxScreen }} alignItems={'center'}>
            <InputGroup m={0}>
              <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                <HiOutlineMap />
              </InputLeftElement>
              <Field name="origin" component={InputSelectFormik}>
                {({ field, form, meta }) => (
                  <InputSelectFormik
                    options={municipalities}
                    field={field}
                    meta={meta}
                    form={form}
                    placeholder="Origen"
                    handleCleaning={handleCleaning}
                    isCleaning={selectedOptions.origin !== ''}
                    onChange={(option) => {
                      setFieldValue('origin', option.value);
                      handleSearchLocation({ ...values, origin: option.value });
                    }}
                  />
                )}
              </Field>
            </InputGroup>

            <Button
              variant={'ghost'}
              onClick={() => switchLocations(values, setFieldValue)}
              m={0}
              pb={3}
              size="md"
              color={'#2C5282'}
              isDisabled={!values.origin && !values.destination}
              _hover={{
                color: 'orange',
              }}
            >
              <HiArrowsRightLeft style={{ fontSize: '3rem' }} />
            </Button>
            <InputGroup m={0}>
              <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                <HiOutlineMapPin />
              </InputLeftElement>
              <Field name="destination">
                {({ field, form, meta }) => (
                  <InputSelectFormik
                    options={municipalities}
                    field={field}
                    meta={meta}
                    form={form}
                    placeholder="Destino"
                    handleCleaning={handleCleaning}
                    isCleaning={selectedOptions.destination !== ''}
                    onChange={(option) => {
                      setFieldValue('destination', option.value);
                      handleSearchLocation({ ...values, destination: option.value });
                    }}
                  />
                )}
              </Field>
            </InputGroup>
            <Button
              type="button"
              variant={'ghost'}
              m={0}
              mb={3}
              size="md"
              onClick={() => setShowAnothersFilters(!showAnothersFilters)}
            >
              <FiFilter
                style={{
                  fontSize: '38px',
                  color: showAnothersFilters ? 'orange' : '#2C5282',
                }}
              />
            </Button>
          </Flex>

          {showAnothersFilters && (
            <Grid
              mb={5}
              templateColumns={{
                base: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(4, 1fr)',
              }}
              gap={4}
            >
              <TripTableDateSelect
                columnId={'offered_at'}
                options={filters.offered_at || []}
                onSelectChange={handleSelectChange}
                emptyText="Fecha "
              />
              <InputGroup m={0}>
                <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                  <HiOutlineTruck />
                </InputLeftElement>
                <Field name="unit_type">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={unitTypes}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Unidad"
                        onChange={(option) => {
                          setFieldValue('unit_type', option.value);
                          handleSearchLocation({ ...values, unit_type: option.value });
                        }}
                        isCleaning={selectedOptions.unit_type !== ''}
                        handleCleaning={handleCleaning}
                      />
                    );
                  }}
                </Field>
              </InputGroup>

              <TripTableDateSelect
                columnId={'start_date'}
                options={filters?.unloading_date || []}
                onSelectChange={handleSelectChange}
                emptyText={'Fecha disponible'}
              />
              <InputGroup m={0}>
                <InputLeftElement pointerEvents="none" color="gray.900" fontSize="20px" zIndex={10000}>
                  <HiOutlineTruck />
                </InputLeftElement>
                <Field name="carrier_id">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={transportationsArray}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Transportista"
                        onChange={(option) => {
                          console.log('option', option);
                          setFieldValue('carrier_id', option.value);
                          handleSearchLocation({ ...values, carrier_id: option.value });
                        }}
                        isCleaning={selectedOptions.carrier_id !== ''}
                        handleCleaning={handleCleaning}
                      />
                    );
                  }}
                </Field>
              </InputGroup>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AvailabilitiesFilters;
