import {
  METRO_AREA_DROPDOWN_LIST_REQUEST,
  METRO_AREA_DROPDOWN_LIST_SUCCESS,
  METRO_AREA_DROPDOWN_LIST_FAIL,
  METRO_AREA_CREATE_REQUEST,
  METRO_AREA_CREATE_SUCCESS,
  METRO_AREA_CREATE_FAIL,
} from '../constants/metroAreaConstants';

export const metroAreaDropdownListReducer = (state = { metroAreas: [] }, action) => {
  switch (action.type) {
    case METRO_AREA_DROPDOWN_LIST_REQUEST:
      return { loading: true, metroAreas: [] };

    case METRO_AREA_DROPDOWN_LIST_SUCCESS:
      return { loading: false, metroAreas: action.payload };

    case METRO_AREA_DROPDOWN_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const metroAreaCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case METRO_AREA_CREATE_REQUEST:
      return { loading: true };

    case METRO_AREA_CREATE_SUCCESS:
      return { loading: false, metroAreaDetails: action.payload };

    case METRO_AREA_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
