export const METRO_AREA_LIST_REQUEST = 'METRO_AREA_LIST_REQUEST';
export const METRO_AREA_LIST_SUCCESS = 'METRO_AREA_LIST_SUCCESS';
export const METRO_AREA_LIST_FAIL = 'METRO_AREA_LIST_FAIL';

export const METRO_AREA_DROPDOWN_LIST_REQUEST = 'METRO_AREA_DROPDOWN_LIST_REQUEST';
export const METRO_AREA_DROPDOWN_LIST_SUCCESS = 'METRO_AREA_DROPDOWN_LIST_SUCCESS';
export const METRO_AREA_DROPDOWN_LIST_FAIL = 'METRO_AREA_DROPDOWN_LIST_FAIL';

export const METRO_AREA_DETAILS_REQUEST = 'METRO_AREA_DETAILS_REQUEST';
export const METRO_AREA_DETAILS_SUCCESS = 'METRO_AREA_DETAILS_SUCCESS';
export const METRO_AREA_DETAILS_FAIL = 'METRO_AREA_DETAILS_FAIL';

export const METRO_AREA_CREATE_REQUEST = 'METRO_AREA_CREATE_REQUEST';
export const METRO_AREA_CREATE_SUCCESS = 'METRO_AREA_CREATE_SUCCESS';
export const METRO_AREA_CREATE_FAIL = 'METRO_AREA_CREATE_FAIL';

export const METRO_AREA_DELETE_REQUEST = 'METRO_AREA_DELETE_REQUEST';
export const METRO_AREA_DELETE_SUCCESS = 'METRO_AREA_DELETE_SUCCESS';
export const METRO_AREA_DELETE_FAIL = 'METRO_AREA_DELETE_FAIL';
