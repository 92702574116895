import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HiOutlineMailOpen, HiOutlineMail, HiOutlineTrash } from 'react-icons/hi';
import { HiOutlineDocumentPlus } from 'react-icons/hi2';

import { ChatIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

import Buttom from './Buttom';
import { duplicateTrip } from '../../../actions/clientActions';
//TODO: this is duplicate in actions trip request button outline
const ContainerButtom = ({ children }) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="justify-between"
      p={0}
      m={0}
      height="auto"
      width="fit-content"
      border="none"
    >
      {children}
    </Box>
  );
};

const ActionsAvailabilityButton = ({
  id,
  readStatus,
  disabledButtons,
  handleReadStatusToggle,

  openSureModal,
  openDetailMessage,
}) => {
  const dispatch = useDispatch();
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  return (
    <ContainerButtom>
      <Buttom
        variant="ghost"
        size="xs"
        text={
          <div>
            <ChatIcon />
          </div>
        }
        onClick={() => openDetailMessage(id)}
        mb={1}
        width="30px"
        mt={1}
        py={1}
        px={1}
        textColor="blue.700"
        tooltipLabel="Mensajes"
        maxHeight="100px"
        cy="messages-button"
        //
      />

      <Buttom
        variant="ghost"
        size="xs"
        // color="pink.100"
        text={
          <div>
            <HiOutlineTrash
              style={{
                fontSize: '17px',
              }}
            />
          </div>
        }
        onClick={() => openSureModal(id)}
        mb={1}
        width="30px"
        mt={1}
        py={1}
        pr={0}
        pl={1}
        mr={0}
        textColor="blue.700"
        tooltipLabel="Eliminar"
        cy="delete-button"
      />
      <Buttom
        variant="ghost"
        size="xs"
        // color="blue.100"
        text={
          readStatus[id] ? (
            <div>
              <HiOutlineMailOpen
                style={{
                  fontSize: '17px',
                }}
              />
            </div>
          ) : (
            <div>
              <HiOutlineMail
                style={{
                  fontSize: '17px',
                }}
              />
            </div>
          )
        }
        onClick={() => handleReadStatusToggle(id)}
        mb={1}
        width="30px"
        mt={1}
        py={1}
        pr={0}
        pl={1}
        mr={0}
        textColor="blue.700"
        isLoading={disabledButtons[id]}
        tooltipLabel={readStatus[id] ? 'Marcar como no leído' : 'Marcar como leído'}
        cy="read-unread-button"
      />
      {userDetails?.is_staff && (
        <Buttom
          variant="ghost"
          type="button"
          size="xs"
          text={
            <div>
              <HiOutlineDocumentPlus
                style={{
                  fontSize: '17px',
                }}
              />
            </div>
          }
          onClick={(e) => {
            e.preventDefault();
            dispatch(duplicateTrip(id));
          }}
          mb={1}
          width="30px"
          mt={1}
          py={1}
          pr={0}
          pl={1}
          mr={0}
          cy="reject-button"
          textColor="blue.700"
          tooltipLabel={'Duplicar viaje'}
        />
      )}
    </ContainerButtom>
  );
};

export default ActionsAvailabilityButton;
