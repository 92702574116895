/* eslint-disable no-unused-vars */
import toast from 'react-hot-toast';

import {
  TRIP_REQUEST_SEND_COMMENT_REQUEST,
  TRIP_REQUEST_SEND_COMMENT_SUCCESS,
  TRIP_REQUEST_SEND_COMMENT_FAIL,
  TRIP_REQUEST_LIST_REQUEST,
  TRIP_REQUEST_LIST_REFRESH_REQUEST,
  TRIP_REQUEST_LIST_SUCCESS,
  TRIP_REQUEST_LIST_FAIL,
  TRIP_REQUEST_DETAILS_REQUEST,
  TRIP_REQUEST_DETAILS_SUCCESS,
  TRIP_REQUEST_DETAILS_FAIL,
  TRIP_REQUEST_MESSAGES_REQUEST,
  TRIP_REQUEST_MESSAGES_SUCCESS,
  TRIP_REQUEST_MESSAGES_FAIL,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS,
  TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS,
  TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL,
  TRIP_REQUEST_UPDATE_REQUEST,
  TRIP_REQUEST_UPDATE_SUCCESS,
  TRIP_REQUEST_UPDATE_FAIL,
  TRIP_REQUEST_APPROVE_REQUEST,
  TRIP_REQUEST_APPROVE_SUCCESS,
  TRIP_REQUEST_APPROVE_FAIL,
  TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST,
  TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS,
  TRIP_REQUEST_TRAFFIC_APPROVE_FAIL,
  TRIP_REQUEST_ASSIGN_REQUEST,
  TRIP_REQUEST_ASSIGN_SUCCESS,
  TRIP_REQUEST_ASSIGN_FAIL,
  TRIP_REQUEST_COMPLETE_REQUEST,
  TRIP_REQUEST_COMPLETE_SUCCESS,
  TRIP_REQUEST_COMPLETE_FAIL,
  TRIP_REQUEST_DELETE_REQUEST,
  TRIP_REQUEST_DELETE_SUCCESS,
  TRIP_REQUEST_DELETE_FAIL,
  TRIP_REQUEST_RESPONSE_REQUEST,
  TRIP_REQUEST_RESPONSE_SUCCESS,
  TRIP_REQUEST_RESPONSE_FAIL,
  RESET_TRIP_REQUEST_RESPONSE,
  TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST,
  TRIP_REQUEST_MESSAGES_CLEAN,
  TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN,
} from '../constants/tripRequestConstants';
import httpClient from './utils/axiosInstance';

export const listTripRequests = (params) => async (dispatch, getState) => {
  try {
    if (params.refresh) {
      dispatch({ type: TRIP_REQUEST_LIST_REFRESH_REQUEST });
    } else {
      dispatch({ type: TRIP_REQUEST_LIST_REQUEST });
    }

    const { data } = await httpClient.get(`/api/v1/trip-requests/`, {
      params: {
        ...params,
      },
    });

    try {
      var pagination = {
        totalItems: data.total_items,
        totalPages: data.total_pages,
        pageSize: data.page_size,
        currentPage: data.current,
        previousPageURL: data.previous,
        nextPageURL: data.next,
      };
    } catch (error) {
      console.error('Error processing pagination data:', error);

      // Provide default values or handle the absence of properties
      pagination = {
        totalItems: 0,
        totalPages: 0,
        pageSize: 0,
        currentPage: 0,
        previousPageURL: null,
        nextPageURL: null,
      };
    }

    dispatch({
      type: TRIP_REQUEST_LIST_SUCCESS,
      payload: {
        results: data.results,
        pagination: pagination,
        filters: data.filters,
      },
    });
  } catch (error) {
    console.error('error', error.response.data);
    dispatch({
      type: TRIP_REQUEST_LIST_FAIL,
      payload: error.response && error.response.data?.detail ? error.response.data?.detail : error.message,
    });
  }
};

export const listTripRequestsDownload = (params) => async (dispatch, getState) => {
  try {
    const response = await httpClient.get(`/api/v1/trip-requests/`, {
      params: {
        ...params,
        download: true,
      },
      responseType: 'blob',
    });

    // Create a Blob object from the response data
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'data.csv';

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link element from the document
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CSV:', error);
    // Handle error as needed
  }
};

export const getTripRequestDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DETAILS_REQUEST });
    const { data } = await httpClient.get(`/api/v1/trip-requests/${id}`);

    dispatch({
      type: TRIP_REQUEST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const updateTripRequest = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_UPDATE_REQUEST });

    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/`, updatedData);

    dispatch({
      type: TRIP_REQUEST_UPDATE_SUCCESS,
      payload: data,
    });
    toast.success('Viaje actualizado correctamente');
  } catch (error) {
    toast.error('Hubo un error actualizando el viaje 😢');
    dispatch({
      type: TRIP_REQUEST_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listTripRequestMessages = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_MESSAGES_REQUEST });

    const { data } = await httpClient.get(`/api/v1/trip-requests/${id}/messages`);

    dispatch({
      type: TRIP_REQUEST_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_MESSAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.response,
    });
  }
};
export const cleanlistTripRequestMessages = () => (dispatch) => {
  dispatch({ type: TRIP_REQUEST_MESSAGES_CLEAN });
};

export const deleteTripRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DELETE_REQUEST });

    const { data } = await httpClient.delete(`/api/v1/trip-requests/${id}`);
    dispatch({
      type: TRIP_REQUEST_DELETE_SUCCESS,
    });
    toast.success('Viaje eliminado exitosamente');
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const respondToTripRequest = (id, messageData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_RESPONSE_REQUEST });

    const { data } = await httpClient.post(`/api/v1/messaging/send-message`, messageData);

    dispatch({
      type: TRIP_REQUEST_RESPONSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_RESPONSE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const resetTripRequestResponse = () => (dispatch) => {
  dispatch({ type: RESET_TRIP_REQUEST_RESPONSE });
};

export const getTripRequestSupplierMessages = (id, number) => async (dispatch) => {
  try {
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST,
    });
    const { data } = await httpClient.get(`/api/v1/messaging/trip-requests/${id}/suppliers/forward/${number}`);
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

// export const forwardSupplierMessage = (id, messageData) => async (dispatch) => {
//   // try {
//   //   dispatch({ type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST });
//   //   const { data } = await httpClient.post(`/api/v1/messaging/trip-requests/${id}/suppliers/forward`, messageData);
//   //   dispatch({
//   //     type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS,
//   //     payload: data,
//   //   });
//   //   toast.success('Enviado exitosamente');
//   // } catch (error) {
//   //   dispatch({
//   //     type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL,
//   //     payload: error.response && error.response.data.message ? error.response.data.message : error.message,
//   //   });
//   // }
// };
export const forwardForwardBidRequestMessage = (messageData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST });

    await httpClient.post('/api/v1/trip-requests/forward/', messageData);

    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS,
    });
  } catch (error) {
    console.error('error', error.response);
    toast.error('Oh no! Algo salió mal😢');
    dispatch({
      type: TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
export const cleanlistTripRequestForwardMessages = () => (dispatch) => {
  dispatch({ type: TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN });
};

export const approveTripRequest = (id, isApproved) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_APPROVE_REQUEST });

    const approve = { is_approved: isApproved };
    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/approve/`, approve);
    dispatch({
      type: TRIP_REQUEST_APPROVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_APPROVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const approveTripRequestByTraffic = (id, isApproved) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST });

    const approve = { is_approved_by_traffic: isApproved };
    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/approve/traffic/`, approve);
    dispatch({
      type: TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_TRAFFIC_APPROVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const assignTripRequest = (id, supplierId) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_ASSIGN_REQUEST });

    const assign = { assigned_transporter: supplierId };
    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/assign/`, assign);

    // Simulate a delay of 1.5 seconds
    // await new Promise(resolve => setTimeout(resolve, 1500));
    // const data = { success: true };

    dispatch({
      type: TRIP_REQUEST_ASSIGN_SUCCESS,
      payload: data,
    });
    toast.success('Viaje Asignado 🚚');
  } catch (error) {
    toast.error('Oh no! Something went bad');
    dispatch({
      type: TRIP_REQUEST_ASSIGN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const completeTripRequest = (id, isComplete) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_COMPLETE_REQUEST });

    const complete = { is_complete: isComplete };
    // const { data } = await axios.put(
    //   `/api/v1/trip-requests/${id}/complete/`,
    //   complete,
    //   config
    // );

    // Simulate a delay of 1.5 seconds
    await new Promise((resolve) => setTimeout(resolve, 1500));
    const data = { success: true };

    dispatch({
      type: TRIP_REQUEST_COMPLETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_COMPLETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
// TRIP_REQUEST_SEND_COMMENT
export const sendCommentTripRequest = (id, messageData) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRIP_REQUEST_SEND_COMMENT_REQUEST });

    const { data } = await httpClient.post(`/api/v1/trip-requests/${id}/comment/`, messageData);

    dispatch({
      type: TRIP_REQUEST_SEND_COMMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_SEND_COMMENT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const unreadTripRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DETAILS_REQUEST });

    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/unread/`);
    dispatch({
      type: TRIP_REQUEST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
export const rejectTripRequest = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_DETAILS_REQUEST });

    const { data } = await httpClient.put(`/api/v1/trip-requests/${id}/reject`, {
      is_rejected: updatedData?.is_rejected,
      rejection_reason: updatedData?.rejection_reason,
    });
    dispatch({
      type: TRIP_REQUEST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
