import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const MyCarousel = ({ items }) => {
  // const renderArrows = ({ nextArrow, prevArrow }) => (
  //   <div className="custom-arrows">
  //     {prevArrow && (
  //       <button
  //         onClick={() => {
  //           console.log("hola");
  //           prevArrow();
  //         }}
  //       >
  //         Prev
  //       </button>
  //     )}
  //     {nextArrow && <button onClick={nextArrow}>Next</button>}
  //   </div>
  // );
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container"
      // renderArrows={renderArrows}
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 860,
          },
          items: 3,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 860,
            min: 464,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {items}
    </Carousel>
  );
};

export default MyCarousel;
