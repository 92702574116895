/* eslint-disable no-case-declarations */
/* eslint-disable no-inner-declarations */
import {
  DECISION_TREE_LIST_REQUEST,
  DECISION_TREE_LIST_SUCCESS,
  DECISION_TREE_LIST_FAIL,
  DECISION_TREE_DETAILS_REQUEST,
  DECISION_TREE_DETAILS_SUCCESS,
  DECISION_TREE_DETAILS_FAIL,
  DECISION_TREE_CREATE_REQUEST,
  DECISION_TREE_CREATE_SUCCESS,
  DECISION_TREE_CREATE_FAIL,
  DECISION_TREE_DELETE_REQUEST,
  DECISION_TREE_DELETE_SUCCESS,
  DECISION_TREE_DELETE_FAIL,
} from '../constants/decisionTreeConstants';

export const decisionTreeListReducer = (state = { decisionTrees: [] }, action) => {
  switch (action.type) {
    case DECISION_TREE_LIST_REQUEST:
      return { loading: true, decisionTrees: [] };

    case DECISION_TREE_LIST_SUCCESS:
      return { loading: false, decisionTrees: action.payload };

    case DECISION_TREE_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const decisionTreeDetailsReducer = (state = { decisionTree: {} }, action) => {
  switch (action.type) {
    case DECISION_TREE_DETAILS_REQUEST:
      return { loading: true, ...state };

    case DECISION_TREE_DETAILS_SUCCESS:
      function groupByDepthLevelList(data) {
        let result = [];
        data.forEach((item) => {
          let depth = item.depth_level;
          if (!result[depth]) {
            result[depth] = [];
          }
          result[depth].push(item);
        });
        return result;
      }
      const levels = groupByDepthLevelList(action.payload.actions);
      action.payload.actionLevels = levels;
      return { loading: false, decisionTree: action.payload };

    case DECISION_TREE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const decisionTreeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case DECISION_TREE_CREATE_REQUEST:
      return { loading: true };

    case DECISION_TREE_CREATE_SUCCESS:
      return { loading: false, decisionTreeDetails: action.payload };

    case DECISION_TREE_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const decisionTreeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DECISION_TREE_DELETE_REQUEST:
      return { loading: true };

    case DECISION_TREE_DELETE_SUCCESS:
      return { loading: false, success: true };

    case DECISION_TREE_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
