/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { useField } from 'formik';

import { FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react';

const CustomDatePickerInput = React.forwardRef(({ value, onClick, cy }, ref) => (
  <Input
    ref={ref}
    value={value}
    onClick={onClick}
    cy={cy}
    style={{
      background: 'transparent',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      padding: '0.375rem 0.75rem',
    }}
    w={'100%'}
  />
));

const DatePickerField = ({
  label,
  showTimeSelect = false,
  showIcon = false,
  dateFormat = 'yyyy-MM-dd',
  showTimeSelectOnly = false,
  timeFormat,
  timeCaption,
  cy,
  placeholderText = 'Select date',
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <FormControl
      isInvalid={meta.touched && meta.error}
      style={{ width: '100%' }}
      color={'gray.700'}
      position="relative"
      zIndex={9009}
    >
      <FormLabel
        cy={`${cy}-label`}
        color={meta.touched && meta.error ? 'red.500' : 'gray.700'}
        position="relative"
        zIndex={0}
      >
        {label}
      </FormLabel>
      <DatePicker
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(date) => helpers.setValue(date)}
        onBlur={() => helpers.setTouched(true)}
        showTimeSelect={showTimeSelect} // Set to true to include time picker
        showTimeSelectOnly={showTimeSelectOnly}
        timeFormat={timeFormat}
        timeIntervals={5}
        dateFormat={dateFormat}
        style={{
          background: 'white',
          width: '100%',
          position: 'absolute',
          color: 'gray.700',
          borderColor: '#A0AEC0',
          with: '100%',
          zIndex: 1000,
        }}
        customInput={<CustomDatePickerInput cy={cy} />}
        showIcon={showIcon}
        className="custom-datepicker"
        timeCaption={timeCaption}
        placeholderText={placeholderText}
      />
      {meta.touched && meta.error && <FormErrorMessage fontWeight={'medium'}>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
};

export default DatePickerField;
