/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import { Formik, Field, Form, useFormik } from 'formik'; // Import Formik components
import moment from 'moment';

import {
  Flex,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Select,
  HStack,
  Spacer,
  Skeleton,
} from '@chakra-ui/react';

import { getAvailabilityDetail, updateAvailability } from '../../actions/availabilityActions';
import { listMunicipalitiesDropdown } from '../../actions/municipalityActions';
import { listUnitTypesDropdown } from '../../actions/unitTypeActions';
import DatePickerField from '../DatePickerField';
import Message from '../Message';
import { ContanerText } from './AvailabilityDetails';
import InputSelectFormik from '../utils/inputs/InputSelectFormik';

function EditAvailabilityDetails(props) {
  const { id, setEditDetail, openModal, loading } = props;

  const dispatch = useDispatch();
  const availabilitiyDetail = useSelector((state) => state.availabilityDetail);
  const { availability, error } = availabilitiyDetail;

  const initialValues = {
    client: availability?.channel?.client?.name || '',
    origin: availability?.origin || null,
    destination: availability?.destination || null,
    unit_type: availability?.unit_type || null,
    start_date: availability?.start_date || '',
    start_time: availability?.unloading_date || '',
  };

  const [updateLoading, setUpdateLoading] = useState(false);
  // availabilityUpdate
  const { municipalities } = useSelector((state) => state.municipalityDropdownList);
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);

  const [formValues, setFormValues] = useState(initialValues);

  // Fetch filter options when the component mounts
  useEffect(() => {
    dispatch(listMunicipalitiesDropdown());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listUnitTypesDropdown());
  }, [dispatch]);

  const onSubmit = async (values) => {
    const formattedValues = {
      ...values,
      start_date:
        values.start_date && moment(values.start_date).isValid()
          ? moment(values.start_date).utcOffset(-6, true).format('YYYY-MM-DD')
          : null,
      start_time:
        values.start_time && moment(values.start_time).isValid()
          ? moment(values.start_time).utcOffset(-6, true).format('HH:mm')
          : null,
    };

    const filterFields = ['origin', 'destination', 'unit_type', 'start_date', 'start_time']; // Replace with your actual list of fields

    const updatedValues = Object.keys(formattedValues).reduce((acc, key) => {
      if (filterFields.includes(key)) {
        const value = formattedValues[key];
        if (value !== null) {
          if (typeof value === 'object' && value !== null && 'id' in value) {
            acc[key] = value.id;
          } else {
            acc[key] = value;
          }
        }
      }
      return acc;
    }, {});
    setUpdateLoading(true);
    await dispatch(updateAvailability(id, updatedValues))
      .then(() => {
        toast.success('Viaje actualizado correctamente');
        setEditDetail(false);
        dispatch(getAvailabilityDetail(id));
        setUpdateLoading(false);
        // postUpdateCallback();
      })
      .catch((error) => {
        console.error('Error updating trip request:', error);
        toast.error('Hubo un error actualizando el viaje 😢');
        setUpdateLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (availability && Object.keys(formValues).every((key) => !formValues[key])) {
      setFormValues({
        client: availability?.channel?.client?.name || '',
        origin: availability?.origin || null,
        destination: availability?.destination || null,
        unit_type: availability?.unit_type || null,
        start_date: availability?.start_date || '',
        start_time: availability?.start_time || '',
      });
    }
  }, [availability]);
  useEffect(() => {}, [formik.values, formik.dirty, formik.isSubmitting]);

  return (
    <Container
      maxWidth={'100%'}
      boxShadow={'md'}
      border="1px solid"
      borderColor={'gray.200'}
      py={{ base: 2, lg: 4 }}
      mx={0}
      borderRadius={'lg'}
      height={{ lg: '570px' }}
      overflowY={'scroll'}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
    >
      {error ? <Message variant="error">{error}</Message> : null}
      {!loading && !error && !availability ? (
        <Message variant="info">No trip details available.</Message>
      ) : loading || updateLoading ? (
        <Skeleton height="400px" mt={5} />
      ) : (
        <Formik initialValues={formValues} onSubmit={onSubmit} enableReinitialize>
          <Form style={{ margin: 0, padding: 0 }}>
            <ContanerText title="Cliente:" description={availability?.channel?.client?.name} />
            <Flex
              mt={3}
              w="100%"
              alignItems={{ base: 'flex-start', md: 'center' }}
              gap={2}
              justifyContent={{ base: 'flex-start', md: 'space-between' }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <FormControl mb={4} color="gray.900" width={'auto'}>
                <Field
                  style={{ width: '100%' }}
                  name="start_date"
                  render={({ field, meta, helpers }) => (
                    <DatePickerField label="Fecha Disponible" {...field} {...meta} {...helpers} cy="loading-date" />
                  )}
                />
              </FormControl>

              <FormControl mb={4} color="gray.900" width={'auto'}>
                <Field
                  name="start_time"
                  render={({ field, meta, helpers }) => {
                    const value = availability?.start_time;
                    const selectedDate = value instanceof Date ? value : new Date(`1970-01-01T${value}`);
                    return (
                      <DatePickerField
                        label="Hora disponible"
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        name="start_time"
                        {...field}
                        {...meta}
                        {...helpers}
                        selected={selectedDate}
                        value={field.value ? field.value : value}
                        onChange={(date) => {
                          helpers.setValue(date);
                        }}
                        cy="loading-time"
                      />
                    );
                  }}
                />
              </FormControl>
            </Flex>
            <FormControl id="origin" color="gray.900" mt={3}>
              <FormLabel color="gray.900">Origen</FormLabel>
              <HStack>
                <Field name="origin">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={municipalities}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Origen"
                        isCleaning={false}
                        isEditing
                        defaultValue={{
                          value: formValues?.origin?.id,
                          label: formValues?.origin?.name,
                        }}
                      />
                    );
                  }}
                </Field>
              </HStack>
            </FormControl>

            <FormControl id="destination" color="gray.900" my={4}>
              <FormLabel color="gray.900">Destino</FormLabel>
              <HStack>
                <Field name="destination">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={municipalities}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Destino"
                        isCleaning={false}
                        isEditing
                        defaultValue={{
                          value: formValues?.destination?.id,
                          label: formValues?.destination?.name,
                        }}
                      />
                    );
                  }}
                </Field>
              </HStack>
            </FormControl>

            <FormControl id="unitType" color="gray.900" mb={3}>
              <FormLabel color="gray.900">Unidad</FormLabel>
              <HStack>
                <Field name="unit_type">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={unitTypes}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Unidad"
                        isCleaning={false}
                        isEditing
                        defaultValue={{
                          value: formValues?.unit_type?.id,
                          label: formValues?.unit_type?.name,
                        }}
                      />
                    );
                  }}
                </Field>
              </HStack>
            </FormControl>

            <Flex mt={8}>
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <Button
                  colorScheme="gray"
                  type="button"
                  onClick={() => setEditDetail(false)}
                  size={{ base: 'xs', sm: 'sm', lg: 'md' }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="red"
                  type="button"
                  onClick={() => openModal(id)}
                  size={{ base: 'xs', sm: 'sm', lg: 'md' }}
                >
                  Borrar viaje
                </Button>
              </Box>
              <Spacer />
              <Box>
                <Button colorScheme="blue" type="submit" isDisabled={loading} size={{ base: 'xs', sm: 'sm', lg: 'md' }}>
                  Actualizar viaje
                </Button>
              </Box>
            </Flex>
          </Form>
        </Formik>
      )}
    </Container>
  );
}

export default EditAvailabilityDetails;
