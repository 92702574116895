/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { listMetroAreasDropdown } from '../actions/metroAreaActions';
import { createMunicipality } from '../actions/municipalityActions';
import { listStatesDropdown } from '../actions/stateActions';

const CreateMunicipalityModal = ({ isOpen, onClose, refreshDropdowns }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ name: '', state: null, metro: null });
  const { states } = useSelector((state) => state.stateDropdownList);
  const { metroAreas } = useSelector((state) => state.metroAreaDropdownList);

  const [metroAreaList, setMetroAreaList] = useState([]);

  useEffect(() => {
    dispatch(listStatesDropdown());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listMetroAreasDropdown());
  }, [dispatch]);

  useEffect(() => {
    // Check if metroAreas is not empty and if formData.metroArea is not already set
    if (states && states.length > 0 && !formData.state) {
      // Set formData.metroArea to the first option
      setFormData((prev) => ({ ...prev, state: states[0].id }));
    }
  }, [states, formData.state]);

  useEffect(() => {
    // Check if metroAreas is not empty and if formData.metroArea is not already set
    if (metroAreas && metroAreas.length > 0 && !formData.metro) {
      // Prepend a null option to the metroAreas array
      // metroAreas = [{ id: null, verbose_name: 'Select Metro Area' }, ...metroAreas];
      console.log('metroAreas:', metroAreas);
      // Set formData.metroArea to the first option
      // setFormData((prev) => ({ ...prev, metro: metroAreas[0].id }));
    }
  }, [metroAreas, formData.metro]);

  useEffect(() => {
    // Check if metroAreas is not empty and if formData.metroArea is not already set
    if (metroAreas && metroAreas.length > 0 && !formData.metro) {
      // Prepend a null option to the metroAreas array
      // metroAreas = [{ id: null, verbose_name: 'Select Metro Area' }, ...metroAreas];
      // Set formData.metroArea to the first option
      setMetroAreaList([{ id: null, verbose_name: '-----' }, ...metroAreas]);
      setFormData((prev) => ({ ...prev, metro: null }));
    }
  }, [metroAreas]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'state' || name === 'metro' ? parseInt(value, 10) : value;
    setFormData((prev) => ({ ...prev, [name]: parsedValue }));
  };

  const handleSubmit = async () => {
    // Dispatch the createMunicipality action
    try {
      await dispatch(createMunicipality(formData));
      // Refresh the origin and destination dropdowns
      refreshDropdowns();
      toast.success('Municipalidad creada exitosamente');

      // Close the modal
      onClose();
    } catch (error) {
      console.error('Error creating municipality:', error);
      toast.success('Oh no, ocurrió un error 😢');

      // Handle errors if needed
    }
  };

  const customStyles = {
    backgroundColor: 'white',
    color: 'darkblue',
    // Add more custom styles as needed
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent style={customStyles}>
        <ModalHeader>Create New Municipality</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>State</FormLabel>
            <Select name="state" value={formData.state} onChange={handleChange}>
              {states
                ? states.map((option) => (
                    <option key={option?.id} value={option.id}>
                      {option.verbose_name}
                    </option>
                  ))
                : null}
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Metro Area</FormLabel>
            <Select name="metro" value={formData.metro} onChange={handleChange}>
              {metroAreaList
                ? metroAreaList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.verbose_name}
                    </option>
                  ))
                : null}
            </Select>
          </FormControl>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Create Municipality
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateMunicipalityModal;
