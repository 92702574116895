import {
  STATE_DROPDOWN_LIST_REQUEST,
  STATE_DROPDOWN_LIST_SUCCESS,
  STATE_DROPDOWN_LIST_FAIL,
  STATE_CREATE_REQUEST,
  STATE_CREATE_SUCCESS,
  STATE_CREATE_FAIL,
} from '../constants/stateConstants';

export const stateDropdownListReducer = (state = { states: [] }, action) => {
  switch (action.type) {
    case STATE_DROPDOWN_LIST_REQUEST:
      return { loading: true, states: [] };

    case STATE_DROPDOWN_LIST_SUCCESS:
      return { loading: false, states: action.payload };

    case STATE_DROPDOWN_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const stateCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STATE_CREATE_REQUEST:
      return { loading: true };

    case STATE_CREATE_SUCCESS:
      return { loading: false, stateDetails: action.payload };

    case STATE_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
