import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form } from 'formik'; // Import Formik components
import * as yup from 'yup';

import { Flex } from '@chakra-ui/react';

import DatePickerField from '../../DatePickerField';
import Buttom from '../buttons/Buttom';
import InputSelectFormik from '../inputs/InputSelectFormik';
import InputTextFormik from '../inputs/InputTextFormik';
import {
  listAsignmentsDetails,
  listContacts,
} from '../../../actions/clientActions';

const validationSchema = yup.object().shape({
  origin_location: yup.number(),
  destination_location: yup.number(),
  loading_date: yup.date(),
  // loading_time: yup.string().matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Loading time must be in HH:MM format'),
  unloading_date: yup.date(),
  // unloading_time: yup.string().matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Unloading time must be in HH:MM format'),
  unit_type: yup.number(),
  client: yup.number().required('Cliente es requerido.'),
  // channel: yup.number().required('El canal es requerido.'),
  shipment_number: yup.string().optional(),
  folio_number: yup.string().optional(),
});
const validationSchemaAvailabilities = yup.object().shape({
  origin_location: yup.number(),
  destination_location: yup.number(),
  // offered_by: yup.number().required('Solicitado por es requerido'),
  loading_date: yup.date(),
  unit_type: yup.number(),
  client: yup.number().required('Cliente es requerido.'),
  // channel: yup.number().required('El canal es requerido.'),
  shipment_number: yup.string().optional(),
  folio_number: yup.string().optional(),
});

const CreateTripForm = ({ onSubmit, isAvailability = false }) => {
  const dispatch = useDispatch();
  const { municipalities } = useSelector((state) => state.municipalityDropdownList);
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);
  const { clients } = useSelector((state) => state.clientDropdownList);
  const { transportation } = useSelector((state) => state.transportationClientDropdownList);
  const clientsTransform = clients.map((client) => {
    return {
      id: client.id,
      verbose_name: client.name,
    };
  });
  const transportationTransform = transportation.map((trans) => {
    return {
      id: trans.id,
      verbose_name: trans.name,
    };
  });
  const formValues = {
    origin_location: '',
    destination_location: '',
    loading_date: '',
    loading_time: '',
    unloading_date: '',
    unloading_time: '',
    unit_type: '',
    client: '',
    shipment_number: '',
    folio_number: '',
  };
  
  
  const [contactsTransform, setContactsTransform] = useState([]);
  const [contactsOffered, setContactsOffered] = useState([]);
  const { client: contacts } = useSelector((state) => state.contactsClients);
  const { contactsList } = useSelector((state) => state.contactsList);
  //channel
  useEffect(() => {
    if (contacts?.length > 0) {
      const transformedContacts = contacts.map((client) => {
        return {
          id: client.id,
          verbose_name: client?.channel?.name,
        };
      });
      setContactsTransform(transformedContacts);
    }

    return () => {
      setContactsTransform([]);
    };
  }, [contacts]);
//isAvailability ? 'offered_by' : 'requested_by'
  useEffect(() => {
    if (contactsList?.length > 0) {
      const addContacts = contactsList.map((client) => {
        return {
          id: client.id,
          verbose_name: client?.whatsapp_name || client?.phone_number,
        };
      });
      setContactsOffered(addContacts);
    }

    return () => {
      setContactsOffered([]);
    };
  }, [contactsList]);


  return (
    <Formik
      initialValues={formValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={isAvailability ? validationSchemaAvailabilities : validationSchema}
    >
      <Form>
        <Field name="client">
          {({ field, form, meta }) => {
            const handleChange = (selectedOption) => {
              dispatch(listAsignmentsDetails(selectedOption.value));
              dispatch(listContacts(selectedOption.value));
              form.setFieldValue(field.name, selectedOption.value);
            };
            return (
              <InputSelectFormik
                options={isAvailability ? transportationTransform : clientsTransform}
                field={field}
                meta={meta}
                form={form}
                label={'Cliente*'}
                placeholder="Cliente"
                onChange={handleChange}
              />
            );
          }}
        </Field>

        <Field name="origin_location">
          {({ field, form, meta }) => {
            return (
              <InputSelectFormik
                options={municipalities}
                field={field}
                meta={meta}
                form={form}
                label={'Origen'}
                placeholder="Origen"
              />
            );
          }}
        </Field>
        <Field name="destination_location">
          {({ field, form, meta }) => {
            return (
              <InputSelectFormik
                options={municipalities}
                field={field}
                meta={meta}
                form={form}
                label={'Destino'}
                placeholder="Destino"
              />
            );
          }}
        </Field>

        <Field name="unit_type">
          {({ field, form, meta }) => {
            return (
              <InputSelectFormik
                options={unitTypes}
                field={field}
                meta={meta}
                form={form}
                label={'Unidad'}
                placeholder="Unidad"
              />
            );
          }}
        </Field>

        {contactsTransform?.length > 0 && (
          <Field name="channel">
            {({ field, form, meta }) => {
              return (
                <InputSelectFormik
                  options={contactsTransform}
                  field={field}
                  meta={meta}
                  form={form}
                  label={'Canal*'}
                  placeholder="Canal"
                />
              );
            }}
          </Field>
        )}
        {contactsOffered?.length > 0 && (
          <Field name={isAvailability ? 'offered_by' : 'requested_by'}>
            {({ field, form, meta }) => {
              return (
                <InputSelectFormik
                  options={contactsOffered}
                  field={field}
                  meta={meta}
                  form={form}
                  label={isAvailability ? 'Solicitado por*' : 'Requerido por*'}
                  placeholder="Solicitado por"
                />
              );
            }}
          </Field>
        )}
        <Field name="message">
          {({ field, form, meta }) => {
            return (
              <InputTextFormik
                field={field}
                meta={meta}
                form={form}
                label={'Mensaje'}
                placeholder="mensaje identificador del viaje"
              />
            );
          }}
        </Field>
        <Field name="shipment_number">
          {({ field, form, meta }) => {
            return (
              <InputTextFormik
                field={field}
                meta={meta}
                form={form}
                label={'Número de viaje'}
                placeholder="Número de viaje"
              />
            );
          }}
        </Field>
        <Field name="folio_number">
          {({ field, form, meta }) => {
            return (
              <InputTextFormik
                field={field}
                meta={meta}
                form={form}
                label={'Número de folio'}
                placeholder="Número de folio"
              />
            );
          }}
        </Field>
        {isAvailability ? (
          <>
            <Flex my={2}>
              <Field name="start_date">
                {({ field, meta, helpers }) => {
                  return (
                    <DatePickerField
                      label="Fecha disponible*"
                      placeholder="Fecha disponible"
                      {...field}
                      {...meta}
                      {...helpers}
                    />
                  );
                }}
              </Field>
              <Field name="start_time">
                {({ field, helpers, meta }) => {
                  return (
                    <DatePickerField
                      label="Hora disponible*"
                      placeholder="Hora disponible"
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      name="loading_time"
                      {...field}
                      {...meta}
                      {...helpers}
                      value={field.value}
                      onChange={(date) => {
                        helpers.setValue(date);
                      }}
                      cy="loading-time"
                    />
                  );
                }}
              </Field>
            </Flex>
          </>
        ) : (
          <>
            <Flex my={2}>
              <Field name="loading_date">
                {({ field, meta, helpers }) => {
                  return (
                    <DatePickerField
                      label="Fecha de carga*"
                      placeholder="Fecha de carga"
                      {...field}
                      {...meta}
                      {...helpers}
                    />
                  );
                }}
              </Field>
              <Field name="loading_time">
                {({ field, helpers, meta }) => {
                  return (
                    <DatePickerField
                      label="Hora de carga*"
                      placeholder="Hora de carga"
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      name="loading_time"
                      {...field}
                      {...meta}
                      {...helpers}
                      value={field.value}
                      onChange={(date) => {
                        helpers.setValue(date);
                      }}
                      cy="loading-time"
                    />
                  );
                }}
              </Field>
            </Flex>
            <Flex my={2} w="100%">
              <Field name="unloading_date">
                {({ field, meta, helpers }) => {
                  return (
                    <DatePickerField
                      {...field}
                      {...meta}
                      {...helpers}
                      label="Fecha de descarga*"
                      placeholder="Fecha de descarga"
                    />
                  );
                }}
              </Field>
              <Field name="unloading_time">
                {({ field, helpers, meta }) => {
                  return (
                    <DatePickerField
                      label="Hora de carga*"
                      placeholder="Hora de carga"
                      showTimeSelect={true}
                      showTimeSelectOnly={true}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      name="loading_time"
                      {...field}
                      {...meta}
                      {...helpers}
                      value={field.value}
                      onChange={(date) => {
                        helpers.setValue(date);
                      }}
                      cy="loading-time"
                    />
                  );
                }}
              </Field>
            </Flex>
          </>
        )}
        <Flex justifyContent={'flex-end'}>
          <Buttom type="submit" variant="solid" color="blue.500" isLoading={false} text="Crear viaje" width="100%" />
        </Flex>
      </Form>
    </Formik>
  );
};

export default CreateTripForm;
