import React from 'react';

import { Text } from '@chakra-ui/react';

import CreateTripForm from '../forms/createTripForm';
import ModalContainer from '../ModalContainer';
import { cleanContactsDetails, cleanListContacts } from '../../../actions/clientActions';
import { useDispatch } from 'react-redux';
const customStyles = {
  backgroundColor: 'white',
  color: 'darkblue',
  // Add more custom styles as needed
};
const CreateTripModal = ({ isOpen, onClose, onSubmit, isAvailability = false }) => {
  const dispatch = useDispatch();
  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        dispatch(cleanContactsDetails());
        dispatch(cleanListContacts());
      }}
      size="xl"
      maxWidth="500px"
      headerContent={
        <Text color={'gray.800'} mb={0} fontWeight="bold" fontSize="2xl">
          Crear viaje
        </Text>
      }
      bodyContent={<CreateTripForm onSubmit={onSubmit} isAvailability={isAvailability} />}
    />
  );
};

export default CreateTripModal;
