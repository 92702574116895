import React from 'react';

import { Button, Tooltip } from '@chakra-ui/react';

const Buttom = ({
  size,
  color,
  text,
  variant = 'solid',
  leftIcon,
  rightIcon,
  isLoading = false,
  rounded = 'md',
  mb,
  mt,
  width,
  textColor = 'white',
  onClick,
  py,
  px,
  isDisabled = false,
  tooltipLabel = null,
  isActionTable,
  cy = '',
  type = 'button',
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Button
      type={type}
      colorScheme={color}
      size={size}
      variant={variant}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      isLoading={isLoading}
      rounded={rounded}
      backgroundColor={variant !== 'outline' ? color : 'transparent'}
      mb={mb ? mb : 4}
      mt={mt ? mt : 4}
      width={width || 'auto'}
      borderColor={variant === 'outline' ? color : null}
      color={variant === 'outline' ? color : textColor}
      onClick={onClick}
      py={py}
      isDisabled={isDisabled}
      px={px}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cy={cy}
      _hover={
        isActionTable && {
          backgroundColor: isHovered ? textColor : 'transparent',
          color: isHovered ? 'white' : color,
        }
      }
    >
      {tooltipLabel ? (
        <Tooltip hasArrow label={tooltipLabel}>
          {text}
        </Tooltip>
      ) : (
        text
      )}
    </Button>
  );
};

export default Buttom;
