const formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

export const numberToCurrency = (value) => formatter.format(value);

export const formatMoney = (value = 0, hasCurrency = true, currency = '') =>
  `$${Number(value)
    .toFixed(0)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${hasCurrency ? ' ' + currency : ''}`;
