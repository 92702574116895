import React from 'react';
// import DownloadCSVButton from "../DownloadCSVButton";
import { FiFilter, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { CheckIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Checkbox, Flex, Heading, Select, Button, useToast } from '@chakra-ui/react';

import Buttom from '../utils/buttons/Buttom';
import SelectFilterGrid from './utils/TripsFilters';
// import UploadButton from "../utils/buttons/UploadButton";

// import DownloadCSVButton from '../DownloadCSVButton';

const TableActionGloblal = ({
  data,
  withMaxScreen,
  // handleDownloadCSV,
  selectedStatus,
  setSelectedStatus,
  filterByStatusTrips,
  handleSelectChange,
  clients,
  unitTypes,
  municipalities,
  filters,
  selectAll,
  checkedItems,
  handleSelectAll,
  loadingDeletedBulk,
  loadingAprovedBulk,
  setShowBulkModal,
  aprovedBulk,
  setSelectAll,
  setLastChecked,
  setCheckedItems,
  hasSupplierCoordination,
  forwardBulk,
  // handleFileInputChange,
  // isLoadingUploaded,
  // fileName,
  handleUploadModal,
  hasClientCoordination,
  handleCreateTrip,
  handleSearchLocation,
  selectedOptions,
}) => {
  const [isOpenFilters, setIsOpenFilters] = React.useState(false);

  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  console.log("DATA:"+data);

  const toast = useToast();
  const copyDataToClipboard = () => {
    console.log(data);
    const csvData = convertToCSV(data);
    copyToClipboard(csvData);

    toast({
      title: "Success",
      description: "Data has been copied to clipboard successfully!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const convertToCSV = (data) => {
    const headers = ['Cliente', 'Fecha de oferta', 'Unidad', 'Origen', 'Destino', 'Fecha Disponible', 'N. viaje'].join("\t");
    const indexes = ['client_name', 'requested_at', 'unit_type', 'origin', 'destination', 'loading_date', 'shipment_number'];

    let rows = [];

    for (let i = 0; i < data.length; i++) {
      let row = [];
      for (let j = 0; j < indexes.length; j++) {
        if (data[i][indexes[j]] === undefined || data[i][indexes[j]] === null) {
          row.push('');
          continue;
        }
        let value = data[i][indexes[j]];
        if (indexes[j] === 'start_date') {
          value = data[i]["start_date"];
          if (data[i]['start_time'] !== undefined && data[i]['start_time'] !== null) {
            value += ' ' + data[i]['start_time'];
          }
        }
        row.push('"'+value+'"');
      }
      rows.push(row.join("\t"));
    }

  return `${headers}\n${rows.join("\n")}`;
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Copied to clipboard successfully!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <Box mt={{ base: 3, xl: 5 }} pt={2}>
      <Box
        display={{ base: 'flex' }}
        justifyContent={{ base: 'space-between' }}
        alignItems={'center'}
        m={0}
        p={0}
        maxWidth={{ base: 'auto', xl: withMaxScreen }}
      >
        <Flex alignItems={'center'} gap={2}>
          <Box
            rounded={'md'}
            border={'1px solid'}
            borderColor="gray.300"
            p={0}
            bg="white"
            alignItems={'center'}
            justifyContent={'space-between'}
            h={'34px'}
            maxWidth={withMaxScreen}
            display={{
              base: 'none',
              xl: selectAll || checkedItems.length > 0 ? 'flex' : 'none',
            }}
          >
            <Flex>
              <Checkbox
                isChecked={selectAll}
                onChange={handleSelectAll}
                borderColor="gray.600"
                mb={0}
                p={2}
                fontWeight={'normal'}
                display={{ base: 'flex', xl: 'none' }}
              >
                Seleccionar todo
              </Checkbox>
              {(selectAll || checkedItems.length > 0) && (
                <Flex gap={2}>
                  {!hasClientCoordination && (
                    <Buttom
                      size="sm"
                      p={1}
                      text={<ExternalLinkIcon fontSize={16} />}
                      isLoading={loadingDeletedBulk}
                      isDisabled={loadingAprovedBulk}
                      onClick={forwardBulk}
                      textColor="blue.700"
                      variant="ghost"
                      tooltipLabel="Reenviar"
                    />
                  )}
                  <Buttom
                    size="sm"
                    p={1}
                    text={
                      <div>
                        <FiTrash2
                          style={{
                            fontSize: '17px',
                          }}
                        />
                      </div>
                    }
                    isLoading={loadingDeletedBulk}
                    isDisabled={loadingAprovedBulk}
                    onClick={() => setShowBulkModal(true)}
                    textColor="blue.700"
                    variant="ghost"
                    tooltipLabel="Eliminar"
                  />
                  <Buttom
                    onClick={aprovedBulk}
                    tooltipLabel="Aprobar"
                    p={1}
                    variant="ghost"
                    size="sm"
                    isLoading={loadingAprovedBulk}
                    isDisabled={loadingDeletedBulk}
                    text={<CheckIcon />}
                    borderRadius="xl"
                    textColor="blue.700"
                  />
                </Flex>
              )}
            </Flex>
            {(selectAll || checkedItems.length !== 0) && (
              <Buttom
                onClick={() => {
                  setSelectAll(false);
                  setLastChecked(null);
                  setCheckedItems([]);
                }}
                tooltipLabel="Limpiar selección"
                p={1}
                variant="ghost"
                size="sm"
                text={
                  <div>
                    <CloseIcon
                      style={{
                        fontSize: '14px',
                      }}
                      color="red"
                    />
                  </div>
                }
                borderRadius="xl"
                textColor="blue.700"
              />
            )}
          </Box>
          <Heading
            as="h1"
            size={{ base: 'sm', md: 'md' }}
            pt={2}
            pl={2}
            color="gray.700"
            display={{ base: 'block', xl: 'hidden' }}
          >
            Solicitudes de Viaje
          </Heading>
        </Flex>
        <Flex gap={2} justifyContent={{ base: 'flex-end' }} pt={1}>
          <Select
            value={selectedStatus}
            bottom={0}
            borderColor="gray.400"
            backgroundColor="white"
            onChange={(e) => setSelectedStatus(() => e.target.value)}
            width={'30%'}
            display={{ base: 'flex' }}
            size={'sm'}
            color={'gray.600'}
            borderRadius={'md'}
            icon={<FiFilter style={{ fontSize: 6 }} />}
            cy="select-statuses-button"
          >
            {filterByStatusTrips.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </Select>

          <Box display={{ base: 'flex' }} justifyContent={'flex-end'}>
          <Button
              type="button"
              mb={3}
              onClick={(e) => {
                e.preventDefault();
                setIsOpenFilters(!isOpenFilters);
              }}
              leftIcon={<FiFilter />}
              variant={'solid'}
              background={'blue.400'}
              color={'white'}
              size="sm"
              cy="filter-button"
            >
              {isOpenFilters ? 'Cerrar filtros' : ' Ver filtros'}
            </Button>
          </Box>
          {hasSupplierCoordination && (
            <Box p={0} m={0} mb={3}>
              <Button mb={3} onClick={handleUploadModal} variant={'outline'} colorScheme="blue" size="sm">
                Seleccionar archivo
              </Button>
            </Box>
          )}
          {userDetails?.is_staff && (
            <Box p={0} m={0} mb={3}>
              <Button mb={3} onClick={handleCreateTrip} variant={'solid'} colorScheme="orange" size="sm">
                Crear solicitud
              </Button>
            </Box>
          )}
          <Box p={0} m={0} mb={3}>
          <Button mb={3} onClick={copyDataToClipboard} variant={'solid'} colorScheme="orange" size="sm">    
                Copiar datos
          </Button>
          </Box>
        </Flex>
      </Box>

      {isOpenFilters && (
        <SelectFilterGrid
          handleSelectChange={handleSelectChange}
          filters={filters}
          withMaxScreen={withMaxScreen}
          handleSearchLocation={handleSearchLocation}
          selectedOptions={selectedOptions}
        />
      )}

      <Box
        rounded={'md'}
        border={'1px solid'}
        borderColor="gray.300"
        p={0}
        bg="white"
        alignItems={'center'}
        justifyContent={'space-between'}
        h={'40px'}
        maxWidth={withMaxScreen}
        mb={2}
        display={{
          base: 'flex',
          xl: 'none',
        }}
      >
        <Flex>
          <Checkbox
            isChecked={selectAll}
            onChange={handleSelectAll}
            borderColor="gray.600"
            mb={0}
            p={2}
            fontWeight={'normal'}
            display={{ base: 'flex', xl: 'none' }}
            cy="select-all-checkbox"
          >
            Seleccionar todo
          </Checkbox>
          {(selectAll || checkedItems.length > 0) && (
            <Flex gap={2}>
              <Buttom
                size="sm"
                p={1}
                text={
                  <div>
                    <FiTrash2
                      style={{
                        fontSize: '17px',
                      }}
                    />
                  </div>
                }
                isLoading={loadingDeletedBulk}
                isDisabled={loadingAprovedBulk}
                onClick={() => setShowBulkModal(true)}
                textColor="blue.700"
                variant="ghost"
                tooltipLabel="Eliminar"
              />
              {!hasClientCoordination && (
                <Buttom
                  size="sm"
                  p={1}
                  text={<ExternalLinkIcon fontSize={16} />}
                  isLoading={loadingDeletedBulk}
                  isDisabled={loadingAprovedBulk}
                  onClick={forwardBulk}
                  textColor="blue.700"
                  variant="ghost"
                  tooltipLabel="Reenviar"
                />
              )}
              <Buttom
                onClick={aprovedBulk}
                tooltipLabel="Aprobar"
                p={1}
                variant="ghost"
                size="sm"
                isLoading={loadingAprovedBulk}
                isDisabled={loadingDeletedBulk}
                text={<CheckIcon />}
                borderRadius="xl"
                textColor="blue.700"
              />
            </Flex>
          )}
        </Flex>
        {(selectAll || checkedItems.length !== 0) && (
          <Buttom
            onClick={() => {
              setSelectAll(false);
              setLastChecked(null);
              setCheckedItems([]);
            }}
            tooltipLabel="Limpiar selección"
            p={1}
            variant="ghost"
            size="sm"
            text={
              <div>
                <CloseIcon
                  style={{
                    fontSize: '14px',
                  }}
                  color="red"
                  cy="clear-selection-button"
                />
              </div>
            }
            borderRadius="xl"
            textColor="blue.700"
          />
        )}
      </Box>
    </Box>
  );
};

export default TableActionGloblal;
