import React from 'react';

import { Image, Text, Tooltip } from '@chakra-ui/react';

const MessageToolTip = ({ row, openChatModal }) => {
  const item = row?.original || row;
  return (
    <Tooltip
      label={item?.main_msg?.[0]?.body}
      hasArrow
      bg="white"
      color="black"
      arrowSize={15}
      fontSize={13}
      fontWeight="medium"
      placement="bottom"
      shadow="xl"
      border="1px solid gray.400"
      borderRadius="md"
      p={4}
      height="auto"
    >
      <div>
        {item?.main_msg?.[0]?.body && (
          <Text
            variant="filled"
            fontWeight="medium"
            fontSize={12}
            textDecoration={'none'}
            border={'none'}
            isTruncated
            maxW={300}
            height="100%"
            cursor={'pointer'}
            onClick={() => openChatModal(item?.id)}
            mb={0}
          >
            {item?.main_msg?.[0]?.body}
          </Text>
        )}

        {item?.main_msg?.[0]?.media && !item?.main_msg?.[0]?.body && (
          <Image src={item?.main_msg?.[0]?.media} alt="Image" maxH="30px" maxW={'30px'} mt={2} cursor="pointer" />
        )}
      </div>
    </Tooltip>
  );
};

export default MessageToolTip;
