import toast from 'react-hot-toast';

import {
  BID_REQUEST_DETAIL_FAIL,
  BID_REQUEST_DETAIL_REQUEST,
  BID_REQUEST_DETAIL_SUCCESS,
  BID_REQUEST_FAIL,
  BID_REQUEST_REQUEST,
  BID_REQUEST_SUCCESS,
} from '../constants/bidRequestConstants';
import unprotectedHttpClient from './utils/unprotetedAxiosInstance';

export const getBidRequests = (token, refresh) => async (dispatch) => {
  try {
    if (!refresh) {
      dispatch({
        type: BID_REQUEST_REQUEST,
      });
    }
    const response = await unprotectedHttpClient.get(`/api/v1/bid-request/group/all/${token}/?limit=10&offset=0`);
    dispatch({
      type: BID_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: BID_REQUEST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const acceptedBidRequests = (id, params) => async (dispatch) => {
  try {
    dispatch({
      type: BID_REQUEST_DETAIL_REQUEST,
    });
    const response = await unprotectedHttpClient.patch(`/api/v1/bid-requests/${id}/accept/`, { ...params });

    dispatch({
      type: BID_REQUEST_DETAIL_SUCCESS,
      payload: response.data,
    });
    toast.success('Viaje aceptado 🎉');
  } catch (error) {
    dispatch({
      type: BID_REQUEST_DETAIL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
