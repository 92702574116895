export const BID_REQUEST_REQUEST = 'BID_REQUEST_REQUEST';
export const BID_REQUEST_SUCCESS = 'BID_REQUEST_SUCCESS';
export const BID_REQUEST_FAIL = 'BID_REQUEST_FAIL';

export const BID_REQUEST_DETAIL_REQUEST = 'BID_REQUEST_DETAIL_REQUEST';
export const BID_REQUEST_DETAIL_SUCCESS = 'BID_REQUEST_DETAIL_SUCCESS';
export const BID_REQUEST_DETAIL_FAIL = 'BID_REQUEST_DETAIL_FAIL';

export const BID_REQUEST_REQUEST_SET = 'BID_REQUEST_REQUEST_SET';
export const BID_REQUEST_SUCCESS_SET = 'BID_REQUEST_SUCCESS_SET';
export const BID_REQUEST_FAIL_SET = 'BID_REQUEST_FAIL_SET';

export const BID_REQUEST_REQUEST_STATUS = 'BID_REQUEST_REQUEST_STATUS';
export const BID_REQUEST_SUCCESS_STATUS = 'BID_REQUEST_SUCCESS_STATUS';
export const BID_REQUEST_FAIL_STATUS = 'BID_REQUEST_FAIL_STATUS';

export const TRIP_REQUEST_LIST_REFRESH_REQUEST = 'TRIP_REQUEST_LIST_REFRESH_REQUEST';
