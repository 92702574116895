import React from 'react';
import { Formik, Field, Form } from 'formik'; // Import Formik components
import InputDate from '../InputDate';
import { Box, Flex, Grid } from '@chakra-ui/react';
import DatePickerField from '../DatePickerField';
import InputDateHour from '../utils/inputs/InputDateHour';
import InputSelectFormik from '../utils/inputs/InputSelectFormik';

const FiltersChats = ({ handleSelectChange, handleSearchLocation, selectedOptions }) => {
  const formValues = {
    media: selectedOptions?.media || '',
    unloading_time: '',
  };
  const handleCleaning = (name) => {
    if (name === 'media') {
      handleSearchLocation({ media: '' });
    }
  };
  const getTemplateColumns = () => {
    if (selectedOptions?.date) {
      return { base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' };
    } else {
      return { base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' };
    }
  };
  return (
    <Flex w="100%" justifyContent={'flex-end'} m={0} mt={2}>
      <Formik initialValues={formValues} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form m={0} p={0} w="100%">
            <Grid gap={5} w={900} h={38} templateColumns={getTemplateColumns()}>
              <InputDate
                columnId={'date'}
                options={[]}
                onSelectChange={handleSelectChange}
                emptyText={'Fecha'}
                defaultValue={selectedOptions?.date}
              />
              {selectedOptions?.date && (
                <>
                  <InputDateHour
                    columnId={'time_start'}
                    options={[]}
                    onSelectChange={handleSelectChange}
                    emptyText={'Hora inicio'}
                    defaultValue={selectedOptions?.time_start}
                  />
                  <InputDateHour
                    columnId={'time_end'}
                    options={[]}
                    onSelectChange={handleSelectChange}
                    emptyText={'Hora final'}
                    defaultValue={selectedOptions?.time_end}
                  />
                </>
              )}
              <Field name={'media'}>
                {({ field, form, meta }) => {
                  return (
                    <InputSelectFormik
                      options={[
                        { id: true, verbose_name: 'Sí' },
                        { id: false, verbose_name: 'No' },
                      ]}
                      field={field}
                      meta={meta}
                      form={form}
                      placeholder="Ver media"
                      onSelectChange={handleSelectChange}
                      isCleaning={selectedOptions?.media !== ''}
                      handleCleaning={handleCleaning}
                      onChange={(option) => {
                        setFieldValue('media', option.value);
                        handleSearchLocation({ media: option.value });
                      }}
                    />
                  );
                }}
              </Field>
            </Grid>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default FiltersChats;
