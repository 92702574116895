import {
  TRIP_AVAILABILITY_DELETE_FAIL,
  TRIP_AVAILABILITY_DELETE_REQUEST,
  TRIP_AVAILABILITY_DELETE_SUCCESS,
  TRIP_AVAILABILITY_DETAILS_FAIL,
  TRIP_AVAILABILITY_DETAILS_REQUEST,
  TRIP_AVAILABILITY_DETAILS_SUCCESS,
  TRIP_AVAILABILITY_LIST_FAIL,
  TRIP_AVAILABILITY_LIST_REQUEST,
  TRIP_AVAILABILITY_LIST_SUCCESS,
  TRIP_AVAILABILITY_UPDATE_FAIL,
  TRIP_AVAILABILITY_UPDATE_REQUEST,
  TRIP_AVAILABILITY_UPDATE_SUCCESS,
} from '../constants/availabilityConstants';

export const availabilityListReducer = (state = { availabilities: [] }, action) => {
  switch (action.type) {
    case TRIP_AVAILABILITY_LIST_REQUEST:
      return { loading: true, availabilities: [], filters: {}, error: null, refreshing: false };
    // case TRIP_AVAILABILITY_LIST_REFRESH:
    //   return { loading: false, refreshing: true, availabilities: [], filters: {}, error: null };

    case TRIP_AVAILABILITY_LIST_SUCCESS:
      return {
        loading: false,
        availabilities: action.payload.results,
        pagination: action.payload.pagination,
        filters: action.payload.filters,
        error: null,
        efreshing: false,
      };

    case TRIP_AVAILABILITY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        filters: {},
        refreshing: true,
      };

    default:
      return state;
  }
};

export const availabilityDetailReducer = (state = { availability: {} }, action) => {
  switch (action.type) {
    case TRIP_AVAILABILITY_DETAILS_REQUEST:
      return { loading: true, availability: {} };

    case TRIP_AVAILABILITY_DETAILS_SUCCESS:
      return { loading: false, availability: action.payload };

    case TRIP_AVAILABILITY_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const availabilityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRIP_AVAILABILITY_DELETE_REQUEST:
      return { loading: true };

    case TRIP_AVAILABILITY_DELETE_SUCCESS:
      return { loading: false, success: true };

    case TRIP_AVAILABILITY_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const availabilityUpdateReducer = (state = { availability: {} }, action) => {
  switch (action.type) {
    case TRIP_AVAILABILITY_UPDATE_REQUEST:
      return { loading: true, ...state };

    case TRIP_AVAILABILITY_UPDATE_SUCCESS:
      return { loading: false, availability: action.payload };

    case TRIP_AVAILABILITY_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
