export const filterStatusesByColor = (text) => {
  switch (text) {
    case 'is_approved':
      return 'blue.500';
    case 'is_approved_by_traffic':
      return 'purple.500';
    case 'is_complete':
      return 'orange.500';
    case 'suppliers_have_been_contacted':
      return 'green.500';
    case 'assigned_transporter':
      return 'pink.500';
    case 'is_rejected':
      return 'red';
    case 'available':
      return '#9AD56B';
    case 'taken_by_us':
      return 'blue.500';
    case 'unavailable':
      return '#F55252';
    case 'taken_by_others':
      return 'gray.300';

    default:
      return 'yellow.500';
  }
};

export const filterStatusesByColorOpacity = (text) => {
  switch (text) {
    case 'is_approved':
      return 'blue.200';
    case 'is_approved_by_traffic':
      return 'purple.200';
    case 'is_complete':
      return 'orange.200';
    case 'suppliers_have_been_contacted':
      return 'green.200';
    case 'assigned_transporter':
      return 'pink.200';
    case 'is_rejected':
      return 'pink.300';

    default:
      return 'yellow.200';
  }
};

export const filterByStatus = (status) => {
  switch (status) {
    case 'is_approved':
      return 'Aprobado por MC';
    case 'is_approved_by_traffic':
      return 'Aprobado por tráfico';
    case 'suppliers_have_been_contacted':
      return 'Reenviado a carrier';
    case 'is_complete':
      return 'Enviado a TMS';
    case 'assigned_transporter':
      return 'Carrier asignado';
    case 'is_rejected':
      return 'Sin colocar/asignar';
    case 'available':
      return 'Disponible';
    case 'unavailable':
      return 'No disponible';
    case 'taken_by_us':
      return 'Tomado in...';
    case 'taken_by_others':
      return 'Tomado por...';
    case 'available_label':
      return 'Disponible';
    case 'unavailable_label':
      return 'No disponible';
    case 'taken_by_us_label':
      return 'Tomado internamente';
    case 'taken_by_others_label':
      return 'Tomado por otra compañía';
    default:
      return 'Sin atender';
  }
};

export const statusesSteps = [
  { value: 'is_approved', label: 'Aprobado por mesa de control' },
  { value: 'suppliers_have_been_contacted', label: 'Reenviado a carrier' },
  { value: 'is_approved_by_traffic', label: 'Aprobado por tráfico' },
  { value: 'assigned_transporter', label: 'Carrier asignado' },
  { value: 'is_complete', label: 'Enivado a TMS' },
  { value: 'is_rejected', label: 'Sin colocar/asignar' },
];

export const StatusesStepsLabels = {
  '1 is_approved': 'is_approved',
  '2 suppliers_have_been_contacted': 'suppliers_have_been_contacted',
  '3 is_approved_by_traffic': 'is_approved_by_traffic',
  '4 assigned_transporter': 'assigned_transporter',
  '5 is_complete': 'is_complete',
  '6 is_rejected': 'is_rejected',
};
