export const TRIP_REQUEST_LIST_REQUEST = 'TRIP_REQUEST_LIST_REQUEST';
export const TRIP_REQUEST_LIST_REFRESH_REQUEST = 'TRIP_REQUEST_LIST_REFRESH_REQUEST';
export const TRIP_REQUEST_LIST_SUCCESS = 'TRIP_REQUEST_LIST_SUCCESS';
export const TRIP_REQUEST_LIST_FAIL = 'TRIP_REQUEST_LIST_FAIL';

export const TRIP_REQUEST_DETAILS_REQUEST = 'TRIP_REQUEST_DETAILS_REQUEST';
export const TRIP_REQUEST_DETAILS_SUCCESS = 'TRIP_REQUEST_DETAILS_SUCCESS';
export const TRIP_REQUEST_DETAILS_FAIL = 'TRIP_REQUEST_DETAILS_FAIL';

export const TRIP_REQUEST_MESSAGES_REQUEST = 'TRIP_REQUEST_MESSAGES_REQUEST';
export const TRIP_REQUEST_MESSAGES_SUCCESS = 'TRIP_REQUEST_MESSAGES_SUCCESS';
export const TRIP_REQUEST_MESSAGES_FAIL = 'TRIP_REQUEST_MESSAGES_FAIL';
export const TRIP_REQUEST_MESSAGES_CLEAN = 'TRIP_REQUEST_MESSAGES_CLEAN';
export const TRIP_REQUEST_CREATE_REQUEST = 'TRIP_REQUEST_CREATE_REQUEST';
export const TRIP_REQUEST_CREATE_SUCCESS = 'TRIP_REQUEST_CREATE_SUCCESS';
export const TRIP_REQUEST_CREATE_FAIL = 'TRIP_REQUEST_CREATE_FAIL';
export const TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN = 'TRIP_REQUEST_SUPPLIER_MESSAGES_CLEAN';
export const TRIP_REQUEST_UPDATE_REQUEST = 'TRIP_REQUEST_UPDATE_REQUEST';
export const TRIP_REQUEST_UPDATE_SUCCESS = 'TRIP_REQUEST_UPDATE_SUCCESS';
export const TRIP_REQUEST_UPDATE_FAIL = 'TRIP_REQUEST_UPDATE_FAIL';

export const TRIP_REQUEST_APPROVE_REQUEST = 'TRIP_REQUEST_APPROVE_REQUEST';
export const TRIP_REQUEST_APPROVE_SUCCESS = 'TRIP_REQUEST_APPROVE_SUCCESS';
export const TRIP_REQUEST_APPROVE_FAIL = 'TRIP_REQUEST_APPROVE_FAIL';

export const TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST = 'TRIP_REQUEST_TRAFFIC_APPROVE_REQUEST';
export const TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS = 'TRIP_REQUEST_TRAFFIC_APPROVE_SUCCESS';
export const TRIP_REQUEST_TRAFFIC_APPROVE_FAIL = 'TRIP_REQUEST_TRAFFIC_APPROVE_FAIL';

export const TRIP_REQUEST_ASSIGN_REQUEST = 'TRIP_REQUEST_ASSIGN_REQUEST';
export const TRIP_REQUEST_ASSIGN_SUCCESS = 'TRIP_REQUEST_ASSIGN_SUCCESS';
export const TRIP_REQUEST_ASSIGN_FAIL = 'TRIP_REQUEST_ASSIGN_FAIL';

export const TRIP_REQUEST_COMPLETE_REQUEST = 'TRIP_REQUEST_COMPLETE_REQUEST';
export const TRIP_REQUEST_COMPLETE_SUCCESS = 'TRIP_REQUEST_COMPLETE_SUCCESS';
export const TRIP_REQUEST_COMPLETE_FAIL = 'TRIP_REQUEST_COMPLETE_FAIL';

export const TRIP_REQUEST_DELETE_REQUEST = 'TRIP_REQUEST_DELETE_REQUEST';
export const TRIP_REQUEST_DELETE_SUCCESS = 'TRIP_REQUEST_DELETE_SUCCESS';
export const TRIP_REQUEST_DELETE_FAIL = 'TRIP_REQUEST_DELETE_FAIL';

export const TRIP_REQUEST_RESPONSE_REQUEST = 'TRIP_REQUEST_RESPONSE_REQUEST';
export const TRIP_REQUEST_RESPONSE_SUCCESS = 'TRIP_REQUEST_RESPONSE_SUCCESS';
export const TRIP_REQUEST_RESPONSE_FAIL = 'TRIP_REQUEST_RESPONSE_FAIL';
export const RESET_TRIP_REQUEST_RESPONSE = 'RESET_TRIP_REQUEST_RESPONSE';

export const TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST = 'TRIP_REQUEST_SUPPLIER_MESSAGES_REQUEST';
export const TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS = 'TRIP_REQUEST_SUPPLIER_MESSAGES_SUCCESS';
export const TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL = 'TRIP_REQUEST_SUPPLIER_MESSAGES_FAIL';

export const TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST = 'TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_REQUEST';
export const TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS = 'TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_SUCCESS';
export const TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL = 'TRIP_REQUEST_SUPPLIER_MESSAGES_SEND_FAIL';

export const TRIP_REQUEST_SEND_COMMENT_REQUEST = 'TRIP_REQUEST_SEND_COMMENT_REQUEST';
export const TRIP_REQUEST_SEND_COMMENT_SUCCESS = 'TRIP_REQUEST_SEND_COMMENT_SUCCESS';
export const TRIP_REQUEST_SEND_COMMENT_FAIL = 'TRIP_REQUEST_SEND_COMMENT_FAIL';
