export const handleRecent = (created_at) => {
  const dateObj = new Date(created_at);
  const now = new Date();
  const diffInHours = Math.abs(now - dateObj) / 1000 / 60 / 60;
  return diffInHours < 48;
};

export const filterStatusesTabsByColor = (text) => {
  switch (text) {
    case 'recent':
      return 'pink.400';
    case 'unattended':
      return 'green.400';
    case 'approved_by_mc':
      return 'blue.500';
    case 'approve_by_traffic':
      return 'purple.500';
    case 'sent_to_tms':
      return 'orange.500';
    case 'expired':
      return 'red.400';
    case 'archived':
      return 'gray.500';
    default:
      return 'cyan.600';
  }
};
export const filterStatuseTraffic = [
  {
    label: 'Aprobados por MC',
    value: 'approved_by_mc',
  },
  {
    label: 'Aprobados por tráfico',
    value: 'approve_by_traffic',
  },
  {
    label: 'Enviados a TMS',
    value: 'sent_to_tms',
  },

  {
    label: 'Expirados',
    value: 'expired',
  },
  {
    label: 'Archivados',
    value: 'archived',
  },
];
export const filterByStatusTrips = [
  { label: 'Todos los viajes', value: '' },
  {
    label: 'Recientes',
    value: 'recent',
  },
  {
    label: 'Sin atender',
    value: 'unattended',
  },
  {
    label: 'Aprobados por MC',
    value: 'approved_by_mc',
  },
  {
    label: 'Aprobados por tráfico',
    value: 'approve_by_traffic',
  },
  {
    label: 'Enviados a TMS',
    value: 'sent_to_tms',
  },

  {
    label: 'Expirados',
    value: 'expired',
  },
  {
    label: 'Archivados',
    value: 'archived',
  },
];
export const statusesMap = {
  recent: {
    requested_hours_ago_recently: 48,
  },
  unattended: {
    requested_hours_ago_recently: 48,
    is_approved: false,
    has_been_sent_to_suppliers: false,
    is_approved_by_traffic: false,
    is_complete: false,
  },
  approved_by_mc: {
    requested_hours_ago_recently: 48,
    is_approved: true,
    is_approved_by_traffic: false,
    is_complete: false,
  },
  approve_by_traffic: {
    requested_hours_ago_recently: 48,
    is_approved_by_traffic: true,
    is_complete: false,
  },
  sent_to_tms: {
    requested_hours_ago_recently: 48,
    is_complete: true,
  },
  expired: {
    requested_hours_ago: 48,
    is_complete: false,
  },
  archived: {
    requested_hours_ago: 48,
    is_complete: true,
  },
};
