/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Formik, Field, Form, useFormik } from 'formik'; // Import Formik components
import moment from 'moment';

import {
  Flex,
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Checkbox,
  HStack,
  Spacer,
  Input,
} from '@chakra-ui/react';

import { listMunicipalitiesDropdown } from '../actions/municipalityActions'; // Replace with your actual actions
import {
  getTripRequestDetails,
  updateTripRequest,
  approveTripRequest,
  approveTripRequestByTraffic,
} from '../actions/tripRequestActions';
import { listUnitTypesDropdown } from '../actions/unitTypeActions';
import CreateMunicipalityModal from './CreateMunicipalityModal';
import CreateUnitTypeModal from './CreateUnitTypeModal';
import DatePickerField from './DatePickerField';
import Loader from './Loader';
import Message from './Message';
import { ContanerText } from './tripRequest/TripDetail';
import InputSelectFormik from './utils/inputs/InputSelectFormik';

function EditTripRequestDetails(props) {
  const { id, postUpdateCallback, setEditDetail, openModal } = props;
  // const { id, onClose } = props;
  const dispatch = useDispatch();
  const tripRequestDetails = useSelector((state) => state.tripRequestDetails);
  const { loading, error, tripRequest } = tripRequestDetails;
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;

  const [updateLoading, setUpdateLoading] = useState(false);

  const { municipalities } = useSelector((state) => state.municipalityDropdownList);
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);

  const [isCreateMunicipalityModalOpen, setCreateMunicipalityModalOpen] = useState(false);

  // const openCreateMunicipalityModal = () => {
  //   setCreateMunicipalityModalOpen(true);
  // };

  const closeCreateMunicipalityModal = () => {
    setCreateMunicipalityModalOpen(false);
  };

  const [isCreateUnitTypeModalOpen, setCreateUnitTypeModalOpen] = useState(false);

  // const openCreateUnitTypeModal = () => {
  //   setCreateUnitTypeModalOpen(true);
  // };

  const closeCreateUnitTypeModal = () => {
    setCreateUnitTypeModalOpen(false);
  };

  // if the user is a supplier coordinator, add the supplier column
  const hasSupplierCoordination = userDetails?.roles.some((role) => role.id_verbose === 'supplier_coordination');

  // if the user is a supplier coordinator, add the supplier column
  const hasClientCoordination = userDetails?.roles.some((role) => role.id_verbose === 'client_coordination');

  const initialValues = {
    client: tripRequest?.channel?.client?.name || '',
    origin: tripRequest?.origin || null,
    destination: tripRequest?.destination || null,
    unit_type: tripRequest?.unit_type || null,
    loading_date: tripRequest?.loading_date || '',
    unloading_date: tripRequest?.unloading_date || '',
    shipment_number: tripRequest?.shipment_number || '',
    folio_number: tripRequest?.folio_number || '',
    is_approved: tripRequest?.is_approved || false,
    is_approved_by_traffic: tripRequest?.is_approved_by_traffic || false,
  };

  const [formValues, setFormValues] = useState(initialValues);
  useEffect(() => {
    if (tripRequest && Object.keys(formValues).every((key) => !formValues[key])) {
      setFormValues({
        client: tripRequest?.channel?.client?.name || '',
        origin: tripRequest?.origin || null,
        destination: tripRequest?.destination || null,
        unit_type: tripRequest?.unit_type || null,
        loading_date: tripRequest?.loading_date || '',
        shipment_number: tripRequest?.shipment_number || '',
        folio_number: tripRequest?.folio_number || '',
        unloading_time: tripRequest?.unloading_time || '',
        is_approved: tripRequest?.is_approved || false,
        is_approved_by_traffic: tripRequest?.is_approved_by_traffic || false,
      });
    }
  }, [tripRequest]);

  const [isApprovedByTraffic, setIsApprovedByTraffic] = useState(!!tripRequest?.approved_by_traffic_at);
  const [isApprovedByMC, setIsApprovedByMC] = useState(!!tripRequest?.approved_at);

  useEffect(() => {
    if (!tripRequest) return;
    setIsApprovedByTraffic(!!tripRequest?.approved_by_traffic_at);
    setIsApprovedByMC(!!tripRequest?.approved_at);
  }, [tripRequest]);

  // Fetch filter options when the component mounts
  useEffect(() => {
    dispatch(listMunicipalitiesDropdown());
  }, [dispatch, isCreateMunicipalityModalOpen]);

  useEffect(() => {
    dispatch(listUnitTypesDropdown());
  }, [dispatch, isCreateUnitTypeModalOpen]);

  const onSubmit = async (values) => {
    const formattedValues = {
      ...values,
      loading_date:
        values.loading_date && moment(values.loading_date).isValid()
          ? moment(values.loading_date).utcOffset(-6, true).format('YYYY-MM-DD')
          : null,
      unloading_date:
        values.unloading_date && moment(values.unloading_date).isValid()
          ? moment(values.unloading_date).utcOffset(-6, true).format('YYYY-MM-DD')
          : null,
      unloading_time:
        values.unloading_time && moment(values.unloading_time).isValid()
          ? moment(values.unloading_time).utcOffset(-6, true).format('HH:mm')
          : null,
      loading_time:
        values.loading_time && moment(values.loading_time).isValid()
          ? moment(values.loading_time).utcOffset(-6, true).format('HH:mm')
          : null,
    };

    const filterFields = [
      'origin',
      'destination',
      'unit_type',
      'loading_date',
      'unloading_date',
      'loading_time',
      'unloading_time',
      'shipment_number',
      'folio_number',
      'is_attended',
      'is_approved',
    ]; // Replace with your actual list of fields

    const updatedValues = Object.keys(formattedValues).reduce((acc, key) => {
      if (filterFields.includes(key)) {
        const value = formattedValues[key];
        if (value !== null) {
          if (typeof value === 'object' && value !== null && 'id' in value) {
            acc[key] = value.id;
          } else {
            acc[key] = value;
          }
        }
      }
      return acc;
    }, {});
    try {
      // Dispatch the updateTripRequest action
      setUpdateLoading(true);
      if (hasClientCoordination) {
        await dispatch(approveTripRequest(id, isApprovedByMC));
      }

      if (hasSupplierCoordination) {
        await dispatch(approveTripRequestByTraffic(id, isApprovedByTraffic));
      }
      await dispatch(updateTripRequest(id, updatedValues));
      setEditDetail(false);
      dispatch(getTripRequestDetails(id));
    } catch (error) {
      console.error('Error updating trip request:', error);

      // Handle errors if needed
    } finally {
      setUpdateLoading(false);
      postUpdateCallback();
    }
  };

  const onToggleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'is_approved_by_traffic') {
      setIsApprovedByTraffic(checked);
    }
    if (name === 'is_approved') {
      setIsApprovedByMC(checked);
    }
  };

  const refreshDropdowns = () => {
    console.log('refreshDropdowns called');
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    console.log('Form values changed:', formik.values);
    console.log('Form dirty state:', formik.dirty);
    console.log('Form isSubmitting state:', formik.isSubmitting);
  }, [formik.values, formik.dirty, formik.isSubmitting]);

  if (loading || updateLoading) {
    return <Loader />;
  }
  return (
    <Container mt={4} width="100%">
      {error ? <Message variant="error">{error}</Message> : null}
      {!loading && !error && !tripRequest ? (
        <Message variant="info">No trip details available.</Message>
      ) : (
        <Formik initialValues={formValues} onSubmit={onSubmit} enableReinitialize>
          <Form style={{ margin: 0, padding: 0 }}>
            <ContanerText title="Cliente:" description={tripRequest?.channel?.client?.name} />
            <Flex
              mt={3}
              w="100%"
              alignItems={{ base: 'flex-start', md: 'center' }}
              gap={2}
              justifyContent={{ base: 'flex-start', md: 'space-between' }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <FormControl mb={4} color="gray.900" width={'auto'} zIndex={9999}>
                <Field
                  style={{ width: '100%' }}
                  name="loading_date"
                  render={({ field, meta, helpers }) => (
                    <DatePickerField label="Fecha de Carga" {...field} {...meta} {...helpers} cy="loading-date" />
                  )}
                />
              </FormControl>

              <FormControl mb={4} color="gray.900" width={'auto'}  zIndex={9999}>
                <Field
                  name="loading_time"
                  render={({ field, meta, helpers }) => {
                    const value = tripRequest?.loading_time;
                    const selectedDate = value instanceof Date ? value : new Date(`1970-01-01T${value}`);
                    return (
                      <DatePickerField
                        label="Hora de Carga"
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        name="loading_time"
                        {...field}
                        {...meta}
                        {...helpers}
                        selected={selectedDate}
                        value={field.value ? field.value : value}
                        onChange={(date) => {
                          helpers.setValue(date);
                        }}
                        cy="loading-time"
                      />
                    );
                  }}
                />
              </FormControl>
            </Flex>

            <Flex
              mt={3}
              w="100%"
              alignItems={{ base: 'flex-start', md: 'center' }}
              gap={2}
              justifyContent={{ base: 'flex-start', md: 'space-between' }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <FormControl mb={4} color="gray.900" width={'auto'}>
                <Field
                  style={{ width: '100%' }}
                  name="unloading_date"
                  render={({ field, meta, helpers }) => (
                    <DatePickerField label="Fecha de descarga" {...field} {...meta} {...helpers} cy="unloading-date" />
                  )}
                />
              </FormControl>
              <FormControl mb={4} color="gray.900" width={'auto'}>
                <Field
                  name="unloading_time"
                  render={({ field, meta, helpers }) => {
                    const value = tripRequest?.unloading_time;
                    const selectedDate = value instanceof Date ? value : new Date(`1970-01-01T${value}`);

                    return (
                      <DatePickerField
                        name="unloading_time"
                        label="Hora de Descarga"
                        placeholder="Hora de descarga"
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        {...field}
                        {...meta}
                        {...helpers}
                        selected={selectedDate}
                        value={field.value ? field.value : value}
                        onChange={(date) => {
                          helpers.setValue(date);
                        }}
                        cy="unloading-time"
                      />
                    );
                  }}
                />
              </FormControl>
            </Flex>
            <FormControl id="origin" color="gray.900" mt={3}>
              <FormLabel color="gray.900">Origen</FormLabel>
              <HStack>
                <Field name="origin">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={municipalities}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Origen"
                        isCleaning={false}
                        isEditing
                        defaultValue={{
                          value: formValues?.origin?.id,
                          label: formValues?.origin?.name,
                        }}
                      />
                    );
                  }}
                </Field>
                {/* <Tooltip label="Temporalmente desabilitado" hasArrow>
                  <Button colorScheme="blue" onClick={openCreateMunicipalityModal} isDisabled>
                    +
                  </Button>
                </Tooltip> */}
              </HStack>
            </FormControl>

            <FormControl id="destination" color="gray.900" my={4}>
              <FormLabel color="gray.900">Destino</FormLabel>
              <HStack>
                <Field name="destination">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={municipalities}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Destino"
                        isCleaning={false}
                        isEditing
                        defaultValue={{
                          value: formValues?.destination?.id,
                          label: formValues?.destination?.name,
                        }}
                      />
                    );
                  }}
                </Field>
                {/* <Tooltip label="Temporalmente desabilitado" hasArrow mb={2}>
                  <Button
                    colorScheme="blue"
                    // onClick={openCreateMunicipalityModal}
                    isDisabled
                  >
                    +
                  </Button>
                </Tooltip> */}
              </HStack>
            </FormControl>

            <FormControl id="unitType" color="gray.900" mb={3}>
              <FormLabel color="gray.900">Unidad</FormLabel>
              <HStack>
                <Field name="unit_type">
                  {({ field, form, meta }) => {
                    return (
                      <InputSelectFormik
                        options={unitTypes}
                        field={field}
                        meta={meta}
                        form={form}
                        placeholder="Unidad"
                        isCleaning={false}
                        isEditing
                        defaultValue={{
                          value: formValues?.unit_type?.id,
                          label: formValues?.unit_type?.name,
                        }}
                      />
                    );
                  }}
                </Field>
                {/* <Button colorScheme="blue" onClick={openCreateUnitTypeModal}>
                  +
                </Button> */}
              </HStack>
            </FormControl>

            <FormControl id="unitType" color="gray.900" mb={3}>
              <FormLabel color="gray.900">Número de viaje</FormLabel>

              <Field
                style={{ width: '100%' }}
                name="shipment_number"
                render={({ field, meta, helpers }) => <Input {...field} {...meta} {...helpers} />}
              />
            </FormControl>
            <FormControl id="unitType" color="gray.900" mb={3}>
              <FormLabel color="gray.900">Número de confirmación</FormLabel>
              <Field
                style={{ width: '100%' }}
                name="folio_number"
                render={({ field, meta, helpers }) => <Input {...field} {...meta} {...helpers} />}
              />
            </FormControl>
            <Grid templateColumns="repeat(1, 1fr)" w="100%" ml={2}>
              {hasClientCoordination && (
                <FormControl marginY={4} display="flex" alignItems="center" gap={2} color="gray.900">
                  <Checkbox
                    name="is_approved"
                    isChecked={isApprovedByMC}
                    onChange={onToggleChange}
                    style={{
                      borderColor: 'gray',
                      backgroundColor: 'white',
                    }}
                  />
                  <FormLabel mt={1} color="gray.900">
                    Aprobado por MC
                  </FormLabel>
                </FormControl>
              )}
            </Grid>
            <Grid templateColumns="repeat(1, 1fr)" gap={1} w="100%" ml={2}>
              <GridItem>
                {hasSupplierCoordination && (
                  <FormControl mt={4} display="flex" alignItems="center" gap={2} mb={0}>
                    <Checkbox
                      name="is_approved_by_traffic"
                      isChecked={isApprovedByTraffic}
                      onChange={onToggleChange}
                      style={{
                        borderColor: 'gray',
                        backgroundColor: 'white',
                      }}
                    />
                    <FormLabel mb={0} color="gray.900">
                      Aprobado por trafico
                    </FormLabel>
                  </FormControl>
                )}
              </GridItem>
            </Grid>

            <Flex mt={8}>
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <Button
                  colorScheme="gray"
                  type="button"
                  onClick={() => setEditDetail(false)}
                  size={{ base: 'xs', sm: 'sm', lg: 'md' }}
                >
                  Cancelar
                </Button>
                <Button colorScheme="red" type="button" onClick={openModal} size={{ base: 'xs', sm: 'sm', lg: 'md' }}>
                  Borrar viaje
                </Button>
              </Box>
              <Spacer />
              <Box>
                <Button colorScheme="blue" type="submit" isDisabled={loading} size={{ base: 'xs', sm: 'sm', lg: 'md' }}>
                  Actualizar viaje
                </Button>
              </Box>
            </Flex>
          </Form>
        </Formik>
      )}

      <CreateMunicipalityModal
        isOpen={isCreateMunicipalityModalOpen}
        onClose={closeCreateMunicipalityModal}
        refreshDropdowns={refreshDropdowns} // Replace with the actual function to refresh dropdowns
      />
      <CreateUnitTypeModal
        isOpen={isCreateUnitTypeModalOpen}
        onClose={closeCreateUnitTypeModal}
        refreshDropdowns={refreshDropdowns} // Replace with the actual function to refresh dropdowns
      />
    </Container>
  );
}

export default EditTripRequestDetails;
