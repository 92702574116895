import React from 'react';
// import { FiFilter } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import { Box, Flex, Heading, useToast } from '@chakra-ui/react';

import AvailabilitiesFilters from '../tripRequest/utils/AvailabilitiesFilters';
import Buttom from '../utils/buttons/Buttom';

const Header = ({
  handleSelectChange,
  filters,
  withMaxScreen,
  handleSearchLocation,
  selectedOptions,
  handleCreateTrip,
  data,
}) => {
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);
  const { clients } = useSelector((state) => state.clientDropdownList);
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  const toast = useToast();
  
  const copyDataToClipboard = () => {
    const csvData = convertToCSV(data);
    copyToClipboard(csvData);
    

    toast({
      title: "Success",
      description: "Data has been copied to clipboard successfully!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const convertToCSV = (data) => {
    const headers = ['Fecha de oferta', 'Unidad', 'Origen', 'Destino', 'Fecha Disponible', 'Transportista'].join("\t");
    const indexes = ['offered_at', 'unit_type', 'origin', 'destinations', 'start_date', 'carrier_name'];

    let rows = [];

    for (let i = 0; i < data.length; i++) {
      let row = [];
      for (let j = 0; j < indexes.length; j++) {
        if (data[i][indexes[j]] === undefined || data[i][indexes[j]] === null) {
          row.push('');
          continue;
        }
        let value = data[i][indexes[j]];
        if (indexes[j] === 'start_date') {
          value = data[i]["start_date"];
          if (data[i]['start_time'] !== undefined && data[i]['start_time'] !== null) {
            value += ' ' + data[i]['start_time'];
          }
        }
        row.push('"'+value+'"');
      }
      rows.push(row.join("\t"));
    }

  return `${headers}\n${rows.join("\n")}`;
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Copied to clipboard successfully!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <Box mt={{ base: 3, xl: 5 }} pt={2}>
      <Box display={{ base: 'flex' }} justifyContent={'space-between'}>
        <Heading
          as="h1"
          size={{ base: 'sm', md: 'md' }}
          pt={2}
          pl={2}
          color="gray.700"
          display={{ base: 'block', xl: 'hidden' }}
        >
          Disponibilidades
        </Heading>
        <div style={{ width: '250px',display: 'flex', justifyContent: userDetails?.is_staff ? 'space-between' : 'flex-end' }}>
        {userDetails?.is_staff && (
          <Buttom
            onClick={handleCreateTrip}
            text="Crear solicitud"
            color={'orange.500'}
            background="blue.400"
            size="sm"
            borderRadius="xl"
            p={2}
            m={0}
            mt={2}
            display={{ base: 'flex', xl: 'none' }}
            w="100%"
          />
        )}
        <Buttom
            onClick={copyDataToClipboard}
            text="Copiar datos"
            color={'orange.500'}
            background="blue.400"
            size="sm"
            borderRadius="xl"
            p={2}
            m={0}
            mt={2}
            display={{ base: 'flex', xl: 'none' }}
            w="100%"
          />
          </div>
      </Box>

      <AvailabilitiesFilters
        handleSelectChange={handleSelectChange}
        clients={clients}
        unitTypes={unitTypes}
        filters={filters}
        withMaxScreen={withMaxScreen}
        handleSearchLocation={handleSearchLocation}
        selectedOptions={selectedOptions}
      />
    </Box>
  );
};

export default Header;
