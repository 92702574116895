/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Box, Icon, Spinner, Text } from '@chakra-ui/react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';

import SortIcon from '../icons/SortIcon';
import { columns } from './columns';

const TableAvailability = ({
  loading,
  data,
  setData,
  elementShow,
  withMaxScreen,
  setWithMaxScreen,
  openStatusesModal,
  readStatus,
  openDetailMessage,
  openSureModal,

  disabledButtons,
  handleReadStatusToggle,
  openDetailModal,
  is_staff,

  handleColumnSort,
}) => {
  const [hovered, setHovered] = React.useState(null);
  let columnsTable = columns(
    openDetailMessage,
    openSureModal,
    disabledButtons,
    handleReadStatusToggle,
    readStatus,
    openStatusesModal,
    is_staff
  );
  const table = useReactTable({
    data,
    columns: columnsTable,
    initialState: {
      pageIndex: 0,
      pageSize: elementShow,
      pagination: {
        pageIndex: 0,
        pageSize: elementShow,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    meta: {
      updateData: (rowIndex, columnId, value) =>
        setData((prev) =>
          prev.map((row, index) =>
            index === rowIndex
              ? {
                  ...prev[rowIndex],
                  [columnId]: value,
                }
              : row
          )
        ),
    },
  });

  useEffect(() => {
    table.setPageSize(elementShow);
    setWithMaxScreen(table?.getTotalSize());
  }, [elementShow, table]);
  const borderStyle = '1px solid #e1e1e1';
  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        position: 'relative',
      }}
      maxWidth={withMaxScreen}
    >
      <Box
        className="table"
        minHeight={'100%'}
        display={{ base: 'none', xl: 'block' }}
        maxWidth={withMaxScreen}
        style={{
          borderLeft: borderStyle, // Lighter border color
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <Box
            key={headerGroup.id}
            width={withMaxScreen - 2}
            position={'sticky'}
            top={5}
            zIndex={2}
            bg="white"
            style={{
              borderRight: borderStyle, // Lighter border color
            }}
          >
            <Box
              className="tr"
              key={headerGroup.id}
              style={{
                borderTop: borderStyle,
              }}
            >
              {headerGroup.headers.map((header) => {
                const validateStickyHeader = header.column.id === 'respond' && table.getRowModel().rows.length !== 0;
                return (
                  <Box
                    className="th"
                    width={header.column.columnDef.header === null ? '0px' : header.getSize()}
                    key={header?.id}
                    style={{
                      display: validateStickyHeader && 'none',
                      textAlign: 'center',
                      background: 'white',
                      textWrap: 'wrap',
                      padding: '10px',
                    }}
                  >
                    {header.column.columnDef.header}

                    {header.column.columnDef.accessorKey !== 'created_at' &&
                      header.column.columnDef.accessorKey !== 'main_msg' &&
                      header.column.columnDef.accessorKey !== 'start_date' &&
                      header.column.columnDef.header !== null &&
                      header.column.getCanSort() && (
                        <Icon as={SortIcon} mx={3} fontSize={14} onClick={() => handleColumnSort(header.column)} />
                      )}
                    {
                      {
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()]
                    }

                    {table.getRowModel().rows.length !== 0 && (
                      <Box
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ' '}`}
                      >
                        {' '}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
        {loading ? (
          <Box
            bg="white"
            p={0}
            py={10}
            height={420}
            m={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={'99.99%'}
            style={{
              borderRight: borderStyle, // Lighter border color
            }}
          >
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.700" size={'xl'} />
          </Box>
        ) : table.getRowModel().rows.length === 0 ? (
          <Box
            pt={4}
            height={240}
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="99.98%"
            position={'sticky'}
            bg="white"
            style={{
              borderRight: borderStyle, // Lighter border color
              borderBottom: borderStyle, // Lighter border color
            }}
          >
            <Text fontWeight="normal">No hay disponibilidades</Text>
          </Box>
        ) : (
          <Box
            w="99.99%"
            position={'sticky'}
            bg="white"
            style={{
              borderRight: borderStyle, // Lighter border color
            }}
          >
            {table.getRowModel().rows.map((row) => {
              const cells = row.getVisibleCells();
              const lastCell = cells[cells.length - 1];
              const haveBeenSeen = readStatus[row?.original?.id];
              return (
                <Box
                  className="tr"
                  key={row?.id}
                  _hover={{
                    borderBottom: '1px solid',
                    borderTop: '1px solid',
                    borderColor: 'gray.400',
                  }}
                  minH={'55px'}
                  bg={haveBeenSeen ? 'blue.50' : 'white'}
                  onMouseEnter={() => setHovered(row?.original?.id)}
                  onMouseLeave={() => setHovered(null)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Box
                        w={lastCell?.id !== cell.id && cell?.column.getSize()}
                        border="none"
                        borderBottom="1px solid #E2E8F0"
                        cursor={'pointer'}
                        style={
                          lastCell?.id === cell?.id
                            ? {
                                position: 'absolute',
                                visibility: hovered === cell?.row?.original?.id ? 'visible' : 'hidden',
                                width: '12%',
                                right: 0,
                                backgroundColor: haveBeenSeen ? '#F3F5FF' : 'white', //this is a problem
                                zIndex: 999,
                              }
                            : {}
                        }
                        key={cell?.id}
                        onClick={
                          cell.column.columnDef.isInteractive
                            ? null
                            : cell.column?.columnDef?.isOpenMessage
                              ? () => openDetailMessage(row?.original?.id) //openDetailMessage(row?.original?.id) whe messages are implemented
                              : () => openDetailModal(row?.original?.id)
                        }
                      >
                        <Box border="none">{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TableAvailability;
