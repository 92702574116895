import { Box, Skeleton, SkeletonText, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const TableSkeleton = () => {
  return (
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'} w="100%">
      <Box w="100%">
        <SkeletonText mb="4" noOfLines={1} spacing="4" />
        {/* Filters */}
        <Stack direction="row" spacing="24px" mb={5}>
          <Skeleton height="20px" width="200px" />
          <Skeleton height="20px" width="200px" />
          <Skeleton height="20px" width="200px" />
          <Skeleton height="20px" width="200px" />
          <Skeleton height="20px" width="200px" />
        </Stack>
        <Box padding="6" boxShadow="lg" bg="white">
          {/* Table */}
          <Table variant="simple" mt="24px">
            <Thead>
              <Tr>
                <Th>
                  <Skeleton height="20px" />
                </Th>
                <Th>
                  <Skeleton height="20px" />
                </Th>
                <Th>
                  <Skeleton height="20px" />
                </Th>
                <Th>
                  <Skeleton height="20px" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
                <Td>
                  <SkeletonText mt="4" noOfLines={3} spacing="4" />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default TableSkeleton;
