import React from 'react';

import { Text, Tooltip } from '@chakra-ui/react';

const CopyTextTooltip = ({ text }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(text);
  };
  return (
    <Tooltip label="Copiar" background={'blue.600'} hasArrow>
      <Text
        fontWeight={'bold'}
        color="blue.400"
        bg="gray.100"
        p={1}
        px={2}
        rounded={'full'}
        fontSize={10}
        m={0}
        cursor={'pointer'}
        onClick={handleCopy}
        w={'fit-content'}
      >
        {text}
      </Text>
    </Tooltip>
  );
};

export default CopyTextTooltip;
