import { combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { configureStore } from '@reduxjs/toolkit';

import { actionCreateReducer, actionFormReducer } from './reducers/actionReducers';
import {
  availabilityDeleteReducer,
  availabilityDetailReducer,
  availabilityListReducer,
  availabilityUpdateReducer,
} from './reducers/availabilityReducers';
import { bidRequestReducer, bidRequestDetailReducer } from './reducers/bidRequestReducer';
import { bidRequestSetReducer, bidRequestStatusesReducer } from './reducers/bidRequestSetReducer';
import { aprovedBulkReducer, deleteBulkReducer } from './reducers/bulkReducers';
import { chatGroupVisualizerReducer, messagesVisualizerReducer } from './reducers/chatVisualizerReducers';
import { clientDetailsReducer, clientDropdownListReducer, clientDropdownTransportationListReducer, contactsListReducer } from './reducers/clientReducers';
import { conversationsForwadersReducer } from './reducers/conversationReducers';
import {
  decisionTreeListReducer,
  decisionTreeDetailsReducer,
  decisionTreeCreateReducer,
  decisionTreeDeleteReducer,
} from './reducers/decisionTreeReducers';
import { metroAreaDropdownListReducer } from './reducers/metroAreaReducers';
import { municipalityDropdownListReducer, municipalityDropdownReducer } from './reducers/municipalityReducers';
import { productListReducer, productDetailsReducer } from './reducers/productReducers';
import { queueDetailsReducer, queueListReducer } from './reducers/queueReducers';
import { stateDropdownListReducer } from './reducers/stateReducers';
import {
  tripRequestListReducer,
  tripRequestDetailsReducer,
  tripRequestMessagesReducer,
  tripRequestSupplierMessagesReducer,
  tripRequestSendSupplierMessagesReducer,
  tripRequestUpdateReducer,
  tripRequestAssignReducer,
  tripRequestApproveReducer,
  tripRequestTrafficApproveReducer,
  tripRequestCompleteReducer,
  tripRequestDeleteReducer,
  tripRequestResponseReducer,
  tripRequestSendCommentReducer,
} from './reducers/tripRequestReducers';
import { unitTypeDropdownListReducer } from './reducers/unitTypeReducers';
import { userLoginReducer, userRegisterReducer, userDetailsReducer } from './reducers/userReducers';

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  userRegister: userRegisterReducer,
  decisionTreeList: decisionTreeListReducer,
  decisionTreeDetails: decisionTreeDetailsReducer,
  decisionTreeCreate: decisionTreeCreateReducer,
  decisionTreeDelete: decisionTreeDeleteReducer,
  tripRequestList: tripRequestListReducer,
  tripRequestDetails: tripRequestDetailsReducer,
  tripRequestMessages: tripRequestMessagesReducer,
  tripRequestSupplierMessages: tripRequestSupplierMessagesReducer,
  tripRequestSendSupplierMessages: tripRequestSendSupplierMessagesReducer,
  tripRequestUpdate: tripRequestUpdateReducer,
  tripRequestAssign: tripRequestAssignReducer,
  tripRequestApprove: tripRequestApproveReducer,
  tripRequestTrafficApprove: tripRequestTrafficApproveReducer,
  tripRequestComplete: tripRequestCompleteReducer,
  tripRequestDelete: tripRequestDeleteReducer,
  tripRequestResponse: tripRequestResponseReducer,
  municipalityDropdownList: municipalityDropdownListReducer,
  unitTypeDropdownList: unitTypeDropdownListReducer,
  metroAreaDropdownList: metroAreaDropdownListReducer,
  stateDropdownList: stateDropdownListReducer,
  clientDropdownList: clientDropdownListReducer,
  transportationClientDropdownList: clientDropdownTransportationListReducer,
  actionCreate: actionCreateReducer,
  actionForm: actionFormReducer,
  queueList: queueListReducer,
  queueDetails: queueDetailsReducer,
  tripRequestSendComment: tripRequestSendCommentReducer,
  conversationsForwaders: conversationsForwadersReducer,
  aprovedBulk: aprovedBulkReducer,
  deleteBulk: deleteBulkReducer,
  bidRequest: bidRequestReducer,
  bidRequestDetail: bidRequestDetailReducer,
  bidRequestSet: bidRequestSetReducer,
  bidRequestStatuses: bidRequestStatusesReducer,
  chatGroupVisualizer: chatGroupVisualizerReducer,
  messagesVisualizerReducer: messagesVisualizerReducer,
  availabilitiesList: availabilityListReducer,
  availabilityDetail: availabilityDetailReducer,
  availabilityDelete: availabilityDeleteReducer,
  availabilityUpdate: availabilityUpdateReducer,
  contactsClients: clientDetailsReducer,
  municipalityList: municipalityDropdownReducer,
  contactsList: contactsListReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage, loading: false, error: '', updatedPassword: '' },
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools:
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools() // Use composeWithDevTools only in non-production environments
      : false,
  preloadedState: initialState,
});

export default store;
