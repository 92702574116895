/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, React } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';

import { updateAprovedBulk, updateDeletedBulk } from '../actions/bulkActions';
import { cleanContactsDetails, cleanListContacts, listClientsDropdown } from '../actions/clientActions';
import { createNewTrip } from '../actions/createTripAction';
import { listDropdownMunicipality, listMunicipalitiesDropdown } from '../actions/municipalityActions';
import {
  approveTripRequest,
  cleanlistTripRequestForwardMessages,
  cleanlistTripRequestMessages,
  deleteTripRequest,
  forwardForwardBidRequestMessage,
  getTripRequestDetails,
  listTripRequests,
  listTripRequestsDownload,
  rejectTripRequest,
  unreadTripRequest,
} from '../actions/tripRequestActions';
import { listUnitTypesDropdown } from '../actions/unitTypeActions';
// import DownloadCSVButton from "../components/DownloadCSVButton";
import httpClientFormData from '../actions/utils/axiosInstanceFormData';
import AssignSupplier from '../components/AssignSupplier';
import ForwardMessage from '../components/ForwardMessage';
import SortIcon from '../components/icons/SortIcon';
import ScrollableChat from '../components/ScrollableChat';
import SureModal from '../components/SureModal';
import AssignSupplierModal from '../components/tripRequest/AssignRequest';
import CarouselModal from '../components/tripRequest/CarouselModal';
import GridTripsRequest from '../components/tripRequest/GridTripsRequest';
import RejectedModal from '../components/tripRequest/RejectedModal';
import TableActionGloblal from '../components/tripRequest/TableActionGloblal';
import TableTripRequest from '../components/tripRequest/TableTripRequest';
import UploadFileModal from '../components/tripRequest/UploadFileModal';
import TripRequestPaginator from '../components/TripRequestPaginator';
import { formattedData } from '../components/TripTableDateSelect';
import CreateTripModal from '../components/utils/modals/CreateTripModal';
import DetailsMessagesModal from '../components/utils/modals/DetailsMessagesModal';
import TableSkeleton from '../components/utils/skeletons.jsx/TableSkeleton';
import { filterByStatusTrips, statusesMap, handleRecent } from '../components/utils/TabsFilters';
import moment from 'moment';

const TripTableTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useHistory
  const tripRequestList = useSelector((state) => state.tripRequestList);
  const { error, loading, tripRequests = [], pagination, filters } = tripRequestList;
  const getStatuses = tripRequests?.map((item) => ({
    ...item,
    is_recent: handleRecent(item?.created_at),
    status: {
      '1 is_approved': item.is_approved,
      '2 suppliers_have_been_contacted': item.suppliers_have_been_contacted,
      '3 is_approved_by_traffic': item.is_approved_by_traffic,
      '4 assigned_transporter': !!item.assigned_transporter,
      '5 is_complete': item.is_complete,
      '6 is_rejected': item.is_rejected,
    },
  }));
  const transformedData = getStatuses?.map((item) => {
    const statusKeys = Object.keys(item.status);
    let lastTrueStatus = null;

    for (let i = statusKeys.length - 1; i >= 0; i--) {
      const key = statusKeys[i];
      if (item.status[key] === true) {
        lastTrueStatus = key.split(' ')[1];
        break;
      }
    }

    return {
      ...item,
      status: lastTrueStatus,
    };
  });

  const [sortConfig, setSortConfig] = useState({
    key: 'requested_at',
    direction: 'desc',
  });
  const [data, setData] = useState([]);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [tablePage, setTablePage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [currentTripId, setCurrentTripId] = useState();
  const [isChatOpen, setChatOpen] = useState(false);
  const [isForwardMessageOpen, setForwardMessageOpen] = useState(false);
  const [isAssignSupplierOpen, setAssignSupplierOpen] = useState(false);
  const [isAssignSupplierTripOpen, setAssignSupplierTripOpen] = useState(false);
  const [isTripRequestModalOpen, setTripRequestModalOpen] = useState(false);
  const [isConversationsModalOpen, setConversationsModalOpen] = useState(false);
  const [conversationsIds, setConversationsIds] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('recent');
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const [showBulkModal, setShowBulkModal] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [elementShow, setElementShow] = useState(40);
  const [withMaxScreen, setWithMaxScreen] = useState(1375);
  const userDetailsData = useSelector((state) => state.userDetails);

  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isABidSet, setIsABidSet] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socket = new WebSocket('wss://a9bwkzfk83.execute-api.us-east-1.amazonaws.com/dev/');

    socket.onopen = () => {
      console.log('WebSocket Connected');
    };

    socket.onmessage = (event) => {
      console.log('MESSAGE');
      refreshTripRequests();
    };

    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket Connection Closed');
    };

    setSocket(socket);

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, []);

  const handleUploadFile = async (event) => {
    event.preventDefault();
    const file = fileUploaded;
    if (!file) {
      toast.error('No hay archivo seleccionado');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    setIsUploadLoading(true);
    try {
      await httpClientFormData.post('api/v1/trip-requests/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Archivo subido exitosamente');
      setIsUploadLoading(false);
      setFileUploaded('');
      setIsOpenUploadModal(false);
      event.target.value = null;
      refreshTripRequests();
    } catch (error) {
      setIsUploadLoading(false);
      toast.error('Error al subir archivo🥲');
      setFileUploaded('');
    }
  };
  const handleUploadAndSendFile = async (event) => {
    event.preventDefault();
    const file = fileUploaded;
    if (!file) {
      toast.error('No hay archivo seleccionado');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('send_to_suppliers', 'true');
    setIsUploadLoading(true);
    try {
      await httpClientFormData.post('api/v1/trip-requests/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Archivo subido exitosamente');
      setIsUploadLoading(false);
      setFileUploaded('');
      setIsOpenUploadModal(false);
      event.target.value = null;
      refreshTripRequests();
    } catch (error) {
      setIsUploadLoading(false);
      toast.error('Error al subir archivo🥲');
      setFileUploaded('');
    }
  };
  const handleUploadModal = () => {
    setIsOpenUploadModal(!isOpenUploadModal);
  };
  //TODO: new one posible the old would be delete for the moment is commented
  const handleOpenAssignModal = (params) => {
    setIsRejected(params.is_rejected);
    setCurrentTripId(params.id);
    setConversationsIds(params.forwarded_supplier_chats);
    setTripData(params);

    setAssignSupplierOpen(true);
  };
  const handleCloseAssignModal = () => {
    setAssignSupplierOpen(false);
    setIsRejected(false);
    setCurrentTripId(null);
    setConversationsIds([]);
    setTripData([]);
    dispatch(cleanlistTripRequestForwardMessages());
  };

  const handleCloseUploadModal = () => {
    setIsOpenUploadModal(false);
    setIsUploadLoading(false);
    setFileUploaded(null);
  };
  const { errorUserDetails, loading: loadingUserDetails, userDetails } = userDetailsData;

  const tripRequestDetails = useSelector((state) => state.tripRequestDetails);
  const { loading: loadingTripRequestDetails } = tripRequestDetails;

  const { loading: loadingAprovedBulk } = useSelector((state) => state.aprovedBulk);
  const { loading: loadingDeletedBulk } = useSelector((state) => state.deleteBulk);

  const { municipalities } = useSelector((state) => state.municipalityDropdownList);
  const { clients } = useSelector((state) => state.clientDropdownList);
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);

  // if the user is a supplier coordinator, add the supplier column
  const hasSupplierCoordination = userDetails?.roles.some((role) => role.id_verbose === 'supplier_coordination');

  // if the user is a supplier coordinator, add the supplier column
  const hasClientCoordination = userDetails?.roles.some((role) => role.id_verbose === 'client_coordination');

  // Fetch filter options when the component mounts
  useEffect(() => {
    if (error) return;

    dispatch(listMunicipalitiesDropdown());
  }, [dispatch]);

  //only for filters
  useEffect(() => {
    if (error) return;

    dispatch(listDropdownMunicipality());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;

    dispatch(listClientsDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;
    dispatch(listUnitTypesDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;

    // Check if the selected option has changed
    // Extract selected options from state
    const buildSelectedFilters = Object.entries(selectedOptions)
      .filter(([_, value]) => !!value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    setSelectedFilters(buildSelectedFilters);
    setTablePage(1);
  }, [selectedOptions, selectedOption]);
  // update tripRequests every TIMER_MS
  const TIMER_MS = 10000;
  useEffect(() => {
    if (
      error ||
      errorUserDetails ||
      loadingUserDetails ||
      isConversationsModalOpen ||
      isChatOpen ||
      isTripRequestModalOpen ||
      isForwardMessageOpen ||
      isRejectModalOpen ||
      loading ||
      isOpenUploadModal
    )
      return;

    if (!isTripRequestModalOpen || !loadingDeletedBulk || !loadingAprovedBulk) {
      refreshTripRequests();
    }

    const interval = setInterval(() => {
      //refreshTripRequests();
    }, TIMER_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [
    sortConfig,
    tablePage,
    selectedFilters,
    selectedStatus,
    error,
    errorUserDetails,
    loadingUserDetails,
    isConversationsModalOpen,
    isChatOpen,
    isTripRequestModalOpen,
    isForwardMessageOpen,
    loadingAprovedBulk,
    loadingDeletedBulk,
    isRejectModalOpen,
    elementShow,
    loading,
    isTripRequestModalOpen,
    isOpenUploadModal,
  ]);

  const refreshTripRequests = () => {
    dispatch(
      listTripRequests({
        order_by: sortConfig.key,
        ordering: sortConfig.direction,
        page: tablePage,
        page_size: elementShow,
        // Include selected filters
        ...selectedFilters,
        ...statusesMap[selectedStatus],
        refresh: true,
      })
    );
  };

  useEffect(() => {
    if (loadingUserDetails) return;
    // Fetch trip requests when the component mounts or when filters change
    fetchTripRequests();

    // Update URL with tablePage, filters, and sortConfig
    const params = new URLSearchParams();

    // Add tablePage to URL
    params.set('tablePage', String(tablePage));

    // for each filter in selectedFilters add it to the params
    Object.entries(selectedFilters).forEach(([key, value]) => {
      params.set(key, String(value));
    });

    // Add sortConfig to URL
    params.set('sortKey', sortConfig.key);
    params.set('sortDirection', sortConfig.direction);

    // Update the URL using navigate
    navigate(`/?${params.toString()}`);
  }, [
    sortConfig,
    tablePage,
    selectedFilters,
    selectedStatus,
    loadingUserDetails,
    elementShow,
    // loadingTripRequestDetails,
  ]);

  //This is a  delicate useEffect
  useEffect(() => {
    if (error || loadingUserDetails) return;
    // Update the data when tripRequests change
    setData(transformedData);
  }, [error, tripRequests, loadingUserDetails]);

  useEffect(() => {
    if (error === 'Invalid page.') {
      setTablePage(1);
    }
  }, [error]);

  const openChatModal = (id) => {
    setCurrentTripId(id);
    setChatOpen(true);
  };

  const closeChatModal = () => {
    setCurrentTripId(null);
    setChatOpen(false);
  };

  const openForwardMessageModal = (id, isFile) => {
    setIsABidSet(isFile);
    setCurrentTripId(id);
    setForwardMessageOpen(true);
  };

  const closeForwardMessageModal = () => {
    if (!openForwardMessageModal) {
      setCurrentTripId(null);
    }
    // setCurrentTripId(null);
    setForwardMessageOpen(false);
    setIsABidSet(false);
    dispatch(cleanlistTripRequestForwardMessages());
  };

  const openAssignSupplierTrafficModal = (id, supplier) => {
    setSupplierSelected(supplier);
    setCurrentTripId(id);
    setAssignSupplierTripOpen(true);
  };
  const closeAssignSupplierTrafficModal = () => {
    setSupplierSelected(null);
    setCurrentTripId(null);
    setAssignSupplierTripOpen(false);
  };

  const openTripRequestModal = async (id, isFile) => {
    await dispatch(cleanlistTripRequestMessages());
    setIsABidSet(!!isFile);
    setCurrentTripId(id);
    setTripRequestModalOpen(true);
  };

  const closeTripRequestModal = async () => {
    setCurrentTripId(null);
    setTripRequestModalOpen(false);
    setIsABidSet(false);
    await dispatch(cleanlistTripRequestMessages());
  };

  const handleConversationsModal = () => {
    setConversationsModalOpen(!isConversationsModalOpen);
    if (!isAssignSupplierOpen) {
      setConversationsIds([]);
      setCurrentTripId(null);
    }
  };
  const handleOpenConversationsModal = (forwardedSupplierChats, id) => {
    if (forwardedSupplierChats.length > 0) {
      setCurrentTripId(id);
      setConversationsIds(forwardedSupplierChats);
    }
    setConversationsModalOpen(!isConversationsModalOpen);
  };
  // Function to fetch trip requests with applied filters
  const fetchTripRequests = () => {
    // Call the listTripRequests action with sorting and filter parameters
    dispatch(
      listTripRequests({
        order_by: sortConfig.key,
        ordering: sortConfig.direction,
        page: tablePage,
        page_size: elementShow,
        ...statusesMap[selectedStatus],
        ...selectedFilters,
      })
    );
  };

  // Identical function to fetch trip requests with but calling listTripRequestsDownload action
  // should be refactored to avoid code duplication
  const fetchTripRequestsDownload = () => {
    // Call the listTripRequests action with sorting and filter parameters
    dispatch(
      listTripRequestsDownload({
        order_by: sortConfig.key,
        ordering: sortConfig.direction,
        page: tablePage,
        page_size: elementShow,
        ...statusesMap[selectedStatus],
        // Include selected filters
        ...selectedFilters,
      })
    );
  };

  const handleApprove = async (id, value) => {
    const isApproved = value;
    await dispatch(approveTripRequest(id, isApproved));
    refreshTripRequests();
  };

  const handleDownloadCSV = () => {
    // Call the listTripRequests action with sorting and filter parameters
    fetchTripRequestsDownload();
  };

  const fetchNextPage = () => {
    setTablePage(pagination.currentPage + 1);
    // fetchTripRequests(sortConfig);
  };

  const fetchPreviousPage = () => {
    setTablePage(pagination.currentPage - 1);
    // fetchTripRequests(sortConfig);
  };

  const handleColumnSort = (column) => {
    // Update the sortConfig state
    const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: column.id, direction: newDirection });
  };

  // Function to handle select change for a specific column
  const handleSelectChange = (columnId, selectedOption, endDate) => {
    const columFilterDictionary = {
      origin: 'origin',
      destination: 'destination',
      client_name: 'client_id',
      unit_type: 'unit_type',
      is_attended: 'is_attended',
      requested_at: 'requested_at',
      loading_date: 'loading_date',
      unloading_date: 'unloading_date',
    };
    //para fechas

    setSelectedOption(selectedOption);

    if (selectedOption === null) {
      // Remove the filter from the columnFilters state
      setSelectedOptions((prevSelectedOptions) => {
        const { [columFilterDictionary[columnId]]: _, ...rest } = prevSelectedOptions;
        return rest;
      });
    } else {
      if (columnId === 'requested_at') {
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          requested_at_after: selectedOption ? formattedData(selectedOption) : selectedOption,
          requested_at_before: endDate ? formattedData(endDate) : endDate,
        }));
      } else if (columnId === 'unloading_date') {
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          unloading_date_after: selectedOption ? formattedData(selectedOption) : selectedOption,
          unloading_date_before: endDate ? formattedData(endDate) : endDate,
        }));
      } else {
        // Add the filter to the selectedOptions state
        setSelectedOptions((prevSelectedOptions) => ({
          ...prevSelectedOptions,
          [columFilterDictionary[columnId]]: selectedOption,
        }));
      }
    }
  };

  const handleSearchLocation = (values) => {
    const columFilterDictionary = {
      origin: 'origin',
      destination: 'destination',
      client_id: 'client_id',
      unit_type: 'unit_type',
    };

    Object.keys(values).forEach((columnId) => {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [columFilterDictionary[columnId]]: values[columnId],
      }));
    });
  };
  const [showDeleteModal, setShowModal] = useState({
    id: null,
    show: false,
  });
  const deleteHandler = () => {
    dispatch(deleteTripRequest(showDeleteModal.id)).then(() => {
      refreshTripRequests();
      setShowModal({ id: null, show: false });
    });
  };

  const [readStatus, setReadStatus] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});
  const [checkedItems, setCheckedItems] = useState([]);
  // Initialize the readStatus state based on the initial data
  useEffect(() => {
    if (tripRequests) {
      const initialReadStatus = {};
      tripRequests.forEach((request) => {
        initialReadStatus[request.id] = request.seen_by_user;
      });
      setReadStatus(initialReadStatus);
    }
  }, [tripRequests]);
  const handleReadStatusToggle = (id) => {
    const currentStatus = readStatus[id];
    setDisabledButtons(() => ({
      [id]: true,
    }));
    setReadStatus((prevReadStatus) => ({
      ...prevReadStatus,
      [id]: !prevReadStatus[id],
    }));

    if (currentStatus) {
      dispatch(unreadTripRequest(id)).then(() => {
        setDisabledButtons({});
      });
    } else {
      dispatch(getTripRequestDetails(id)).then(() => {
        setDisabledButtons({});
      });
    }
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allItems = data.map((item) => ({ id: item.id, supplierId: item.preassigned_transporter }));
      setCheckedItems(allItems);
    } else {
      setCheckedItems([]);
    }
  };
  const [lastChecked, setLastChecked] = useState(null);
  //without supplier forward
  const handleCheckboxChange = (options, event) => {
    const { id, index, supplierId } = options;
    const shiftKeyPressed = event.nativeEvent.shiftKey;
    const newItem = { id, supplierId };

    if (shiftKeyPressed && lastChecked !== null) {
      const firstIndex = Math.min(index, lastChecked.index);
      const lastIndex = Math.max(index, lastChecked.index);

      const newCheckedItems = new Set(checkedItems.map((item) => JSON.stringify(item)));
      const deleteCheckouts = newCheckedItems.has(
        JSON.stringify({ id: data[index].id, supplierId: data[index].preassigned_transporter })
      );
      for (let i = firstIndex; i <= lastIndex; i++) {
        const currentItem = { id: data[i].id, supplierId: data[i].preassigned_transporter };
        if (deleteCheckouts) {
          newCheckedItems.delete(JSON.stringify(currentItem));
          continue;
        }
        newCheckedItems.add(JSON.stringify(currentItem));
      }

      setCheckedItems(Array.from(newCheckedItems).map((item) => JSON.parse(item)));
    } else {
      setCheckedItems((prevCheckedItems = []) => {
        const newCheckedItems = new Set(prevCheckedItems.map((item) => JSON.stringify(item)));
        const itemString = JSON.stringify(newItem);

        if (newCheckedItems.has(itemString)) {
          newCheckedItems.delete(itemString);
        } else {
          newCheckedItems.add(itemString);
        }

        return Array.from(newCheckedItems).map((item) => JSON.parse(item));
      });
    }

    setLastChecked({ index, id, supplierId });
  };

  const aprovedBulk = () => {
    const data = checkedItems || selectAll;
    const dataIds = data.map((item) => item.id);
    dispatch(updateAprovedBulk(dataIds))
      .then(() => {
        setSelectAll(false);
        setCheckedItems([]);
        toast.success('Solicitudes aprovadas exitosamente');
      })
      .catch((error) => {
        console.error('error in aproved bulk: ', error);
      });
  };
  const forwardBulk = async () => {
    const data = checkedItems || selectAll;
    const transformData = data.map((item) => ({
      send_to: [item?.supplierId],
      trip_id: item?.id,
    }));
    try {
      await dispatch(forwardForwardBidRequestMessage(transformData));
      setSelectAll(false);
      setCheckedItems([]);
      toast.success('Enviadas exitosamente');
    } catch {
      console.error('error in aproved bulk: ', error);
    }
  };

  const deletedBulk = () => {
    const data = checkedItems || selectAll;
    const dataIds = data.map((item) => item.id);

    dispatch(updateDeletedBulk(dataIds))
      .then(() => {
        setSelectAll(false);
        setCheckedItems([]);
        toast.success('Solicitudes eliminadas');
        setShowBulkModal(false);
      })
      .catch((error) => {
        console.error('error in deleted bulk: ', error);
        setShowBulkModal(false);
      });
  };
  const handleRejectedModal = (id, params) => {
    setIsRejected(params);
    setCurrentTripId(id);
    setIsRejectModalOpen(true);
  };

  const handleCloseRejectModal = () => {
    setIsRejectModalOpen(false);
    handleCloseAssignModal();
    setCurrentTripId(null);
    setIsRejected(false);
  };
  const onSubmitRejectTrip = async (values) => {
    const rejected = isRejected;
    const reason = values.reason === 'Otro' ? values.reasonOther : values.reason;
    const data = {
      is_rejected: rejected ? false : true,
      rejection_reason: reason || '',
    };
    dispatch(rejectTripRequest(currentTripId, data))
      .then(() => {
        rejected ? toast.success('Solicitud activada exitosamente') : toast.success('Solicitud rechazada exitosamente');

        setIsRejectModalOpen(false);
        setCurrentTripId(null);
        setIsRejected(false);
      })
      .catch((error) => {
        setIsRejected(false);
        console.error('error in rejected bulk: ', error);
        toast.error('Error en la solicitud');
      });
  };
  const [openCreateTrip, setOpenCreateTrip] = useState(false);
  const handleCreateTrip = () => {
    setOpenCreateTrip(!openCreateTrip);
  };
  const handleCreateNewTrip = async (data) => {
    const params = {
      ...data,
      channel: data?.channel ?? '',
      destination_location: data?.destination_location ?? '',
      origin_location: data?.origin_location ?? '',
      folio_number: data?.folio_number ?? '',
      shipment_number: data?.shipment_number ?? '',
      unit_type: data?.unit_type ?? '',
      loading_date: data.loading_date ? formattedData(data.loading_date) : '',
      unloading_date: data.unloading_date ? formattedData(data.unloading_date) : '',
      loading_time: data.loading_time ? moment(data.loading_time).utcOffset(-6, true).format('HH:mm') : '',
      unloading_time: data.loading_time ? moment(data.unloading_time).utcOffset(-6, true).format('HH:mm') : '',
      message: data?.message ?? '',
    };
    const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
    try {
      await dispatch(createNewTrip(filteredParams));
      setOpenCreateTrip(false);
      dispatch(cleanContactsDetails());
      dispatch(cleanListContacts());
      refreshTripRequests();
    } catch (error) {
      console.error('Error creating trip:', error);
    }
  };

  if (loadingUserDetails) return <TableSkeleton />;
  return (
    <Box position="relative" p={0} m={0} w={{ xl: withMaxScreen }}>
      <TableActionGloblal
        hasSupplierCoordination={hasSupplierCoordination}
        withMaxScreen={withMaxScreen}
        handleDownloadCSV={handleDownloadCSV}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        filterByStatusTrips={filterByStatusTrips}
        handleSelectChange={handleSelectChange}
        filters={filters}
        selectAll={selectAll}
        checkedItems={checkedItems}
        handleSelectAll={handleSelectAll}
        loadingDeletedBulk={loadingDeletedBulk}
        loadingAprovedBulk={loadingAprovedBulk}
        setShowBulkModal={setShowBulkModal}
        aprovedBulk={aprovedBulk}
        setSelectAll={setSelectAll}
        setLastChecked={setLastChecked}
        setCheckedItems={setCheckedItems}
        handleUploadModal={handleUploadModal}
        forwardBulk={forwardBulk}
        hasClientCoordination={hasClientCoordination}
        handleCreateTrip={handleCreateTrip}
        selectedOption={selectedOption}
        handleSearchLocation={handleSearchLocation}
        data={data}
      />
      <TableTripRequest
        handleRejectedModal={handleRejectedModal}
        checkedItems={checkedItems}
        handleCheckboxChange={handleCheckboxChange}
        unitTypes={unitTypes}
        municipalities={municipalities}
        clients={clients}
        filters={filters}
        handleColumnSort={handleColumnSort}
        SortIcon={SortIcon}
        handleSelectChange={handleSelectChange}
        openChatModal={openChatModal}
        openTripRequestModal={openTripRequestModal}
        flexRender={flexRender}
        data={data}
        loading={loading}
        hasSupplierCoordination={hasSupplierCoordination}
        hasClientCoordination={hasClientCoordination}
        handleApprove={handleApprove}
        handleOpenConversationsModal={handleOpenConversationsModal}
        openForwardMessageModal={openForwardMessageModal}
        setShowModal={setShowModal}
        readStatus={readStatus}
        disabledButtons={disabledButtons}
        handleReadStatusToggle={handleReadStatusToggle}
        loadingTripRequestDetails={loadingTripRequestDetails}
        setData={setData}
        columnFilters={columnFilters}
        elementShow={elementShow}
        withMaxScreen={withMaxScreen}
        setWithMaxScreen={setWithMaxScreen}
        selectAll={selectAll}
        handleSelectAll={handleSelectAll}
        openAssignSupplierTrafficModal={openAssignSupplierTrafficModal}
        handleAssignModal={handleOpenAssignModal}
        refreshTripRequests={refreshTripRequests}
      />
      <GridTripsRequest
        handleRejectedModal={handleRejectedModal}
        data={data}
        openChatModal={openChatModal}
        hasSupplierCoordination={hasSupplierCoordination}
        handleOpenConversationsModal={handleOpenConversationsModal}
        openForwardMessageModal={openForwardMessageModal}
        setShowModal={setShowModal}
        handleReadStatusToggle={handleReadStatusToggle}
        readStatus={readStatus}
        disabledButtons={disabledButtons}
        openTripRequestModal={openTripRequestModal}
        loading={loading}
        municipalities={municipalities}
        clients={clients}
        handleSelectChange={handleSelectChange}
        unitTypes={unitTypes}
        filters={filters}
        checkedItems={checkedItems}
        handleCheckboxChange={handleCheckboxChange}
        handleAssignModal={handleOpenAssignModal}
        openAssignSupplierTrafficModal={openAssignSupplierTrafficModal}
        hasClientCoordination={hasClientCoordination}
        refreshTripRequests={refreshTripRequests}
      />
      {!loading && data.length > 0 && (
        <TripRequestPaginator
          pagination={pagination}
          fetchNextPage={fetchNextPage}
          fetchPreviousPage={fetchPreviousPage}
          elementShow={elementShow}
          setElementShow={setElementShow}
          withMaxScreen={withMaxScreen}
          setTablePage={setTablePage}
        />
      )}

      <CarouselModal
        onClose={handleConversationsModal}
        isOpen={isConversationsModalOpen}
        conversations={conversationsIds}
        currentTripId={currentTripId}
        openForwardMessageModal={openForwardMessageModal}
      />

      <ScrollableChat id={currentTripId} isOpen={isChatOpen} onClose={closeChatModal} />
      <ForwardMessage
        id={currentTripId}
        isOpen={isForwardMessageOpen}
        onClose={closeForwardMessageModal}
        numberId={isABidSet ? 0 : 1}
      />
      <AssignSupplier
        id={currentTripId}
        isOpen={isAssignSupplierTripOpen}
        onClose={closeAssignSupplierTrafficModal}
        supplierSelected={supplierSelected}
        postUpdateCallback={refreshTripRequests}
      />

      <DetailsMessagesModal
        currentTripId={currentTripId}
        isOpen={isTripRequestModalOpen}
        onClose={closeTripRequestModal}
        refreshTripRequests={refreshTripRequests}
        isABidSet={isABidSet}
      />

      <SureModal show={showDeleteModal.show} onHide={() => setShowModal(false)} onConfirm={deleteHandler} />
      <RejectedModal
        show={isRejectModalOpen}
        onHide={handleCloseRejectModal}
        onSubmit={onSubmitRejectTrip}
        isRejected={isRejected}
        isLoading={loadingTripRequestDetails}
      />
      <SureModal
        show={showBulkModal}
        onHide={() => setShowBulkModal(false)}
        onConfirm={deletedBulk}
        title="¿Estás seguro de eliminar todas las solicitudes?"
        description="Ten en cuenta que esta acción no se puede revertir."
        isLoading={loadingDeletedBulk}
      />

      <UploadFileModal
        isOpen={isOpenUploadModal}
        onClose={handleCloseUploadModal}
        setFileUploaded={setFileUploaded}
        handleUploadFile={handleUploadFile}
        isUploadLoading={isUploadLoading}
        fileUploaded={fileUploaded}
        handleUploadAndSendFile={handleUploadAndSendFile}
      />
      <AssignSupplierModal
        isOpen={isAssignSupplierOpen}
        onClose={handleCloseAssignModal}
        handleRejectedModal={handleRejectedModal}
        handleOpenConversationsModal={handleOpenConversationsModal}
        isRejected={isRejected}
        id={currentTripId}
        conversationsIds={conversationsIds}
        tripData={tripData}
      />
      <CreateTripModal isOpen={openCreateTrip} onClose={handleCreateTrip} onSubmit={handleCreateNewTrip} />
    </Box>
  );
};
export default TripTableTemplate;
