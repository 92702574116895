export const ACTION_LIST_REQUEST = 'ACTION_LIST_REQUEST';
export const ACTION_LIST_SUCCESS = 'ACTION_LIST_SUCCESS';
export const ACTION_LIST_FAIL = 'ACTION_LIST_FAIL';

export const ACTION_DETAILS_REQUEST = 'ACTION_DETAILS_REQUEST';
export const ACTION_DETAILS_SUCCESS = 'ACTION_DETAILS_SUCCESS';
export const ACTION_DETAILS_FAIL = 'ACTION_DETAILS_FAIL';

export const ACTION_CREATE_REQUEST = 'ACTION_CREATE_REQUEST';
export const ACTION_CREATE_SUCCESS = 'ACTION_CREATE_SUCCESS';
export const ACTION_CREATE_FAIL = 'ACTION_CREATE_FAIL';

export const ACTION_DELETE_REQUEST = 'ACTION_DELETE_REQUEST';
export const ACTION_DELETE_SUCCESS = 'ACTION_DELETE_SUCCESS';
export const ACTION_DELETE_FAIL = 'ACTION_DELETE_FAIL';

export const ACTION_UPDATE_REQUEST = 'ACTION_UPDATE_REQUEST';
export const ACTION_UPDATE_SUCCESS = 'ACTION_UPDATE_SUCCESS';
export const ACTION_UPDATE_FAIL = 'ACTION_UPDATE_FAIL';

export const ACTION_FORM_REQUEST = 'ACTION_FORM_REQUEST';
export const ACTION_FORM_SUCCESS = 'ACTION_FORM_SUCCESS';
export const ACTION_FORM_FAIL = 'ACTION_FORM_FAIL';
