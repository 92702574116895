/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import { deleteAvailability, getAvailabilityDetail } from '../../../actions/availabilityActions';
import AvailabilityDetails from '../../availability/AvailabilityDetails';
import EditAvailabilityDetails from '../../availability/EditAvailabilityDetails';
import MessageContainer from '../../availability/MessageContainer';
import ModalContainer from '../ModalContainer';
const customStyles = {
  backgroundColor: 'white',
  color: 'darkblue',
  // Add more custom styles as needed
};
const DetailsMessagesAvailabilityModal = ({ isOpen, onClose, currentTripId, refreshAvailabilities, openSureModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error } = useSelector((state) => state?.tripRequestMessages);

  const [isLoading, setIsloading] = React.useState(false);
  const [editDetail, setEditDetail] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  // useEffect(() => {
  //   if (!error) return;
  //   onClose();
  // }, [error]);

  useEffect(() => {
    if (!isOpen) {
      setEditDetail(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!currentTripId || !isOpen) return;
    dispatch(getAvailabilityDetail(currentTripId));
  }, [currentTripId, isOpen]);

  const deleteHandler = async () => {
    dispatch(deleteAvailability(currentTripId));
    refreshAvailabilities();
    setShowDeleteModal(false);
    onClose();
    navigate('/availabilities');
  };
  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };
  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={onClose}
      size="100%"
      height={'650px'}
      overflowY="auto"
      maxWidth={error ? '650px' : '1220px'}
      bodyContent={
        <Box
          display={'flex'}
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
          justifyContent={{ base: 'items-center', lg: 'space-between' }}
          alignItems={{ base: 'center', lg: 'flex-start' }}
          gap={{ base: 2, lg: 4 }}
          width={'100%'}
        >
          {!error && <MessageContainer id={currentTripId} isOpen={isOpen} />}
          {editDetail ? (
            <EditAvailabilityDetails
              loading={isLoading}
              isOpen={isOpen}
              setEditDetail={setEditDetail}
              id={currentTripId}
              openModal={openSureModal}
            />
          ) : (
            <AvailabilityDetails
              id={currentTripId}
              isOpen={isOpen}
              onClose={onClose}
              style={{ zIndex: 500 }}
              postUpdateCallback={refreshAvailabilities}
              isLoading={isLoading}
              setIsloading={setIsloading}
              setEditDetail={setEditDetail}
              deleteHandler={deleteHandler}
              handleOpenDeleteModal={handleOpenDeleteModal}
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
            />
          )}
        </Box>
      }
    />
  );
};

export default DetailsMessagesAvailabilityModal;
