const styles = {
  global: {
    'html, body': {
      backgroundColor: 'gray.100', // Lighter background color
      color: 'gray.800', // Darker text color
    },
    svg: {
      cursor: 'pointer',
    },
    '.table': {
      position: 'relative',
    },
    '.tr': {
      display: 'flex',
      width: 'fit-content',
    },
    '.th, .td': { boxShadow: 'inset 0 0 0 1px #e1e1e1' }, // Lighter box shadow
    '.th': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'gray.600', // Darker text color
      padding: '0.5rem',
      fontWeight: 'bold',
      fontSize: 'xs',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    '.td > input': {
      m: '1',
      padding: '0.2rem',
      bg: 'transparent',
      maxW: '100%',
    },
    '.date-wrapper': {
      display: 'flex',
      alignItems: 'center',
      w: '100%',
      h: '100%',
    },
    '.resizer': {
      position: 'absolute',
      opacity: 0,
      top: 0,
      right: 0,
      h: '100%',
      w: '5px',
      bg: '#a0a0a0', // Lighter background color
      cursor: 'col-resize',
      userSelect: 'none',
      touchAction: 'none',
      borderRadius: '6px',
    },
    '.resizer.isResizing': {
      bg: '#b0b0b0', // Lighter background color
      opacity: 1,
    },
    '*:hover > .resizer': {
      opacity: 1,
    },
  },
};

export default styles;
