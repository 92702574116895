import React from 'react';

import { FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
function InputTextFormik({ field = {}, form, label, meta, ...props }) {
  const { name = '' } = field;
  const { touched, errors } = form;
  const error = touched[name] && errors[name];
  return (
    <FormControl mb={3}>
      {label && (
        <FormLabel htmlFor={name} ml={1} fontWeight={'medium'} color={error ? 'red' : 'gray.700'}>
          {label}
        </FormLabel>
      )}
      <Input {...field} {...meta} {...props} borderColor={error ? 'red.500' : 'gray.400'} mt={0} color={'gray.700'} />
      {error && (
        <Text color="red" fontWeight={'medium'} fontSize="sm" ml={1} mt={1}>
          {error}
        </Text>
      )}
    </FormControl>
  );
}

export default InputTextFormik;
