// CustomSelect.js
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import './../App.css';
// import moment from 'moment';

export const formattedData = (newDate) => {
  let date = new Date(newDate);
  return date?.getFullYear() + '-' + ('0' + (date?.getMonth() + 1)).slice(-2) + '-' + ('0' + date?.getDate()).slice(-2);
};

// eslint-disable-next-line react/prop-types
const TripTableDateSelect = ({ columnId, onSelectChange, emptyText = 'Seleccionar' }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendars, setShowCalendars] = useState(false);
  // const currentDate =  moment().add(2, 'days').toDate()
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const toggleCalendars = (e) => {
    e.preventDefault();
    setShowCalendars(!showCalendars);
  };
  const renderSelectedText = () => {
    if (startDate || endDate) {
      const formattedStartDate = startDate ? startDate?.toLocaleDateString() : '-';
      const formattedEndDate = endDate ? endDate?.toLocaleDateString() : '-';
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    return emptyText;
  };

  const clearDates = (e) => {
    e.preventDefault();

    setStartDate(null);
    setEndDate(null);
    setShowCalendars(false);
    onSelectChange(columnId, '', '');
  };

  useEffect(() => {
    if (!startDate || !endDate) return;
    // Fetch data when the selected option changes
    onSelectChange(columnId, startDate, endDate);
    setShowCalendars(false);
  }, [startDate, columnId, endDate]);

  return (
    <Box position={'relative'}>
      <button
        style={{
          border: '1px solid #A0AEC0',
          paddingTop: '0.4rem',
          paddingBottom: '0.4rem',
          paddingLeft: '.6rem',
          paddingRight: '.7rem',
          borderRadius: '0.3rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          width: emptyText !== 'Seleccionar' && '100%',
          color: emptyText === 'Fecha descarga' || emptyText === 'Fecha ' || emptyText === 'Fecha disponible'? '#807F7F' : '#000',
        }}
        type="button"
      >
        <button
          onClick={toggleCalendars}
          style={{
            margin: 0,
            padding: 0,
            width: 'auto',
          }}
        >
          {renderSelectedText()}
        </button>
        {startDate || endDate ? (
          <button onClick={clearDates}>
            <CloseIcon fontSize="sm" fontWeight="bold" pl={1} color="red.500" />
          </button>
        ) : null}
        {showCalendars ? (
          <ChevronUpIcon ml={1} onClick={toggleCalendars} />
        ) : (
          <ChevronDownIcon ml={1} onClick={toggleCalendars} />
        )}
      </button>
      {showCalendars && (
        <Box style={{ display: 'flex', position: 'absolute', zIndex: 9999 }}>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            inline
            className="date-picker"
            // maxDate={currentDate}
            style={{ zIndex: 1000 }}
          />
          <DatePicker
            style={{ zIndex: 1000 }}
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            inline
            // maxDate={currentDate}
            className="date-picker"
          />
        </Box>
      )}
    </Box>
  );
};

export default TripTableDateSelect;
