import {
  CHAT_VISUALIZER_FAIL,
  CHAT_VISUALIZER_GROUP_FAIL,
  CHAT_VISUALIZER_GROUP_LOADING,
  CHAT_VISUALIZER_GROUP_SUCCESS,
  CHAT_VISUALIZER_LOADING,
  CHAT_VISUALIZER_SUCCESS,
  MESSAGE_DETAILS_SUCCESS,
  // CHAT_VISUALIZER_REFRESH_LOADING,
} from '../constants/chatVisualizerConstants';
import httpClient from './utils/axiosInstance';

export const getChatGroupVisualizer = (id) => async (dispatch) => {
  try {
    dispatch({ type: CHAT_VISUALIZER_GROUP_LOADING });
    const { data } = await httpClient.get(`/api/v1/company/${id}/chats`);
    dispatch({
      type: CHAT_VISUALIZER_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHAT_VISUALIZER_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listMessageDetails = (id) => async (dispatch) => {
  try {
    const { data } = await httpClient.get(`/api/v1/message/history/${id}/`);
    dispatch({
      type: MESSAGE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error('error', error);
  }
};

export const listGroupMessages = (id, params) => async (dispatch) => {
  try {
    const { refresh, ...restParams } = params;
    if (!refresh) {
      dispatch({ type: CHAT_VISUALIZER_LOADING });
    }

    const { data } = await httpClient.get(`/api/v1/chats/${id}/messages/`, {
      params: { ...restParams },
    });

    dispatch({
      type: CHAT_VISUALIZER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error('error', error);
    dispatch({
      type: CHAT_VISUALIZER_FAIL,
      payload: 'Hubo un error!',
    });
  }
};
