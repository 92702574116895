/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Box, Spinner, Text } from '@chakra-ui/react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';

import { columns } from './columns';

const BidRequestSetTable = ({
  data,
  showRate,
  withMaxScreen,
  elementShow,
  setData,
  setWithMaxScreen,
  isLoading,
  handleEditModal,
  setGetBidRequests,
}) => {
  const table = useReactTable({
    data,
    columns: columns(showRate, handleEditModal, setGetBidRequests),
    // state: {
    //   columnFilters,
    // },
    initialState: {
      pageIndex: 0,
      pageSize: 10,
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    meta: {
      updateData: (rowIndex, columnId, value) =>
        setData((prev) =>
          prev.map((row, index) =>
            index === rowIndex
              ? {
                  ...prev[rowIndex],
                  [columnId]: value,
                }
              : row
          )
        ),
    },
  });
  useEffect(() => {
    table.setPageSize(elementShow);
    setWithMaxScreen(table?.getTotalSize());
  }, [elementShow, table]);
  return (
    <Box
      className="table"
      minHeight={'100%'}
      display={{ base: 'block' }}
      maxWidth={withMaxScreen}
      minWidth={'1300px'}
      mt={2}
      style={{
        borderLeft: '1px solid #e1e1e1', // Lighter border color
      }}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <Box key={headerGroup.id} width={withMaxScreen - 1} position={'sticky'} top={4} zIndex={2} bg="white">
          <Box
            className="tr"
            key={headerGroup.id}
            style={{
              borderTop: '1px solid #e1e1e1',
              borderRight: '1px solid #e1e1e1',
            }}
            w={withMaxScreen}
            bg="white"
          >
            {headerGroup.headers.map((header) => {
              return (
                <Box
                  className="th"
                  width={header.getSize()}
                  key={header.id}
                  style={{
                    // display: validateStickyHeader && "none",
                    textAlign: 'center',
                    background: 'white',
                    textWrap: 'wrap',
                  }}
                >
                  {header.column.columnDef.header}
                  {table.getRowModel().rows.length !== 0 && (
                    <Box
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ' '}`}
                    >
                      {' '}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      ))}
      {isLoading ? (
        <Box
          bg="white"
          p={0}
          py={10}
          height={420}
          m={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width={'99.99%'}
          style={{
            borderRight: '1px solid #e1e1e1', // Lighter border color
          }}
        >
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.700" size={'xl'} />
        </Box>
      ) : table.getRowModel().rows.length === 0 && !isLoading ? (
        <Box
          pt={4}
          height={240}
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="99.98%"
          position={'sticky'}
          bg="white"
          style={{
            borderRight: '1px solid #e1e1e1', // Lighter border color
            borderBottom: '1px solid #e1e1e1', // Lighter border color
          }}
        >
          <Text fontWeight="normal">No hay solicitudes de viaje</Text>
        </Box>
      ) : (
        <Box
          w="99.99%"
          position={'sticky'}
          bg="white"
          style={{
            borderRight: '1px solid #e1e1e1', // Lighter border color
          }}
        >
          {table.getRowModel().rows.map((row) => {
            return (
              <Box
                className="tr"
                key={row.id}
                _hover={{
                  borderBottom: '1px solid',
                  borderTop: '1px solid',
                  borderColor: 'gray.400',
                }}
                minH={'55px'}
                bg={'white'}
                // onMouseEnter={() => setHovered(row.original.id)}
                // onMouseLeave={() => setHovered(null)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Box
                      w={cell.column.getSize()}
                      border="none"
                      borderBottom="1px solid #E2E8F0"
                      key={cell.id}
                      // onClick={
                      //   cell.column.columnDef.isInteractive
                      //     ? null
                      //     : cell.column?.columnDef?.isOpenMessage
                      // }
                    >
                      <Box border="none" textAlign={'center'}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default BidRequestSetTable;
