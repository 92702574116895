import { useEffect, useState } from 'react';

import { Text, Tooltip } from '@chakra-ui/react';

const RegularCell = ({ getValue = () => {} }) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <Tooltip label={value} hasArrow>
      <Text
        value={value}
        variant="filled"
        size="sm"
        w="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        border="none" // Remove border
        outline="none" // Remove outline on focus
        fontFamily="Arial"
        m={0}
        fontSize="12px"
        verticalAlign={'middle'}
        ml={1}
      >
        {value || '-'}
      </Text>
    </Tooltip>
  );
};
export default RegularCell;
