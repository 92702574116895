import React from 'react';

import { Box, Skeleton } from '@chakra-ui/react';

const ConversationSkeleton = () => {
  return (
    <Box width="100%" display="flex" overflow="auto" p="10px" mt={0}>
      {Array(3)
        .fill()
        .map((_, index) => (
          <Box key={index} flex="none" width="100%" maxWidth="385px" marginRight="10px">
            <Skeleton height="600px" />
          </Box>
        ))}
    </Box>
  );
};

export default ConversationSkeleton;
