/* eslint-disable no-case-declarations */
/* eslint-disable no-inner-declarations */
import {
  MUNICIPALITY_LIST_REQUEST,
  MUNICIPALITY_LIST_SUCCESS,
  MUNICIPALITY_LIST_FAIL,
  MUNICIPALITY_DROPDOWN_LIST_REQUEST,
  MUNICIPALITY_DROPDOWN_LIST_SUCCESS,
  MUNICIPALITY_DROPDOWN_LIST_FAIL,
  MUNICIPALITY_DETAILS_REQUEST,
  MUNICIPALITY_DETAILS_SUCCESS,
  MUNICIPALITY_DETAILS_FAIL,
  MUNICIPALITY_CREATE_REQUEST,
  MUNICIPALITY_CREATE_SUCCESS,
  MUNICIPALITY_CREATE_FAIL,
  MUNICIPALITY_DELETE_REQUEST,
  MUNICIPALITY_DELETE_SUCCESS,
  MUNICIPALITY_DELETE_FAIL,
  MUNICIPALITY_DROPDOWN_FAIL,
  MUNICIPALITY_DROPDOWN_SUCCESS,
  MUNICIPALITY_DROPDOWN_REQUEST,
} from '../constants/municipalityConstants';

export const municipalityListReducer = (state = { municipalities: [] }, action) => {
  switch (action.type) {
    case MUNICIPALITY_LIST_REQUEST:
      return { loading: true, municipalities: [] };

    case MUNICIPALITY_LIST_SUCCESS:
      return { loading: false, municipalities: action.payload };

    case MUNICIPALITY_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const municipalityDropdownListReducer = (state = { municipalities: [] }, action) => {
  switch (action.type) {
    case MUNICIPALITY_DROPDOWN_LIST_REQUEST:
      return { loading: true, municipalities: [] };

    case MUNICIPALITY_DROPDOWN_LIST_SUCCESS:
      return { loading: false, municipalities: action.payload };

    case MUNICIPALITY_DROPDOWN_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const municipalityDetailsReducer = (state = { municipality: {} }, action) => {
  switch (action.type) {
    case MUNICIPALITY_DETAILS_REQUEST:
      return { loading: true, ...state };

    case MUNICIPALITY_DETAILS_SUCCESS:
      function groupByDepthLevelList(data) {
        let result = [];
        data.forEach((item) => {
          let depth = item.depth_level;
          if (!result[depth]) {
            result[depth] = [];
          }
          result[depth].push(item);
        });
        return result;
      }
      const levels = groupByDepthLevelList(action.payload.actions);
      action.payload.actionLevels = levels;
      return { loading: false, municipality: action.payload };

    case MUNICIPALITY_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const municipalityCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MUNICIPALITY_CREATE_REQUEST:
      return { loading: true };

    case MUNICIPALITY_CREATE_SUCCESS:
      return { loading: false, municipalityDetails: action.payload };

    case MUNICIPALITY_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const municipalityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MUNICIPALITY_DELETE_REQUEST:
      return { loading: true };

    case MUNICIPALITY_DELETE_SUCCESS:
      return { loading: false, success: true };

    case MUNICIPALITY_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const municipalityDropdownReducer = (state = { municipalities: [] }, action) => {
  switch (action.type) {
    case MUNICIPALITY_DROPDOWN_REQUEST:
      return { loading: true, municipalities: [] };

    case MUNICIPALITY_DROPDOWN_SUCCESS:
      return { loading: false, municipalities: action.payload };

    case MUNICIPALITY_DROPDOWN_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};