import React from 'react';

import { Button, Flex, Input, Text } from '@chakra-ui/react';

const UploadButton = ({ handleFileInputChange, isLoadingUploaded, fileName }) => {
  return (
    <Flex mb={0} alignItems="center" gap={2}>
      <Input
        id="fileInput"
        type="file"
        color="teal"
        placeholder="custom placeholder"
        onChange={handleFileInputChange}
        style={{ position: 'absolute', left: '-9999px' }}
      />
      <Button
        variant="outline"
        colorScheme="blue"
        size="sm"
        onClick={() => {
          document.getElementById('fileInput').click();
        }}
        isLoading={isLoadingUploaded}
      >
        Seleccionar archivo
      </Button>
      {fileName && (
        <Flex alignItems={'center'}>
          <Text fontWeight={'normal'} fontSize="sm" mt={4} color="gray.800">
            {fileName}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default UploadButton;
