/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Box, Spinner, Text, Input } from '@chakra-ui/react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  flexRender,
} from '@tanstack/react-table';

import { columns } from './columnsTripTable';

const BidGroupTokenTable = ({
  data,
  withMaxScreen,
  elementShow,
  setData,
  // setElementShow,
  setWithMaxScreen,
  onChangeInputRate,
  rateValue,
  handleAcceptedBidRequest,
  isLoading,
  isAcceptedLoading,
  showRate,
}) => {
  const dataTable = columns(handleAcceptedBidRequest, isAcceptedLoading);
  //TODO: for the momnet there is a bug with show rate is true
  const filterTable = showRate ? dataTable : dataTable.filter((column) => column.accessorKey !== 'rate');

  const table = useReactTable({
    data,
    columns: filterTable,
    // state: {
    //   columnFilters,
    // },
    initialState: {
      pageIndex: 0,
      pageSize: 10,
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: 'onChange',
    meta: {
      updateData: (rowIndex, columnId, value) =>
        setData((prev) =>
          prev.map((row, index) =>
            index === rowIndex
              ? {
                  ...prev[rowIndex],
                  [columnId]: value,
                }
              : row
          )
        ),
    },
  });
  useEffect(() => {
    table.setPageSize(elementShow);
    setWithMaxScreen(table?.getTotalSize());
  }, [elementShow]);

  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        position: 'relative',
      }}
      maxWidth={withMaxScreen}
      mt={10}
    >
      <Box
        className="table"
        minHeight={'100%'}
        display={{ base: 'none', xl: 'block' }}
        maxWidth={withMaxScreen}
        minWidth={'1300px'}
        style={{
          borderLeft: '1px solid #e1e1e1', // Lighter border color
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <Box key={headerGroup.id} width={withMaxScreen - 1} position={'sticky'} top={0} zIndex={2} bg="white">
            <Box
              className="tr"
              key={headerGroup.id}
              style={{
                borderTop: '1px solid #e1e1e1',
                borderRight: '1px solid #e1e1e1',
              }}
              w={withMaxScreen}
              bg="white"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <Box
                    className="th"
                    width={header?.getSize()}
                    key={header?.id}
                    style={{
                      // display: validateStickyHeader && "none",
                      textAlign: 'center',
                      background: 'white',
                      textWrap: 'wrap',
                    }}
                  >
                    {header?.column?.columnDef?.header}
                    {table?.getRowModel()?.rows?.length !== 0 && (
                      <Box
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ' '}`}
                      >
                        {' '}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
        {isLoading ? (
          <Box
            bg="white"
            p={0}
            py={10}
            height={420}
            m={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={'99.99%'}
            style={{
              borderRight: '1px solid #e1e1e1', // Lighter border color
            }}
          >
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.700" size={'xl'} />
          </Box>
        ) : table.getRowModel().rows.length === 0 && !isLoading ? (
          <Box
            pt={4}
            height={240}
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="99.98%"
            position={'sticky'}
            bg="white"
            style={{
              borderRight: '1px solid #e1e1e1', // Lighter border color
              borderBottom: '1px solid #e1e1e1', // Lighter border color
            }}
          >
            <Text fontWeight="normal">No hay solicitudes de viaje</Text>
          </Box>
        ) : (
          <Box
            w={withMaxScreen}
            position={'sticky'}
            bg="white"
            style={{
              borderRight: '1px solid #e1e1e1', // Lighter border color
            }}
          >
            {table.getRowModel().rows.map((row) => {
              return (
                <Box
                  className="tr"
                  key={row.id}
                  _hover={{
                    borderBottom: '1px solid',
                    borderTop: '1px solid',
                    borderColor: 'gray.400',
                  }}
                  minH={'55px'}
                  bg={'white'}
                  // onMouseEnter={() => setHovered(row.original.id)}
                  // onMouseLeave={() => setHovered(null)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Box
                        w={cell.column.getSize()}
                        border="none"
                        borderBottom="1px solid #E2E8F0"
                        key={cell.id}
                        // onClick={
                        //   cell.column.columnDef.isInteractive
                        //     ? null
                        //     : cell.column?.columnDef?.isOpenMessage
                        // }
                      >
                        <Box border="none">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          {cell.id.includes('rate') && (
                            <Box>
                              <Input
                                placeholder="Tarifa"
                                name="rate"
                                type="number"
                                id={row?.index}
                                size="sm"
                                value={rateValue?.[cell?.id?.charAt(0)] || row?.original?.rate || ''}
                                onChange={(e) => onChangeInputRate(cell?.id?.charAt(0), e)}
                                borderRadius={5}
                                isDisabled={row?.original?.button_settings?.disabled}
                                color="gray.900"
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BidGroupTokenTable;
