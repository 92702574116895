import React from 'react';

import { FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
function InputText({ type, label, error, onChange, value, placeholder, name, cy, props }) {
  return (
    <FormControl>
      <FormLabel fontSize={14} letterSpacing={0} color={`${error ? 'red' : ' gray.700'}`}>
        {label}
      </FormLabel>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        width="100%"
        borderColor={error ? 'red.500' : 'gray.400'}
        cy={cy}
        bg="white"
        {...props}
        // pl={10}
        // sx={{
        //   '&::placeholder': {
        //     paddingLeft: '14px', // Ajusta este valor según necesites
        //   },
        // }}
      />
      {error && (
        <Text color="red" fontSize={12} fontWeight="normal" ml={1}>
          {error}
        </Text>
      )}
    </FormControl>
  );
}

export default InputText;
