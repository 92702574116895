import React, { useState } from 'react';

import { Box } from '@chakra-ui/react';

import ChatResponseBox from '../ChatResponseBox';
import FullChatComponent from '../FullChatComponent';

const ConversationDetail = ({
  messages,
  priorMessages,
  laterMessages,
  isEndOfConversation,
  loading,
  chatProviderId,
  isCurrentChat = true,
  laterMessagesRef,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <Box
      boxShadow={'md'}
      overflowX="auto"
      maxW="100%"
      w={'95%'}
      h={500}
      border="1px solid #ccc"
      borderTop={'none'}
      borderRadiusBottom="md"
      p={4}
      mb={4}
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <FullChatComponent
        handleImageClick={handleImageClick}
        handleCloseModal={handleCloseModal}
        loading={loading}
        messages={messages}
        priorMessages={priorMessages}
        laterMessages={laterMessages}
        isEndOfConversation={isEndOfConversation}
        selectedImage={selectedImage}
        laterMessagesRef={laterMessagesRef}
      />
      <ChatResponseBox id={chatProviderId} isCurrentChat={isCurrentChat} convesations={true} />
    </Box>
  );
};

export default ConversationDetail;
