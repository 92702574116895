import {
  BID_REQUEST_FAIL_SET,
  BID_REQUEST_FAIL_STATUS,
  BID_REQUEST_REQUEST_SET,
  BID_REQUEST_REQUEST_STATUS,
  BID_REQUEST_SUCCESS_SET,
  BID_REQUEST_SUCCESS_STATUS,
} from '../constants/bidRequestConstants';

export const bidRequestSetReducer = (state = { bidRequestsSet: [] }, action) => {
  switch (action.type) {
    case BID_REQUEST_REQUEST_SET:
      return { loading: true, bidRequestsSet: [] };

    case BID_REQUEST_SUCCESS_SET:
      return {
        loading: false,
        bidRequestsSet: action.payload,
        pagination: action.payload.pagination,
      };

    case BID_REQUEST_FAIL_SET:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const bidRequestStatusesReducer = (state = { bidRequest: {} }, action) => {
  switch (action.type) {
    case BID_REQUEST_REQUEST_STATUS:
      return { loading: true, bidRequestsStatus: {} };

    case BID_REQUEST_SUCCESS_STATUS:
      return {
        loading: false,
        bidRequestsStatus: action.payload,
      };

    case BID_REQUEST_FAIL_STATUS:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
