import React, { useRef, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Heading, Button, Text, Flex, Icon, Grid, Tooltip } from '@chakra-ui/react';

import { bidSetpRequestsDownload } from '../actions/bidRequestSetAction';
import BidRequestSetTable from '../components/bidRequest/bidRequestSetTable';
import EditResponseModal from '../components/bidRequest/EditResponseModal';
import LegendsTable from '../components/bidRequest/LegendsTable';
import SuppliersGrid from '../components/bidRequest/SuppliersGrid';
import UploadButton from '../components/utils/buttons/UploadButton';
import TableSkeleton from '../components/utils/skeletons.jsx/TableSkeleton';

// import GridBidRequestSet from "../components/bidRequest/GridBidRequestSet";

const BidRequestSetTemplate = ({
  data,
  suppliers,
  setData,
  isLoading,
  isLoadingUploaded,
  fileName,
  handleFileInputChange,
  isOpen,
  setIsOpen,
  getBidRequests,
  setGetBidRequests,
}) => {
  const dispatch = useDispatch();

  const [elementShow, setElementShow] = useState(40);
  const [withMaxScreen, setWithMaxScreen] = useState(1375);
  const scrollRefs = useRef([]); // Array de referencias para los scroll de las tablas
  const [isScrolledRight, setIsScrolledRight] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCurrentId, setCurrentId] = useState(null);
  const handleScroll = (index, direction) => {
    const ref = scrollRefs.current[index];
    if (ref) {
      if (direction === 'right') {
        ref.scrollLeft += 500;
      } else if (direction === 'left') {
        ref.scrollLeft -= 500;
      }
      setIsScrolledRight((prev) => ({
        ...prev,
        [index]: direction === 'right',
      }));
      return;
    } else {
      console.error(`Referencia no encontrada para el índice: ${index}`);
    }
  };
  const handleEditModal = (id) => {
    setCurrentId(id);
    setIsOpen(!isOpen);
  };
  const onCloseUpdateModal = () => {
    setGetBidRequests(null);
    setCurrentId(null);
    setIsOpen(false);
  };

  if (isLoading) {
    return <TableSkeleton />;
  }
  return (
    <Box w="100%" mt={4}>
      <Box
        display={{ base: 'flex' }}
        justifyContent={{ base: 'space-between' }}
        alignItems={'center'}
        m={0}
        p={0}
        pt={0}
        mb={4}
        mt={{ base: 3, xl: 5 }}
        maxWidth={{ base: 'auto', xl: withMaxScreen }}
      >
        <Box w="100%" mt={0}>
          <Heading
            as="h1"
            size={{ base: 'sm', md: 'md' }}
            pt={0}
            pl={2}
            color="gray.700"
            display={{ base: 'block', xl: 'hidden' }}
          >
            Solicitudes de Viaje
          </Heading>
          <Flex mb={0}>
            <UploadButton
              handleFileInputChange={handleFileInputChange}
              isLoadingUploaded={isLoadingUploaded}
              fileName={fileName}
            />
            <Box ml="auto" display="flex" w="100%" justifyContent="flex-end" m={0} p={0}>
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => {
                  if (!isDownloading) {
                    setIsDownloading(true);
                    dispatch(bidSetpRequestsDownload()).then(() => {
                      setIsDownloading(false);
                    });
                  }
                }}
                leftIcon={<Icon as={FiDownload} />}
                size={{ base: 'sm' }}
                isLoading={isDownloading}
              >
                Descargar Excel
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>

      {suppliers && Object.keys(suppliers)?.length > 0 && data?.length > 0 ? (
        data?.map((item, index) => {
          let headers = [];
          headers = Object.values(suppliers).map((supplier) => ({
            header: supplier.name,
          }));
          return (
            <Box
              key={index}
              display="flex"
              alignItems={'start'}
              gap={2}
              ref={(el) => (scrollRefs.current[index] = el)}
              overflowX="auto"
              sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
              mt={1}
            >
              <Box
                m={0}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                flexDirection="column"
                style={{
                  whiteSpace: 'nowrap',
                  position: 'relative',
                }}
                maxWidth={withMaxScreen}
              >
                <Box m={0} w="10%">
                  <Button
                    boxShadow={'md'}
                    cursor="pointer"
                    w="100%"
                    size="lg"
                    h="100%"
                    variant={'solid'}
                    colorScheme="blue"
                    onClick={() => handleScroll(index, isScrolledRight[index] ? 'left' : 'right')}
                  >
                    {isScrolledRight[index] ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </Button>
                </Box>
                <BidRequestSetTable
                  data={item?.bid_requests}
                  showRate={item?.show_rate}
                  suppliers={suppliers}
                  withMaxScreen={withMaxScreen}
                  elementShow={elementShow}
                  setData={setData}
                  setElementShow={setElementShow}
                  setWithMaxScreen={setWithMaxScreen}
                  isLoading={isLoading}
                  handleEditModal={handleEditModal}
                  setGetBidRequests={setGetBidRequests}
                />
              </Box>
              <Box
                marginX={5}
                height={'100%'}
                style={{
                  whiteSpace: 'nowrap',
                  position: 'relative',
                }}
              >
                <LegendsTable />
                <Box
                  mt={-3}
                  bg="white"
                  // borderLeft={"1px dotted #e1e1e1"}
                  // borderBottom="1px solid #e1e1e1 "
                  height={'100%'}
                >
                  <Grid
                    templateColumns={`repeat(${headers?.length}, 1fr)`}
                    gap={2}
                    height="100%"
                    w="100%"
                    m={0}
                    mt={'9px'}
                    p={0}
                    bg="white"
                    px={2}
                    py={'16.5px'}
                    border="1px solid #e1e1e1 "
                    borderBottom={'none'}
                    textWrap="wrap"
                  >
                    {headers?.map(({ header, index }) => (
                      <Tooltip label={header} fontSize="lg" cursor={'pointer'} hasArrow key={index}>
                        <Text
                          fontSize="12px"
                          textTransform="uppercase"
                          fontWeight="bold"
                          color="gray.600"
                          mb={0}
                          maxW={'80px'}
                          textWrap="wrap"
                          isTruncated
                          style={{
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                          }}
                          height={'100%'}
                        >
                          {header}
                        </Text>
                      </Tooltip>
                    ))}
                  </Grid>
                  {item?.bid_requests?.map((bidItem, index) => {
                    return (
                      <>
                        <Box borderBottom={'1px solid  #e1e1e1'} m={0} p={0} />
                        <SuppliersGrid
                          key={index}
                          suppliers={suppliers}
                          showRate={item?.show_rate}
                          bidItem={bidItem?.item}
                        />
                      </>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box
          pt={4}
          height={240}
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="99.98%"
          position={'sticky'}
          bg="white"
          style={{
            borderRight: '1px solid #e1e1e1', // Lighter border color
            borderBottom: '1px solid #e1e1e1', // Lighter border color
          }}
        >
          <Text fontWeight="normal">No hay solicitudes de viaje</Text>
        </Box>
      )}
      <EditResponseModal
        isOpen={isOpen}
        onClose={onCloseUpdateModal}
        isCurrentId={isCurrentId}
        getBidRequests={getBidRequests}
      />
    </Box>
  );
};

export default BidRequestSetTemplate;
