import React from 'react';
import Select from 'react-select';

import { CloseIcon } from '@chakra-ui/icons';
import { FormControl, FormLabel, Text, Box, IconButton } from '@chakra-ui/react';

function InputSelectFormik({
  field,
  form,
  label,
  options,
  meta,
  isCleaning,
  handleCleaning,
  isEditing = false,
  ...props
}) {
  const { name } = field;
  const { touched, errors, setFieldValue } = form;
  const error = touched[name] && errors[name];

  const selectOptions = options?.map((option) => ({
    value: option.id,
    label: option.verbose_name,
  }));

  const handleChange = (selectedOption) => {
    setFieldValue(name, selectedOption ? selectedOption.value : '');
  };

  const selectedOption = selectOptions?.find((option) => option.value === field.value) || null;

  const handleClear = () => {
    setFieldValue(name, '');
    if (isCleaning) {
      handleCleaning(name, selectedOption);
    }
  };

  return (
    <FormControl mb={3} position="relative">
      {label && (
        <FormLabel htmlFor={name} ml={1} fontWeight={'medium'} color={error ? 'red' : 'gray.700'}>
          {label}
        </FormLabel>
      )}
      <Box display="flex" alignItems="center">
        <Box flex="1">
          <Select
            classNamePrefix="react-select"
            options={selectOptions}
            onChange={handleChange}
            name={name}
            {...props}
            {...meta}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: error ? 'red' : '#A0AEC0',
                color: '#2D3748',
                fontWeight: '400',
                paddingLeft: '1.5rem',
                zIndex: 10,
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9000,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9000,
              }),
              option: (provided, state) => ({
                ...provided,
                color: '#2D3748',
                fontWeight: '400',
                backgroundColor: state.isSelected ? '#EDF2F7' : 'white',
                zIndex: 9000,
              }),
            }}
            menuPortalTarget={document.body}
            value={
              isEditing
                ? selectOptions?.find((option) => option.value === field.value)
                : selectOptions?.find((option) => option.value === field.value) || null
            }
            defaultValue={props?.defaultValue}
          />
        </Box>

        {selectedOption && isCleaning && (
          <IconButton
            icon={<CloseIcon fontSize="xs" color={'gray.400'} />}
            onClick={() => handleClear(setFieldValue, 'origin')}
            size="sm"
            variant="ghost"
          />
        )}
      </Box>
      {error && (
        <Text color="red" fontWeight={'medium'} fontSize="sm" ml={1} mt={1}>
          {error}
        </Text>
      )}
    </FormControl>
  );
}

export default InputSelectFormik;
