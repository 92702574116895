/* eslint-disable react/jsx-no-comment-textnodes */

import { EditIcon } from '@chakra-ui/icons';
import { Text, Box, Flex } from '@chakra-ui/react';

import { colorStatus } from './GridBidRequestSet';

const TextCell = ({ value }) => {
  return (
    <Text
      alignItems={'center'}
      value={value}
      variant="filled"
      size="sm"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      border="none" // Remove border
      outline="none" // Remove outline on focus
      fontFamily="Arial"
      m={0}
      fontSize="12px"
      verticalAlign={'middle'}
      w="100%"
    >
      {value || '-'}
    </Text>
  );
};

export const columns = (showRate, handleEditModal, setGetBidRequests) => {
  const columns = [
    {
      accessorKey: 'client', // client_name_tag
      header: 'Cliente',
      isInteractive: false,
      size: 160,
      cell: ({ row }) => {
        return (
          <Flex alignItems={'center'} gap={2}>
            <button
              onClick={() => {
                const client = row?.original?.item?.content_object?.client;
                const { supplier_response, is_accepted, rate } = row?.original || {};
                setGetBidRequests({ supplier_response, is_accepted, rate, client });
                handleEditModal(row?.original?.id);
                return;
              }}
            >
              <EditIcon color="gray.500" w={4} h={4} />
            </button>
            <TextCell value={row?.original?.item?.content_object?.client} />
          </Flex>
        );
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },

    {
      accessorKey: 'origin',
      header: 'Origen',
      isInteractive: false,
      size: 170,
      cell: ({ row }) => {
        return <TextCell value={row?.original?.item?.content_object?.origin} />;
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      accessorKey: 'destination',
      header: 'Destino',
      isInteractive: false,
      size: 170,
      cell: ({ row }) => {
        return <TextCell value={row?.original?.item?.content_object?.destination} />;
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      accessorKey: 'unloading_date',
      header: 'Fecha descarga',
      isInteractive: false,
      size: 150,
      cell: ({ row }) => {
        return (
          <>
            <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
              {row?.original?.item?.content_object?.unloading_date || '-'}
            </Text>
            <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
              {row?.original?.item?.content_object?.unloading_time?.split(':').slice(0, 2).join(':')}
            </Text>
          </>
        );
      },
    },
    {
      accessorKey: 'folio_number',
      header: 'N. confirmación',
      isInteractive: false,
      size: 130,
      cell: ({ row }) => {
        return <TextCell value={row?.original?.item?.content_object?.folio_number} />;
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },

    {
      accessorKey: 'loading_date',
      header: 'Fecha Carga',
      isInteractive: false,
      size: 150,
      cell: ({ row }) => {
        return (
          <>
            <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
              {row?.original?.item?.content_object?.loading_date || '-'}
            </Text>
            <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
              {row?.original?.item?.content_object?.loading_time?.split(':').slice(0, 2).join(':')}
            </Text>
          </>
        );
      },
    },

    {
      accessorKey: 'unit_type',
      header: 'Unidad',
      isInteractive: false,
      size: 150,
      cell: ({ row }) => {
        return <TextCell value={row?.original?.item?.content_object?.unit_type} />;
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },

    {
      accessorKey: 'supplier',
      header: 'Asignado a',
      isInteractive: false,
      size: 170,
      cell: ({ row }) => {
        return <TextCell value={row?.original?.item?.content_object?.supplier} />;
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      accessorKey: 'response_summary',
      header: 'Resumen respuestas',
      isInteractive: false,
      size: 150,
      cell: ({ row }) => {
        let rate = null;
        for (let keyItem in row.original.item?.status.details) {
          let item = row.original.item?.status.details[keyItem];
          if (item.rate !== null && item.rate !== undefined) {
            if (rate == null) {
              rate = item.rate;
            } else if (rate > item.rate) {
              rate = item.rate;
            }
          }
        }

        if (!rate) {
          rate = '';
        } else {
          const formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
          });
          rate = formatter.format(rate);
        }

        return (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              w={'80px'}
              h={'45px'}
              borderRadius={'8px'}
              fontSize={'xs'}
              color={'#000000'}
              fontWeight={'normal'}
              bg={colorStatus(row?.original?.item?.status?.summary?.status_box?.color)}
            >
              {showRate ? rate : row?.original?.item?.status?.details?.length}
            </Box>
          </Box>
        );
      },
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
  ];

  // if (suppliers) {
  //   Object.keys(suppliers).forEach(key => {
  //     let supplier = suppliers[key];
  //     columns.push({
  //       accessorKey: `supplier_${supplier.id}`,
  //       header: supplier.name,
  //       isInteractive: false,
  //       size: 170,
  //       cell: ({ row }) => {
  //         let color = row?.original?.status?.details?.find(
  //           (detail) => detail.supplier.id === supplier.id
  //         )?.status_box?.color;
  //         let rate = row?.original?.status?.details?.find(
  //           (detail) => detail.supplier.id === supplier.id
  //         )?.rate;

  //         if (!rate) {
  //             rate = "";
  //         } else {
  //           const formatter = new Intl.NumberFormat('es-MX', {
  //               style: 'currency',
  //               currency: 'MXN'
  //           });
  //           rate = formatter.format(rate);
  //         }

  //         return (
  //             <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
  //               <Box
  //                 display={"flex"}
  //                 alignItems={"center"}
  //                 justifyContent={"center"}
  //                 borderRadius={"8px"}
  //                 w={"80px"}
  //                 h={"60px"}
  //                   fontSize={"xs"}
  //                 color={"#000000"}
  //                 fontWeight={"bold"}
  //                 bg={colorStatus(
  //                   color
  //                 )}
  //               >
  //                 {showRate ? rate : ''}
  //               </Box>
  //             </Box>
  //         );
  //       },
  //       enableColumnFilter: true,
  //       filterFn: "includesString",
  //     });
  //   });
  // }

  return columns;
};
