import toast from 'react-hot-toast';

import {
  TRIP_AVAILABILITY_DELETE_FAIL,
  TRIP_AVAILABILITY_DELETE_REQUEST,
  TRIP_AVAILABILITY_DELETE_SUCCESS,
  TRIP_AVAILABILITY_DETAILS_FAIL,
  TRIP_AVAILABILITY_DETAILS_REQUEST,
  TRIP_AVAILABILITY_DETAILS_SUCCESS,
  TRIP_AVAILABILITY_LIST_FAIL,
  TRIP_AVAILABILITY_LIST_REFRESH,
  TRIP_AVAILABILITY_LIST_REQUEST,
  TRIP_AVAILABILITY_LIST_SUCCESS,
  TRIP_AVAILABILITY_UPDATE_FAIL,
  TRIP_AVAILABILITY_UPDATE_REQUEST,
  TRIP_AVAILABILITY_UPDATE_SUCCESS,
} from '../constants/availabilityConstants';
import {
  TRIP_REQUEST_MESSAGES_CLEAN,
  TRIP_REQUEST_MESSAGES_FAIL,
  TRIP_REQUEST_MESSAGES_REQUEST,
  TRIP_REQUEST_MESSAGES_SUCCESS,
} from '../constants/tripRequestConstants';
import httpClient from './utils/axiosInstance';

export const listAvailabilities = (params) => async (dispatch) => {
  try {
    if (params.refresh) {
      dispatch({ type: TRIP_AVAILABILITY_LIST_REFRESH });
    } else {
      dispatch({ type: TRIP_AVAILABILITY_LIST_REQUEST });
    }

    const { data } = await httpClient.get(`/api/v1/trip-availabilities/`, {
      params: {
        ...params,
      },
    });

    try {
      var pagination = {
        totalItems: data.total_items,
        totalPages: data.total_pages,
        pageSize: data.page_size,
        currentPage: data.current,
        previousPageURL: data.previous,
        nextPageURL: data.next,
      };
    } catch (error) {
      console.error('Error processing pagination data:', error);

      // Provide default values or handle the absence of properties
      pagination = {
        totalItems: 0,
        totalPages: 0,
        pageSize: 0,
        currentPage: 0,
        previousPageURL: null,
        nextPageURL: null,
      };
    }

    dispatch({
      type: TRIP_AVAILABILITY_LIST_SUCCESS,
      payload: {
        results: data.results,
        pagination: pagination,
        filters: data.filters,
      },
    });
  } catch (error) {
    console.error('error', error.response.data);
    dispatch({
      type: TRIP_AVAILABILITY_LIST_FAIL,
      payload: error.response && error.response.data?.detail ? error.response.data?.detail : error.message,
    });
  }
};

export const getAvailabilityDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_AVAILABILITY_DETAILS_REQUEST });
    const { data } = await httpClient.get(`/api/v1/trip-availabilities/${id}`);

    dispatch({
      type: TRIP_AVAILABILITY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_AVAILABILITY_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const unreadAvailability = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_AVAILABILITY_DETAILS_REQUEST });

    const { data } = await httpClient.put(`/api/v1/trip-availabilities/${id}/unread/`);
    dispatch({
      type: TRIP_AVAILABILITY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_AVAILABILITY_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const deleteAvailability = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_AVAILABILITY_DELETE_REQUEST });

    await httpClient.delete(`/api/v1/trip-availabilities/${id}`);
    dispatch({
      type: TRIP_AVAILABILITY_DELETE_SUCCESS,
    });
    toast.success('Viaje eliminado exitosamente');
  } catch (error) {
    dispatch({
      type: TRIP_AVAILABILITY_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listAvailabilitiesMessages = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_REQUEST_MESSAGES_REQUEST });

    const { data } = await httpClient.get(`/api/v1/trip-availabilities/${id}/messages`);

    dispatch({
      type: TRIP_REQUEST_MESSAGES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRIP_REQUEST_MESSAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.response,
    });
  }
};

export const cleanAvailabilitiesMessages = () => (dispatch) => {
  dispatch({ type: TRIP_REQUEST_MESSAGES_CLEAN });
};

export const updateAvailability = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({ type: TRIP_AVAILABILITY_UPDATE_REQUEST });

    await httpClient.put(`/api/v1/trip-availabilities/${id}`, updatedData);
    dispatch({
      type: TRIP_AVAILABILITY_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TRIP_AVAILABILITY_UPDATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
