import React from 'react';

import { DeleteIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  ModalCloseButton,
  Icon,
  Text,
} from '@chakra-ui/react';

const SureModal = ({
  show,
  onHide,
  onConfirm,
  isLoading,
  title = '¿Estás seguro de eliminar esta solicitud?',
  description = ' Ten en cuenta que esta acción no se puede revertir.',
}) => {
  const customStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 'none',
      marginBottom: -20,
    },
  };

  return (
    <Modal isOpen={show} onClose={onHide} size="md" isCentered>
      <ModalOverlay />
      <ModalContent zIndex="1500" position="absolute">
        <ModalCloseButton />
        <ModalHeader style={customStyles.container}>
          <Icon as={DeleteIcon} w={20} h={20} color="gray.100" mt={10} mb={2} />
          <Text fontSize="md" color="gray.800" fontWeight="bold" textAlign={'center'} mt={2} cy="delete-modal-title">
            {title}
          </Text>
          <Text fontSize="sm" color="gray.800" fontWeight="normal" mt={-2}>
            {description}
          </Text>
        </ModalHeader>
        <ModalFooter justifyContent="space-between">
          <Button variant="secondary" onClick={onHide} cy="delete-modal-cancel">
            Cancelar
          </Button>
          <Button
            variant="ghost"
            bg="red.500"
            textColor="white"
            onClick={onConfirm}
            isLoading={isLoading}
            cy="delete-modal-confirm"
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SureModal;
