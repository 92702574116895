/* eslint-disable no-console */
// import React from 'react';

// const AvailabilityStatusesModal = () => {
//   return <div>AvailabilityStatusesModal</div>;
// };

// export default AvailabilityStatusesModal;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Field, Form, Formik } from 'formik';

import { Box, Button, Flex, FormControl, Select, Text } from '@chakra-ui/react';

import ModalContainer from '../ModalContainer';

const AvailabilityStatusesModal = (props) => {
  const { isOpen, onClose, id } = props;

  const customStyles = {
    backgroundColor: 'white',
    color: 'darkblue',
    // Add more custom styles as needed
  };
  const options = [
    {
      id: 'available',
      label: 'disponible',
    },
    {
      id: 'unavailable',
      label: 'No disponible',
    },
    {
      id: 'taken_by_us',
      label: 'Tomado internamente',
    },
    {
      id: 'taken_by_other',
      label: 'Tomado por otra compañía',
    },
  ];

  const onSubmit = (values) => {
    console.log(values, id);
  };
  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="xl"
      maxWidth="500px"
      bodyContent={
        <Box>
          <Text fontSize="xl" fontWeight="bold" cy="statuses-title" color={'gray.900'}>
            Actualizar estado de disponibilidad
          </Text>
          <Formik onSubmit={onSubmit} enableReinitialize>
            <Form style={{ margin: 0, padding: 0 }}>
              <FormControl id="status" color="gray.900">
                <Field name="status" as={Select}>
                  <option value="1">Seleccionar</option>
                  {options
                    ? options.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))
                    : null}
                </Field>
              </FormControl>
            </Form>
          </Formik>
        </Box>
      }
      footerContent={
        <Flex justifyContent={'space-between'} w={'100%'}>
          <Button colorScheme="gray" onClick={onClose} size={{ base: 'xs', sm: 'sm', lg: 'md' }}>
            Cancelar
          </Button>
          <Button colorScheme="blue" type="submit" size={{ base: 'xs', sm: 'sm', lg: 'md' }}>
            Actualizar
          </Button>
        </Flex>
      }
    />
  );
};

export default AvailabilityStatusesModal;
