import React from 'react';

function BackgroundIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="647" height="536" fill="none" viewBox="0 0 647 536">
      <path
        fill="#89C5CC"
        fillRule="evenodd"
        d="M636.87 142.272c-.501-.385-1.691-.521-2.244-.637-2.301-.478-4.671-.687-7.017-.687-7.233.012-14.583.762-21.695 2.059-4.517.823-9.976 3.595-14.468 2.089-7.826-2.622-15.735-2.493-23.161 1.251-6.788 3.423-11.941 9.926-14.949 16.951-.454 1.061-2.295 4.965-2.816 7.597a31.466 31.466 0 00-.629 1.629 84.884 84.884 0 00-1.495 4.741c-.196.679-.686 2.178-.104 2.468a46.258 46.258 0 00-2.441 5.61c-1.998 5.61-4.183 11.764-4.885 17.71a29.203 29.203 0 00-.028 6.621c.119 1.091.07 3.283.77 4.113 1.486 1.758 2.248-.704 2.081-1.877-1.279-8.94 1.191-17.335 4.118-25.738 1.893-5.434 5.003-10.406 8.487-14.907 2.82-3.645 5.844-6.514 9.709-8.93 4.727-2.955 9.781-5.344 14.787-7.74 3.293-1.576 6.59-3.11 9.975-4.468.753-.303 1.937-1.078 2.772-1.081 1.764-.006 4.266 1.369 5.97 1.88 5.608 1.683 11.286 3.838 17.081 4.698 3.717.552 7.2-.07 10.675-1.377.356-.133.952-.477 1.681-.967 2.581-1.048 5.023-2.367 7.148-4.271 1.224-1.096 2.337-2.513 3.35-3.811.303-.387.437-.831.442-1.254.229-.126.382-.275.416-.448.253-1.276-2.053-1.299-3.53-1.224z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M49.323 248.685a107.339 107.339 0 00-4.168 3.741c-1.035.983-2.866 2.159-3.238 3.648-.386 1.547.833 2.996 1.796 3.931 5.093 4.947 10.828 8.291 17.884 8.848 10.867.856 20.323-2.074 29.751-7.838 6.623-4.05 11.009-10.039 11.967-18.127 7.887 3.461 15.515 8.231 21.997 14.085 3.198 2.889 5.953 6.089 8.501 9.636 1.632 2.273 3.21 4.582 4.675 6.98.637 1.042 1.284 2.89 2.336 3.489 1.234.705 2.726-.4 2.665-1.848-.052-1.206-1.284-2.604-1.862-3.59a132.66 132.66 0 00-2.953-4.855c-1.656-2.578-3.368-5.118-5.235-7.53-4.197-5.419-9.536-9.895-15.071-13.712-10.618-7.324-22.271-11.937-35.19-12.328-7.945-.241-14.628 2.625-21.421 6.904-4.23 2.664-8.596 5.285-12.434 8.566z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#C1DEE2"
        fillRule="evenodd"
        d="M152.372 213.263c-8.279-9-16.586-17.889-25.835-25.816-5.451-4.67-11.195-8.917-16.821-13.345.648.6 1.285 1.218 1.906 1.851-1.756-1.259-4.054-1.97-5.916-3.136-2.077-1.299-4.062-2.786-6.01-4.284-3.8-2.924-7.544-5.904-11.451-8.675-4.353-3.088-8.864-5.941-13.291-8.912-3.758-2.522-7.561-5.282-11.58-7.344-2.191-1.123-4.387-2.454-6.587-3.627-.736-.392-8.206-5.372-8.187-5.387.413-.328 4.172 1.536 4.753 1.787 1.698.733 3.322 1.608 4.955 2.482-.896-1.866-1.606-3.828-1.992-5.891-.119-.638-1.075-5.275-.586-5.664.245-.195 2.628 8.103 2.75 8.901.1.648.085 1.581.285 2.194.325.992.308.692 1.023 1.251 1.31 1.023 3.048 1.662 4.466 2.517.764.451 3.13 2.237 4.556 2.747 4.313 2.211 8.684 5.614 12.782 8.439a32.926 32.926 0 01-2.49-6.704c-.143-.555-1.674-5.535-1.303-5.831.418-.333 3.337 7.274 3.51 7.993.321 1.338.388 2.826.79 4.129.44 1.428 1.818 1.934 3.06 2.767 4.566 3.063 8.976 6.318 13.312 9.722 4.731-8.884-.724-19.612-5.322-27.067-3.314-5.372-7.775-9.978-12.487-13.999-6.287-5.367-13.48-9.654-20.665-13.576-8.371-4.569-17.42-7.401-26.826-8.708-4.92-.685-9.953-.665-14.922-.445-2.428.106-4.758.445-7.084 1.282a22.506 22.506 0 00-3.05 1.385c-.75.404-2.565.83-2.997 1.433-3.551 4.88-5.368 9.743-5.09 15.875.262 5.805 1.998 11.613 4.355 16.791 2.624 5.765 6 10.998 10.414 15.39 3.368 3.351 7.134 6.268 10.874 9.146 13.304 10.237 30.172 19.14 47.403 16.852 5.578-.739 10.883-2.799 15.84-5.598 1.169-.659 2.33-1.326 3.433-2.104.743-.525 2.73-2.891 3.478-2.977 1.026-.117 3.233 2.083 4.195 2.753 7.309 5.094 14.426 10.464 21.393 16.051 7.414 5.943 14.463 12.292 21.306 18.938 2.909 2.827 5.801 5.661 8.627 8.583.658.68 2.266 3.184 3.459 3.297.754.072 1.263-.609 1.156-1.345-.187-1.282-2.741-3.198-3.589-4.121z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#69A1AC"
        fillRule="evenodd"
        d="M628.178 178.329c-3.206-.739-6.747-.683-10.013-.622-17.519.252-34.722 9.009-45.917 23-9.531 11.911-12.152 26.721-14.036 41.691-.186 1.486-.266 2.963-.371 4.453-.023.32-.056.604-.093.873-.74.699-1.129 1.514-.69 2.409-1.631 3.769-3.011 7.633-4.196 11.62-3.485 11.732-7.029 23.688-4.846 36.025.51-6.789 1.584-13.732 2.867-20.41.073-.383.153-.763.236-1.142l.025-.12a46.465 46.465 0 011.763-5.981l.023-.063c.095-.257.195-.513.295-.769a53.41 53.41 0 01.641-1.582c.076-.179.149-.36.219-.542 1.046-2.706 1.487-5.755 2.424-8.554.224-.636.458-1.27.703-1.902l.044-.11a84.728 84.728 0 012.172-5.034c.421-.335.646-1.164 1.01-1.917.074-.149.662-1.138.837-1.709.456-.932.911-1.864 1.37-2.795a.499.499 0 01-.041.016c.46-.894.921-1.783 1.379-2.67l.304-.584.492-.931c1.535-2.9 3.162-5.74 4.852-8.546.603-1.002 2.043-2.438 2.32-3.562.01-.043.012-.081.019-.121 3.666-5.437 7.887-10.452 12.949-14.425-.612.698-1.913 3.068-2.256 3.537-1.053 1.437-2.195 2.774-3.341 4.127-2.055 2.425-4.059 4.927-5.841 7.572-3.258 4.838-6.068 9.929-8.726 15.123-.025.03-.053.059-.076.09-1.336 1.738-2.177 4.365-3.137 6.353 3.803-1.86 8.01-3.072 11.914-4.724 3.894-1.646 7.681-3.481 11.338-5.634 4.86-2.862 9.902-6.093 14.494-9.75 5.262-3.446 9.684-8.19 13.587-13.168 4.618-5.89 8.851-11.83 11.468-18.982 1.287-3.514 2.298-7.178 2.898-10.886.026-.161.059-.336.096-.518a36.563 36.563 0 001.622-3.929c.731-2.124 2.458-4.46-.781-5.207z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#89C5CC"
        fillRule="evenodd"
        d="M84.799 203.213c-1.004-1.149-2.132-2.185-3.457-2.925-.335-.186-2.83-1.437-2.992-1.163-.379.639 6.147 3.72 6.449 4.088zm11.829-7.672c-.009.048.856 1.598 1.092 2.074a55.65 55.65 0 011.4 3.081c-.645-1.827-1.308-3.522-2.492-5.155zm-60.494 12.615c-.833.283-1.835-.834-.754-1.109.874-.22.831-.272 1.224-1.016.319-.604.515-1.34.795-1.974.593-1.344 1.267-2.626 2.093-3.827 1.77-2.575 3.928-4.793 6.348-6.663 4.223-3.262 8.959-6.245 13.982-7.743 7.986-2.382 17.076-2.529 25.076-.877 4.954 1.022 9.481 2.801 12.77 7.013 1.645 2.104 2.936 4.569 3.5 7.284.147.71.119 1.499.303 2.194.284 1.069.159.784 1.343.991 1.292.228 2.583.459 3.873.695 3.38.618 6.78 1.273 10.073 2.274 1.084.33 2.144.733 3.18 1.2.783.354 3.901 1.464 4.28 2.538.631 1.788-1.482.978-2.14.588a25.396 25.396 0 00-1.764-.952 28.043 28.043 0 00-4.689-1.761c-1.737-.49-3.509-.856-5.276-1.224a193.097 193.097 0 00-4.954-.95c-.92-.164-1.839-.325-2.76-.485-.315-.056-.868-.342-.827.089-1.935-.394-4.027-.337-5.997-.445-2.414-.133-4.836-.242-7.258-.163-1.747.058-3.495.179-5.233.427a25.377 25.377 0 00-3.01.624c-.548.148-2.706.461-3.004.964.128-.216 2.51-.006 2.813-.019 1.212-.048 2.415-.175 3.623-.317a53.267 53.267 0 016.733-.383c-.008.417-1.544 1.16-1.92 1.436-.84.62-1.646 1.295-2.397 2.036-1.323 1.306-2.908 2.923-3.822 4.47.27-.457 1.6-1.049 2.072-1.398a27.846 27.846 0 002.2-1.789 28.917 28.917 0 001.845-1.871c.594-.648 1.4-1.997 2.181-2.429.047-.027.388.257.58.161.196-.098.439-.584.487-.604.727-.296 2.107.073 2.872.11 1.98.097 3.96.223 5.939.338.614.036 1.135-.112 1.367.365.247.51-.206 1.849-.334 2.404-.478 2.061-1.325 3.908-2.66 5.505-2.499 2.991-5.582 4.984-9.063 6.318-13.335 5.108-27.523 7.154-40.854 1.84-5.52-2.2-13.831-6.131-12.836-13.865z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M646.728 448.803c9.073 64.84-210.944 107.133-400.318 77.618C57.037 496.906-29.839 381.645 60.527 320.23c90.366-61.415 577.129 63.733 586.201 128.573z"
        clipRule="evenodd"
        opacity="0.6"
      ></path>
      <path
        fill="#F2F2F2"
        d="M259.659 23.822H125.218c-4.154 0-7.521 3.555-7.521 7.94v283.883c0 4.385 3.367 7.94 7.521 7.94h134.441c4.154 0 7.521-3.555 7.521-7.94V31.763c0-4.386-3.367-7.94-7.521-7.94z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M191.968 304.726c7.788 0 14.102-6.666 14.102-14.889 0-8.223-6.314-14.889-14.102-14.889s-14.102 6.666-14.102 14.889c0 8.223 6.314 14.889 14.102 14.889zM179.746 43.674c1.039 0 1.881-.889 1.881-1.985 0-1.097-.842-1.985-1.881-1.985-1.039 0-1.88.888-1.88 1.985 0 1.096.841 1.985 1.88 1.985z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M204.19 39.704h-15.982c-1.039 0-1.88.889-1.88 1.985s.841 1.985 1.88 1.985h15.982c1.039 0 1.881-.889 1.881-1.985s-.842-1.985-1.881-1.985zM250.257 51.615H134.619c-2.077 0-3.76 1.777-3.76 3.97v45.659c0 2.193 1.683 3.971 3.76 3.971h115.638c2.077 0 3.761-1.778 3.761-3.971V55.585c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask0_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="23"
        x="167"
        y="67"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M236.155 67.695h-68.63v21.44h68.63v-21.44z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask0_334_780)">
        <path d="M233.335 67.695h-62.99c-1.558 0-2.82 1.333-2.82 2.978v2.084c0 1.645 1.262 2.978 2.82 2.978h62.99c1.558 0 2.82-1.333 2.82-2.978v-2.084c0-1.645-1.262-2.978-2.82-2.978zM210.771 81.095h-40.426c-1.558 0-2.82 1.333-2.82 2.978v2.084c0 1.645 1.262 2.978 2.82 2.978h40.426c1.558 0 2.821-1.333 2.821-2.978v-2.084c0-1.645-1.263-2.978-2.821-2.978z"></path>
      </g>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M150.602 90.326c6.23 0 11.281-5.333 11.281-11.911 0-6.578-5.051-11.911-11.281-11.911-6.231 0-11.282 5.333-11.282 11.91 0 6.579 5.051 11.912 11.282 11.912z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M250.257 113.156H134.619c-2.077 0-3.76 1.777-3.76 3.97v33.748c0 2.193 1.683 3.97 3.76 3.97h115.638c2.077 0 3.761-1.777 3.761-3.97v-33.748c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask1_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="18"
        x="167"
        y="125"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M236.155 125.662h-68.63v16.676h68.63v-16.676z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask1_334_780)">
        <path d="M233.335 125.662h-62.99c-1.558 0-2.82 1.333-2.82 2.978v.298c0 1.644 1.262 2.978 2.82 2.978h62.99c1.558 0 2.82-1.334 2.82-2.978v-.298c0-1.645-1.262-2.978-2.82-2.978zM210.771 136.084h-40.426c-1.558 0-2.82 1.334-2.82 2.978v.298c0 1.645 1.262 2.978 2.82 2.978h40.426c1.558 0 2.821-1.333 2.821-2.978v-.298c0-1.644-1.263-2.978-2.821-2.978z"></path>
      </g>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M150.602 145.911c6.23 0 11.281-5.333 11.281-11.911 0-6.578-5.051-11.911-11.281-11.911-6.231 0-11.282 5.333-11.282 11.911 0 6.578 5.051 11.911 11.282 11.911z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M250.257 162.785H134.619c-2.077 0-3.76 1.778-3.76 3.971v33.748c0 2.193 1.683 3.97 3.76 3.97h115.638c2.077 0 3.761-1.777 3.761-3.97v-33.748c0-2.193-1.684-3.971-3.761-3.971z"
      ></path>
      <mask
        id="mask2_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="17"
        x="167"
        y="175"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M236.155 175.292h-68.63v16.675h68.63v-16.675z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask2_334_780)">
        <path d="M233.335 175.292h-62.99c-1.558 0-2.82 1.333-2.82 2.978v.297c0 1.645 1.262 2.978 2.82 2.978h62.99c1.558 0 2.82-1.333 2.82-2.978v-.297c0-1.645-1.262-2.978-2.82-2.978zM210.771 185.714h-40.426c-1.558 0-2.82 1.333-2.82 2.978v.298c0 1.644 1.262 2.977 2.82 2.977h40.426c1.558 0 2.821-1.333 2.821-2.977v-.298c0-1.645-1.263-2.978-2.821-2.978z"></path>
      </g>
      <path
        fill="#AFB9C5"
        fillRule="evenodd"
        d="M150.602 195.541c6.23 0 11.281-5.333 11.281-11.911 0-6.578-5.051-11.911-11.281-11.911-6.231 0-11.282 5.333-11.282 11.911 0 6.578 5.051 11.911 11.282 11.911z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M250.257 212.415H134.619c-2.077 0-3.76 1.777-3.76 3.97v33.748c0 2.193 1.683 3.971 3.76 3.971h115.638c2.077 0 3.761-1.778 3.761-3.971v-33.748c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask3_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="18"
        x="167"
        y="224"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M236.155 224.922h-68.63v16.675h68.63v-16.675z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask3_334_780)">
        <path d="M233.335 224.922h-62.99c-1.558 0-2.82 1.333-2.82 2.977v.298c0 1.645 1.262 2.978 2.82 2.978h62.99c1.558 0 2.82-1.333 2.82-2.978v-.298c0-1.644-1.262-2.977-2.82-2.977zM210.771 235.344h-40.426c-1.558 0-2.82 1.333-2.82 2.978v.297c0 1.645 1.262 2.978 2.82 2.978h40.426c1.558 0 2.821-1.333 2.821-2.978v-.297c0-1.645-1.263-2.978-2.821-2.978z"></path>
      </g>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M150.602 245.17c6.23 0 11.281-5.333 11.281-11.911 0-6.578-5.051-11.911-11.281-11.911-6.231 0-11.282 5.333-11.282 11.911 0 6.578 5.051 11.911 11.282 11.911z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#89C5CC"
        fillRule="evenodd"
        d="M146.033 266.015a69.32 69.32 0 00-3.732.094c-.948.053-2.397-.155-3.199.47-.834.648-.777 1.974-.646 2.911.688 4.955 2.368 9.348 5.883 12.859 5.413 5.405 11.617 8.151 18.959 9.374 5.157.86 9.918-.333 13.714-4.206 2.775 5.436 4.879 11.451 5.935 17.522.52 2.995.679 5.955.588 9.005a82.176 82.176 0 01-.359 5.848c-.086.845-.494 2.123-.181 2.922.367.937 1.607 1.028 2.164.228.463-.665.379-1.972.474-2.759.158-1.308.303-2.618.408-3.932.17-2.127.295-4.26.286-6.396-.021-4.797-1.032-9.613-2.414-14.167-2.651-8.738-6.952-16.503-13.642-22.596-4.114-3.746-8.823-5.262-14.164-6.077-3.326-.508-6.706-1.1-10.074-1.1z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#89C5CC"
        fillRule="evenodd"
        d="M165.241 300.756c-13.443 0-22.497 11.438-26.777 23.328-1.614 4.48-1.992 9.401-2.615 14.106-.199 1.511-.125 3.812-.706 5.157-.21.488-.694.663-.462 1.328.14.402.413.649.826.728.845.165 2.041-.807 2.766-1.191 6.013-3.184 9.964-8.107 13.186-14.13 1.999-3.733 4.026-7.609 5.213-11.708.724-2.504 1.147-5.14 1.395-7.738.098-1.023.192-2.071.186-3.099-.006-1.021-.456-1.561-.779-2.45 4.485-1.792 9.549-2.281 14.092-.423 4.203 1.718 7.694 5.038 9.648 9.21.508 1.084.9 2.227 1.185 3.394.187.765.195 1.755.542 2.446.839 1.676 2.604.556 2.435-1.003-.473-4.349-2.829-8.715-5.841-11.739-3.799-3.813-8.947-6.216-14.294-6.216z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#DDE3E9"
        d="M321.709 49.63H187.268c-4.154 0-7.521 3.555-7.521 7.94v283.882c0 4.386 3.367 7.941 7.521 7.941h134.441c4.154 0 7.521-3.555 7.521-7.941V57.57c0-4.385-3.367-7.94-7.521-7.94z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M254.018 330.534c7.788 0 14.102-6.667 14.102-14.889 0-8.223-6.314-14.889-14.102-14.889s-14.102 6.666-14.102 14.889c0 8.222 6.314 14.889 14.102 14.889zM241.796 69.481c1.039 0 1.881-.888 1.881-1.985 0-1.096-.842-1.985-1.881-1.985-1.039 0-1.88.889-1.88 1.985 0 1.097.841 1.986 1.88 1.986z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M266.24 65.511h-15.982c-1.038 0-1.88.889-1.88 1.985 0 1.097.842 1.986 1.88 1.986h15.982c1.039 0 1.881-.89 1.881-1.986s-.842-1.985-1.881-1.985zM312.307 77.422H196.669c-2.077 0-3.76 1.778-3.76 3.97v45.66c0 2.193 1.683 3.97 3.76 3.97h115.638c2.077 0 3.761-1.777 3.761-3.97v-45.66c0-2.192-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask4_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="22"
        x="201"
        y="93"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M270.001 93.502H201.37v21.44h68.631v-21.44z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask4_334_780)">
        <path d="M267.18 93.502h-62.99c-1.557 0-2.82 1.334-2.82 2.978v2.084c0 1.645 1.263 2.978 2.82 2.978h62.99c1.558 0 2.821-1.333 2.821-2.978V96.48c0-1.645-1.263-2.978-2.821-2.978zM244.617 106.902H204.19c-1.557 0-2.82 1.334-2.82 2.978v2.085c0 1.644 1.263 2.977 2.82 2.977h40.427c1.557 0 2.82-1.333 2.82-2.977v-2.085c0-1.644-1.263-2.978-2.82-2.978z"></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M302.906 111.17c3.635 0 6.581-3.11 6.581-6.948 0-3.837-2.946-6.948-6.581-6.948-3.635 0-6.581 3.111-6.581 6.948 0 3.838 2.946 6.948 6.581 6.948z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M302.906 107.2c1.558 0 2.82-1.333 2.82-2.978 0-1.644-1.262-2.977-2.82-2.977-1.558 0-2.821 1.333-2.821 2.977 0 1.645 1.263 2.978 2.821 2.978z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M312.307 138.963H196.669c-2.077 0-3.76 1.778-3.76 3.97v33.748c0 2.193 1.683 3.971 3.76 3.971h115.638c2.077 0 3.761-1.778 3.761-3.971v-33.748c0-2.192-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask5_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="18"
        x="229"
        y="151"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M298.205 151.47h-68.631v16.675h68.631V151.47z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask5_334_780)">
        <path d="M295.385 151.47h-62.99c-1.558 0-2.821 1.333-2.821 2.977v.298c0 1.645 1.263 2.978 2.821 2.978h62.99c1.557 0 2.82-1.333 2.82-2.978v-.298c0-1.644-1.263-2.977-2.82-2.977zM272.821 161.892h-40.426c-1.558 0-2.821 1.333-2.821 2.978v.297c0 1.645 1.263 2.978 2.821 2.978h40.426c1.558 0 2.82-1.333 2.82-2.978v-.297c0-1.645-1.262-2.978-2.82-2.978z"></path>
      </g>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M212.652 171.719c6.23 0 11.281-5.334 11.281-11.912 0-6.577-5.051-11.911-11.281-11.911-6.231 0-11.282 5.334-11.282 11.911 0 6.578 5.051 11.912 11.282 11.912z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M312.307 188.593H196.669c-2.077 0-3.76 1.777-3.76 3.97v33.748c0 2.193 1.683 3.971 3.76 3.971h115.638c2.077 0 3.761-1.778 3.761-3.971v-33.748c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask6_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="17"
        x="201"
        y="201"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M270.001 201.099H201.37v16.676h68.631v-16.676z"></path>
      </mask>
      <g mask="url(#mask6_334_780)">
        <path
          fill="#DDE3E9"
          d="M267.18 201.099h-62.99c-1.557 0-2.82 1.334-2.82 2.978v.298c0 1.644 1.263 2.978 2.82 2.978h62.99c1.558 0 2.821-1.334 2.821-2.978v-.298c0-1.644-1.263-2.978-2.821-2.978z"
        ></path>
        <path
          fill="#AFB9C5"
          d="M244.617 211.522H204.19c-1.557 0-2.82 1.333-2.82 2.977v.298c0 1.645 1.263 2.978 2.82 2.978h40.427c1.557 0 2.82-1.333 2.82-2.978v-.298c0-1.644-1.263-2.977-2.82-2.977z"
        ></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M302.906 216.385c3.635 0 6.581-3.111 6.581-6.948s-2.946-6.948-6.581-6.948c-3.635 0-6.581 3.111-6.581 6.948s2.946 6.948 6.581 6.948z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M302.906 212.415c1.558 0 2.82-1.333 2.82-2.978 0-1.645-1.262-2.978-2.82-2.978-1.558 0-2.821 1.333-2.821 2.978 0 1.645 1.263 2.978 2.821 2.978z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M312.307 238.222H196.669c-2.077 0-3.76 1.778-3.76 3.971v33.748c0 2.193 1.683 3.97 3.76 3.97h115.638c2.077 0 3.761-1.777 3.761-3.97v-33.748c0-2.193-1.684-3.971-3.761-3.971z"
      ></path>
      <mask
        id="mask7_334_780"
        style={{ maskType: 'luminance' }}
        width="70"
        height="18"
        x="229"
        y="250"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M298.205 250.729h-68.631v16.676h68.631v-16.676z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask7_334_780)">
        <path d="M295.385 250.729h-62.99c-1.558 0-2.821 1.333-2.821 2.978v.297c0 1.645 1.263 2.978 2.821 2.978h62.99c1.557 0 2.82-1.333 2.82-2.978v-.297c0-1.645-1.263-2.978-2.82-2.978zM272.821 261.151h-40.426c-1.558 0-2.821 1.333-2.821 2.978v.298c0 1.644 1.263 2.978 2.821 2.978h40.426c1.558 0 2.82-1.334 2.82-2.978v-.298c0-1.645-1.262-2.978-2.82-2.978z"></path>
      </g>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M212.652 270.978c6.23 0 11.281-5.333 11.281-11.911 0-6.578-5.051-11.911-11.281-11.911-6.231 0-11.282 5.333-11.282 11.911 0 6.578 5.051 11.911 11.282 11.911z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        d="M549.225 74.445H249.317c-4.154 0-7.521 3.555-7.521 7.94v327.556c0 4.385 3.367 7.941 7.521 7.941h299.908c4.153 0 7.521-3.556 7.521-7.941V82.385c0-4.385-3.368-7.94-7.521-7.94z"
      ></path>
      <path
        fill="#fff"
        d="M534.182 117.126h-50.768c-4.154 0-7.521 3.555-7.521 7.941v64.518c0 4.386 3.367 7.941 7.521 7.941h50.768c4.154 0 7.521-3.555 7.521-7.941v-64.518c0-4.386-3.367-7.941-7.521-7.941z"
      ></path>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M508.798 163.778c8.308 0 15.042-7.11 15.042-15.882 0-8.771-6.734-15.881-15.042-15.881s-15.042 7.11-15.042 15.881c0 8.772 6.734 15.882 15.042 15.882z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#DDE3E9"
        d="M529.481 167.748h-41.366c-1.558 0-2.821 1.333-2.821 2.978 0 1.645 1.263 2.978 2.821 2.978h41.366c1.558 0 2.821-1.333 2.821-2.978 0-1.645-1.263-2.978-2.821-2.978zM521.961 177.674h-26.325c-1.557 0-2.82 1.333-2.82 2.978 0 1.644 1.263 2.978 2.82 2.978h26.325c1.557 0 2.82-1.334 2.82-2.978 0-1.645-1.263-2.978-2.82-2.978z"
      ></path>
      <path
        fill="#C5CFD6"
        d="M241.796 82.385c0-4.385 3.368-7.94 7.521-7.94h299.908c4.153 0 7.521 3.555 7.521 7.94v18.86h-314.95v-18.86z"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M254.018 92.311c2.077 0 3.761-1.778 3.761-3.97 0-2.193-1.684-3.97-3.761-3.97s-3.761 1.777-3.761 3.97c0 2.192 1.684 3.97 3.761 3.97zM265.3 92.311c2.077 0 3.76-1.778 3.76-3.97 0-2.193-1.683-3.97-3.76-3.97s-3.761 1.777-3.761 3.97c0 2.192 1.684 3.97 3.761 3.97zM276.582 92.311c2.076 0 3.76-1.778 3.76-3.97 0-2.193-1.684-3.97-3.76-3.97-2.077 0-3.761 1.777-3.761 3.97 0 2.192 1.684 3.97 3.761 3.97z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M534.182 205.467h-50.768c-4.154 0-7.521 3.555-7.521 7.941v176.681c0 4.386 3.367 7.941 7.521 7.941h50.768c4.154 0 7.521-3.555 7.521-7.941V213.408c0-4.386-3.367-7.941-7.521-7.941z"
      ></path>
      <path
        fill="#DDE3E9"
        d="M521.02 223.333h-24.443c-2.077 0-3.761 1.778-3.761 3.971v17.866c0 2.193 1.684 3.971 3.761 3.971h24.443c2.077 0 3.761-1.778 3.761-3.971v-17.866c0-2.193-1.684-3.971-3.761-3.971z"
      ></path>
      <path
        fill="#AFB9C5"
        d="M521.02 257.082h-24.443c-2.077 0-3.761 1.777-3.761 3.97v16.874c0 2.193 1.684 3.97 3.761 3.97h24.443c2.077 0 3.761-1.777 3.761-3.97v-16.874c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <path
        fill="#DDE3E9"
        d="M521.02 289.837h-24.443c-2.077 0-3.761 1.778-3.761 3.971v16.874c0 2.192 1.684 3.97 3.761 3.97h24.443c2.077 0 3.761-1.778 3.761-3.97v-16.874c0-2.193-1.684-3.971-3.761-3.971zM521.02 355.348h-24.443c-2.077 0-3.761 1.778-3.761 3.971v16.874c0 2.192 1.684 3.97 3.761 3.97h24.443c2.077 0 3.761-1.778 3.761-3.97v-16.874c0-2.193-1.684-3.971-3.761-3.971z"
      ></path>
      <path
        fill="#C5CFD6"
        d="M521.02 322.593h-24.443c-2.077 0-3.761 1.777-3.761 3.97v16.874c0 2.193 1.684 3.97 3.761 3.97h24.443c2.077 0 3.761-1.777 3.761-3.97v-16.874c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <path
        fill="#fff"
        d="M464.611 118.119H260.599c-2.077 0-3.761 1.777-3.761 3.97V160.8c0 2.193 1.684 3.97 3.761 3.97h204.012c2.077 0 3.761-1.777 3.761-3.97v-38.711c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask8_334_780"
        style={{ maskType: 'luminance' }}
        width="158"
        height="19"
        x="265"
        y="132"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M422.305 132.114H265.3v18.661h157.005v-18.661z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask8_334_780)">
        <path d="M419.484 132.114H268.12c-1.557 0-2.82 1.333-2.82 2.978v1.042c0 1.645 1.263 2.978 2.82 2.978h151.364c1.558 0 2.821-1.333 2.821-2.978v-1.042c0-1.645-1.263-2.978-2.821-2.978zM367.866 143.777H268.12c-1.557 0-2.82 1.333-2.82 2.978v1.042c0 1.645 1.263 2.978 2.82 2.978h99.746c1.558 0 2.821-1.333 2.821-2.978v-1.042c0-1.645-1.263-2.978-2.821-2.978z"></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M445.765 148.393c3.635 0 6.581-3.111 6.581-6.949 0-3.837-2.946-6.948-6.581-6.948-3.634 0-6.581 3.111-6.581 6.948 0 3.838 2.947 6.949 6.581 6.949z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M445.765 144.422c1.558 0 2.821-1.333 2.821-2.977 0-1.645-1.263-2.978-2.821-2.978-1.558 0-2.82 1.333-2.82 2.978 0 1.644 1.262 2.977 2.82 2.977z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M464.611 172.711H260.599c-2.077 0-3.761 1.778-3.761 3.971v30.77c0 2.193 1.684 3.97 3.761 3.97h204.012c2.077 0 3.761-1.777 3.761-3.97v-30.77c0-2.193-1.684-3.971-3.761-3.971z"
      ></path>
      <mask
        id="mask9_334_780"
        style={{ maskType: 'luminance' }}
        width="158"
        height="16"
        x="265"
        y="184"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M422.305 184.325H265.3v15.484h157.005v-15.484z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask9_334_780)">
        <path d="M419.555 184.325H268.05c-1.519 0-2.75 1.299-2.75 2.903 0 1.603 1.231 2.903 2.75 2.903h151.505c1.519 0 2.75-1.3 2.75-2.903 0-1.604-1.231-2.903-2.75-2.903zM367.937 194.002H268.05c-1.519 0-2.75 1.3-2.75 2.904 0 1.603 1.231 2.903 2.75 2.903h99.887c1.519 0 2.75-1.3 2.75-2.903 0-1.604-1.231-2.904-2.75-2.904z"></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M445.765 199.015c3.635 0 6.581-3.111 6.581-6.948 0-3.838-2.946-6.948-6.581-6.948-3.634 0-6.581 3.11-6.581 6.948 0 3.837 2.947 6.948 6.581 6.948z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M445.765 195.044c1.558 0 2.821-1.333 2.821-2.977 0-1.645-1.263-2.978-2.821-2.978-1.558 0-2.82 1.333-2.82 2.978 0 1.644 1.262 2.977 2.82 2.977z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M464.611 219.363H260.599c-2.077 0-3.761 1.778-3.761 3.97v30.771c0 2.193 1.684 3.97 3.761 3.97h204.012c2.077 0 3.761-1.777 3.761-3.97v-30.771c0-2.192-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask10_334_780"
        style={{ maskType: 'luminance' }}
        width="158"
        height="17"
        x="265"
        y="230"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M422.305 230.976H265.3v15.485h157.005v-15.485z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask10_334_780)">
        <path d="M419.555 230.976H268.05c-1.519 0-2.75 1.3-2.75 2.904 0 1.603 1.231 2.903 2.75 2.903h151.505c1.519 0 2.75-1.3 2.75-2.903 0-1.604-1.231-2.904-2.75-2.904zM367.937 240.654H268.05c-1.519 0-2.75 1.3-2.75 2.903 0 1.604 1.231 2.904 2.75 2.904h99.887c1.519 0 2.75-1.3 2.75-2.904 0-1.603-1.231-2.903-2.75-2.903z"></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M445.765 245.667c3.635 0 6.581-3.111 6.581-6.948 0-3.838-2.946-6.949-6.581-6.949-3.634 0-6.581 3.111-6.581 6.949 0 3.837 2.947 6.948 6.581 6.948z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#AFB9C5"
        fillRule="evenodd"
        d="M445.765 241.696c1.558 0 2.821-1.333 2.821-2.977 0-1.645-1.263-2.978-2.821-2.978-1.558 0-2.82 1.333-2.82 2.978 0 1.644 1.262 2.977 2.82 2.977z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M464.611 359.319H260.599c-2.077 0-3.761 1.777-3.761 3.97v30.77c0 2.193 1.684 3.971 3.761 3.971h204.012c2.077 0 3.761-1.778 3.761-3.971v-30.77c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask11_334_780"
        style={{ maskType: 'luminance' }}
        width="158"
        height="17"
        x="265"
        y="370"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M422.305 370.932H265.3v15.484h157.005v-15.484z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask11_334_780)">
        <path d="M419.555 370.932H268.05c-1.519 0-2.75 1.3-2.75 2.903 0 1.604 1.231 2.904 2.75 2.904h151.505c1.519 0 2.75-1.3 2.75-2.904 0-1.603-1.231-2.903-2.75-2.903zM367.937 380.61H268.05c-1.519 0-2.75 1.3-2.75 2.903 0 1.604 1.231 2.903 2.75 2.903h99.887c1.519 0 2.75-1.299 2.75-2.903 0-1.603-1.231-2.903-2.75-2.903z"></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M445.765 385.622c3.635 0 6.581-3.11 6.581-6.948 0-3.837-2.946-6.948-6.581-6.948-3.634 0-6.581 3.111-6.581 6.948 0 3.838 2.947 6.948 6.581 6.948z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M445.765 381.652c1.558 0 2.821-1.333 2.821-2.978 0-1.644-1.263-2.978-2.821-2.978-1.558 0-2.82 1.334-2.82 2.978 0 1.645 1.262 2.978 2.82 2.978z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M464.611 312.667H260.599c-2.077 0-3.761 1.777-3.761 3.97v30.771c0 2.192 1.684 3.97 3.761 3.97h204.012c2.077 0 3.761-1.778 3.761-3.97v-30.771c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask12_334_780"
        style={{ maskType: 'luminance' }}
        width="158"
        height="16"
        x="265"
        y="324"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M422.305 324.28H265.3v15.485h157.005V324.28z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask12_334_780)">
        <path d="M419.555 324.28H268.05c-1.519 0-2.75 1.3-2.75 2.903 0 1.604 1.231 2.904 2.75 2.904h151.505c1.519 0 2.75-1.3 2.75-2.904 0-1.603-1.231-2.903-2.75-2.903zM367.937 333.958H268.05c-1.519 0-2.75 1.3-2.75 2.903 0 1.604 1.231 2.903 2.75 2.903h99.887c1.519 0 2.75-1.299 2.75-2.903 0-1.603-1.231-2.903-2.75-2.903z"></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M445.765 338.97c3.635 0 6.581-3.11 6.581-6.948 0-3.837-2.946-6.948-6.581-6.948-3.634 0-6.581 3.111-6.581 6.948 0 3.838 2.947 6.948 6.581 6.948z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M445.765 335c1.558 0 2.821-1.333 2.821-2.978 0-1.644-1.263-2.977-2.821-2.977-1.558 0-2.82 1.333-2.82 2.977 0 1.645 1.262 2.978 2.82 2.978z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M464.611 266.015H260.599c-2.077 0-3.761 1.777-3.761 3.97v30.771c0 2.192 1.684 3.97 3.761 3.97h204.012c2.077 0 3.761-1.778 3.761-3.97v-30.771c0-2.193-1.684-3.97-3.761-3.97z"
      ></path>
      <mask
        id="mask13_334_780"
        style={{ maskType: 'luminance' }}
        width="158"
        height="17"
        x="265"
        y="277"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M422.305 277.628H265.3v15.485h157.005v-15.485z"></path>
      </mask>
      <g fill="#DDE3E9" mask="url(#mask13_334_780)">
        <path d="M419.555 277.628H268.05c-1.519 0-2.75 1.3-2.75 2.904 0 1.603 1.231 2.903 2.75 2.903h151.505c1.519 0 2.75-1.3 2.75-2.903 0-1.604-1.231-2.904-2.75-2.904zM367.937 287.306H268.05c-1.519 0-2.75 1.3-2.75 2.903 0 1.604 1.231 2.904 2.75 2.904h99.887c1.519 0 2.75-1.3 2.75-2.904 0-1.603-1.231-2.903-2.75-2.903z"></path>
      </g>
      <path
        fill="#DDE3E9"
        fillRule="evenodd"
        d="M445.765 292.319c3.635 0 6.581-3.111 6.581-6.949 0-3.837-2.946-6.948-6.581-6.948-3.634 0-6.581 3.111-6.581 6.948 0 3.838 2.947 6.949 6.581 6.949z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M445.765 288.348c1.558 0 2.821-1.333 2.821-2.977 0-1.645-1.263-2.978-2.821-2.978-1.558 0-2.82 1.333-2.82 2.978 0 1.644 1.262 2.977 2.82 2.977z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#DDE3E9"
        d="M482.474 34.74c0-1.644-1.263-2.977-2.82-2.977-1.558 0-2.821 1.333-2.821 2.978V53.6c0 1.645 1.263 2.978 2.821 2.978 1.557 0 2.82-1.333 2.82-2.978V34.74zM495.636 18.86c0-1.645-1.263-2.978-2.82-2.978-1.558 0-2.821 1.333-2.821 2.977V53.6c0 1.645 1.263 2.978 2.821 2.978 1.557 0 2.82-1.333 2.82-2.978V18.86zM508.798 10.918c0-1.644-1.263-2.977-2.82-2.977-1.558 0-2.821 1.333-2.821 2.978v42.68c0 1.646 1.263 2.979 2.821 2.979 1.557 0 2.82-1.334 2.82-2.978V10.919zM521.96 18.86c0-1.645-1.263-2.978-2.82-2.978-1.558 0-2.821 1.333-2.821 2.977V53.6c0 1.645 1.263 2.978 2.821 2.978 1.557 0 2.82-1.333 2.82-2.978V18.86z"
      ></path>
      <path
        fill="#C5CFD6"
        d="M535.122 2.978c0-1.645-1.262-2.978-2.82-2.978-1.558 0-2.821 1.333-2.821 2.978V53.6c0 1.645 1.263 2.978 2.821 2.978 1.558 0 2.82-1.333 2.82-2.978V2.978z"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M14.28 303.988c0-11.057 8.464-20.106 18.803-20.106v20.106l-12.85 14.634c-3.655-3.578-5.953-8.827-5.953-14.634z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#AFB9C5"
        fillRule="evenodd"
        d="M32.586 303.733h18.988c0 5.183-2.002 10.039-5.168 13.567-3.481 3.859-8.44 6.285-13.82 6.285-4.957 0-9.495-2.093-12.977-5.402l12.977-14.45z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M51.886 303.733c0-10.916-8.463-19.851-18.803-19.851v19.851h18.803zm-28.204-19.851l7.52 17.866-16.922-7.94c1.674-4.632 5.432-8.16 9.402-9.926z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M626.097 312.844c-1.806 2.025-4.365 3.297-7.212 3.297-5.469 0-9.903-4.666-9.903-10.422s4.434-10.423 9.903-10.423v-19.355c-15.558 0-28.294 13.403-28.294 29.778 0 16.374 12.736 29.777 28.294 29.777 8.173 0 15.56-3.638 20.594-9.427l-13.382-13.225z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#AFB9C5"
        fillRule="evenodd"
        d="M618.796 275.941v19.355c5.452 0 9.871 4.667 9.871 10.423H647c0-16.375-12.695-29.778-28.204-29.778z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M628.288 305.719c0 3.381-1.509 6.387-3.851 8.329l11.528 15.493c6.709-5.547 11.035-14.146 11.035-23.822h-18.712z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F2F2F2"
        fillRule="evenodd"
        d="M446.796 39.211c-1.389 1.553-3.358 2.528-5.547 2.528-4.208 0-7.618-3.578-7.618-7.99 0-4.414 3.41-7.991 7.618-7.991v-14.84c-11.969 0-21.765 10.276-21.765 22.83 0 12.554 9.796 22.83 21.765 22.83 6.286 0 11.968-2.79 15.841-7.228L446.796 39.21z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#AFB9C5"
        fillRule="evenodd"
        d="M441.108 10.918v14.84c4.18 0 7.568 3.577 7.568 7.99h14.055c0-12.554-9.732-22.83-21.623-22.83z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#C5CFD6"
        fillRule="evenodd"
        d="M448.698 33.748c0 2.537-1.132 4.791-2.889 6.247l8.645 11.62c5.034-4.16 8.277-10.609 8.277-17.867h-14.033z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#A7CAF0"
        d="M326.233 264.514a277.16 277.16 0 01-28.308-17.152c-2.144-1.419-4.034-2.67-5.641-3.673-5.73-2.498-10.911-6.215-15.221-10.918-12.344-14.006-6.233-30.453 11.921-26.681h.178c.386.198.781.417 1.176.645 1.88 1.092 4.033 2.531 6.374 4.219 3.46 2.501 7.314 5.548 11.282 8.814 12.222 10.144 25.177 22.363 29.276 26.661 8.922 9.449 5.058 26.304-11.037 18.085z"
      ></path>
      <path
        stroke="#83ADDF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M297.925 247.362c-2.143-1.419-4.033-2.67-5.641-3.673a54.506 54.506 0 01-5.904-3.156"
      ></path>
      <path
        fill="#C3DCF8"
        d="M296.731 211.033c-.696.129-1.401.278-2.106.486-3.148.782-6.053 2.404-8.439 4.71-2.385 2.306-4.169 5.22-5.184 8.462a35.054 35.054 0 01-3.958 8.08c-12.344-14.006-6.233-30.453 11.922-26.681h.178c.386.198.78.417 1.175.645 1.965 1.171 4.071 2.611 6.412 4.298z"
      ></path>
      <path
        fill="#E18D76"
        d="M326.233 264.514a277.377 277.377 0 01-28.308-17.152c.423-12.586 2.426-23.256 10.032-27.515 12.222 10.145 25.177 22.363 29.276 26.661 8.959 9.37 5.095 26.225-11 18.006z"
      ></path>
      <path
        fill="#83ADDF"
        d="M294.54 247.57c2.238 2.105 6.751 4.636 7.935 4.11 0 0-3.14-6.363 1.241-17.361 4.381-10.998 8.151-13.171 8.151-13.171s-2.341-3.762-5.575-4.537c0 0-13.19 6.899-11.752 30.959z"
      ></path>
      <path
        fill="#E18D76"
        d="M329.617 265.943c6.732 6.373 16.265 3.574 28.44-20.675 12.175-24.249 13.801-28.656 13.801-28.656s10.032.694 10.878-.695c.846-1.39-2.435-1.777-4.645-2.73-2.209-.953-2.82-1.836-1.626-2.392 1.194-.556 9.326-.606 11.122-.556 1.796.05 2.952-1.558 2.745-2.501 0 0 2.821.397 3.422-2.154.367-1.688-3.271-.993-3.271-.993s.846-1.171-.104-1.618c-.949-.446-15.315-.228-19.264.159-3.948.387-5.64 1.459-10.05 6.521-4.409 5.063-25.534 30.612-28.627 35.158-3.093 4.546-10.013 14.323-2.821 21.132z"
      ></path>
      <path
        stroke="#C87862"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M339.912 235.242c-3.591 4.447-6.468 8.1-7.474 9.569M375.628 216.75c2.942.06 6.581 0 7.107-.853.846-1.39-2.435-1.777-4.644-2.73-2.209-.953-2.821-1.836-1.627-2.392 1.194-.556 9.327-.606 11.122-.556 1.796.049 2.952-1.558 2.746-2.501 0 0 2.82.397 3.422-2.154.366-1.688-3.272-.993-3.272-.993s.846-1.171-.103-1.618a40.42 40.42 0 00-6.534-.248M371.858 216.611l.658-2.154M390.332 207.737l-11.225.249M390.482 204.621l-11.62.933"
      ></path>
      <path
        fill="#A7CAF0"
        d="M303.473 503.937s22.563 14.968 21.576 17.271c-.987 2.303-34.992 0-37.38 0-3.977 0-1.881-20.328-1.881-20.328l17.685 3.057zM229.05 503.669s-20.599 13.678-19.743 15.772c.855 2.095 31.965 0 34.137 0 3.629 0 1.711-18.561 1.711-18.561l-16.105 2.789z"
      ></path>
      <path
        fill="#2C59A6"
        d="M303.472 503.937h-19.085l-33.385-172.493s-6.919 51.794-7.069 62.673c-.151 9.926 1.128 93.532 1.344 107.766v2.054h-16.584c-.179-1.697-8.161-75.983-9.533-96.5-1.166-17.628-2.821-35.733-3.441-49.341a285.964 285.964 0 01-.245-6.82c-.31-13.975 1.693-42.84 8.001-62.771l45.23-1.162 10.464-.267c-1.231 7.99 0 17.251 1.081 24.239 1.082 6.987 23.222 192.622 23.222 192.622z"
      ></path>
      <path
        fill="#0E1A78"
        d="M239.947 302.877c-12.222-5.608-15.983 7.018-12.523 21.053 3.027 12.487-1.88 20.845-11.714 34.136a285.964 285.964 0 01-.245-6.82c-.31-13.975 1.692-42.84 8.001-62.771l45.23-1.161c.302.687.566 1.393.79 2.114 2.52 8.05-17.355 19.067-29.539 13.449z"
      ></path>
      <path
        stroke="#2C59A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M271.874 294.748a24.514 24.514 0 004.352 7.018M219.893 302.877c2.453-.585 6.308-6.819 6.355-7.543M248.728 295.165c-.392 4.845-.523 9.71-.395 14.571M248.728 312.932c3.15-1.508 4.635-1.3 4.785-2.699.151-1.4.771-15.068.771-15.068M241.93 325.3c7.71-.913 12.354 2.74 12.354 2.74"
      ></path>
      <path
        fill="#E18D76"
        d="M263.234 209.613c2.764 12.467-7.352 19.098-10.746 19.098s-16.509-9.44-13.312-16.963c2.708-6.373 9.157-15.624 9.477-23.029.319-7.404 6.919-16.199 13.613-9.002 1.45 1.51 2.355 3.508 2.557 5.647.639 5.728-2.679 12.745-2.087 20.765.077 1.174.243 2.339.498 3.484z"
      ></path>
      <path
        fill="#BF796B"
        d="M262.736 206.129c-9.476-.526-10.172-9.419-6.797-17.132 1.946-4.446 5.425-5.062 8.884-3.633.64 5.728-2.679 12.745-2.087 20.765z"
      ></path>
      <path
        fill="#A7CAF0"
        d="M292.285 243.689a131.506 131.506 0 00-7.07 20.587c-3.432 12.903-4.701 23.981-5.255 25.628-.555 1.648-.941 1.985-8.029 1.866-7.089-.119-50.9 0-50.9 0s2.275-30.919 2.266-44.2a37.289 37.289 0 00-.226-5.012c-.808-5.142-4.343-17.867-4.005-25.024.122-2.461.687-4.278 1.965-4.963 5.481-2.858 12.579-3.97 17.609-4.238h1.664c.105.135.196.282.273.437.376.645.714 1.598 1.307 2.481 1.306 1.985 5.424 4.576 8.179 4.437 2.755-.139 2.331 6.67 4.014 7.186 1.683.517 1.768-2.114 2.247-5.816.48-3.702 6.816-7.812 6.816-7.812s0-.119-.065-.317c-.066-.199 0-.268-.066-.427a57.6 57.6 0 013.046-1.698c6.167-3.186 12.861-4.963 20.796-1.717l.733.318c.48.228.94.466 1.363.715 2.289 1.254 4.232 3.11 5.641 5.389 6.158 10.006.235 25.897-2.303 32.18z"
      ></path>
      <path
        fill="#C3DCF8"
        d="M294.626 211.519c-3.148.783-6.054 2.404-8.439 4.71s-4.17 5.22-5.184 8.462a35.054 35.054 0 01-3.958 8.08c-8.866 13.459-25.478 18.006-34.193 4.873a69.9 69.9 0 00-7.361-9.399c-4.814-5.112-10.342-9.013-16.444-10.75.123-2.462.687-4.278 1.965-4.963 5.481-2.859 12.579-3.97 17.609-4.238h1.664c.105.135.196.281.273.436.376.645.714 1.598 1.307 2.482 1.307 1.985 5.424 4.576 8.179 4.437 2.755-.139 2.332 6.67 4.014 7.186 1.683.516 1.768-2.114 2.247-5.817.48-3.702 6.835-7.772 6.835-7.772s0-.119-.066-.317c-.065-.199 0-.268-.065-.427a57.58 57.58 0 013.046-1.697c6.167-3.187 12.861-4.963 20.796-1.718l.733.318c.48.228.94.467 1.363.715 2.304 1.251 4.26 3.111 5.679 5.399z"
      ></path>
      <path
        stroke="#683F1D"
        strokeMiterlimit="10"
        d="M242.89 217.544s8.386 24.448 13.068 30.532c0 0 5.801-23.306 6.271-34.244"
      ></path>
      <path
        fill="#fff"
        d="M265.613 221.108c-3.319-.705-3.385-5.38-6.374-4.427-2.99.953-.649 8.05-5.152 8.05-4.504 0-2.576-6.313-4.908-6.522-2.331-.208-4.748 3.405-8.386 5.301-3.638 1.896-6.327-12.04-2.097-15.157.79-.576 1.251-.477 1.627 0 .376.476.837 1.806 1.579 2.918 1.307 1.985 5.425 4.576 8.18 4.437 2.754-.139 2.331 6.67 4.014 7.186 1.683.516 1.768-2.114 2.247-5.816.48-3.703 6.797-7.832 6.797-7.832s-.065-.308-.131-.744a22.775 22.775 0 01-.292-2.373 4.5 4.5 0 013.338.675 3.024 3.024 0 011.053 1.986c.573 3.384 1.814 13.012-1.495 12.318z"
      ></path>
      <path
        fill="#fff"
        stroke="#683F1D"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M262.219 247.57h-11.423v18.73h11.423v-18.73z"
      ></path>
      <path
        fill="#E18D76"
        d="M223.305 247.57a194.707 194.707 0 01-9.194 19.118c-4.344 7.802-9.712 15.881-15.043 19.534-3.76 2.601-7.446 3.047-10.746-.248a6.205 6.205 0 01-1.194-1.658c-3.591-7.117 3.347-22.71 10.248-35.525 4.936-9.151 9.843-16.874 10.887-19.117l15.042 17.896z"
      ></path>
      <path
        fill="#A7CAF0"
        d="M235.631 226.547c.012.582-.025 1.163-.113 1.737-1.222 8.616-12.222 19.286-12.222 19.286l-15.042-17.866s3.14-7.683 7.521-12.904a13.658 13.658 0 015.274-4.228c7.409-2.77 14.356 5.171 14.582 13.975z"
      ></path>
      <path
        fill="#C3DCF8"
        d="M235.631 226.547a9.995 9.995 0 01-.113 1.737c-4.813-5.112-10.341-9.013-16.443-10.75a23.615 23.615 0 00-3.309-.714 13.67 13.67 0 015.274-4.229c7.418-2.789 14.366 5.152 14.591 13.956z"
      ></path>
      <path
        fill="#83ADDF"
        d="M209.363 224.741s19.33 22.452 19.264 22.829c-.066.378-4.701 5.132-4.701 5.132s-17.581-20.269-17.581-21.311c0-1.042 2.031-5.955 3.018-6.65z"
      ></path>
      <path
        fill="#F9F9F9"
        stroke="#DDD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M257.649 293.776h-42.251l-9.589-59.099h42.241l9.599 59.099z"
      ></path>
      <path fill="#DDD" d="M234.362 237.684h-13.886l.761-5.707h10.511l2.614 5.707z"></path>
      <path
        fill="#DDD"
        d="M229.269 234.455c1.2-1.382.915-3.721-.638-5.226-1.553-1.506-3.785-1.606-4.986-.225-1.201 1.381-.916 3.721.637 5.226s3.786 1.606 4.987.225z"
      ></path>
      <path
        fill="#F9F9F9"
        stroke="#DDD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M228.029 233.253c.671-.771.51-2.079-.358-2.92-.868-.842-2.116-.899-2.786-.127-.671.771-.511 2.079.358 2.92.868.842 2.115.898 2.786.127z"
      ></path>
      <path
        fill="#E18D76"
        d="M257.001 272.336c-.329 1.151.235 1.29.451 2.173a3.656 3.656 0 01-.121 2.267c-.28.72-.78 1.32-1.421 1.704-1.1.784-10.661.645-13.03 1.25-1.552.388-11.536 3.445-20.214 6.313-4.437 1.469-8.536 2.889-10.99 3.871-7.239 2.909-21.924 3.772-24.538-5.588-1.429-5.132 3.61-10.194 10.191-13.569a35.358 35.358 0 0115.287-4.049h1.523c7.521-.179 24.848-1.718 26.146-1.787 1.297-.07 5.189-2.66 8.254-3.722a2.966 2.966 0 012.439.11c.379.191.717.461.994.793.277.333.488.721.619 1.141 0 0 4.598-2.531 5.641-.813 1.222 2.044-1.391 3.603-.761 4.963.968 2.213-.123 3.742-.47 4.943z"
      ></path>
      <path
        stroke="#C87862"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M248.511 261.179a2.963 2.963 0 012.439.11c.378.191.716.461.993.793.278.333.488.721.62 1.142 0 0 4.597-2.532 5.641-.814 1.222 2.044-1.392 3.603-.762 4.963.94 2.193-.075 3.722-.423 4.873-.348 1.152.235 1.291.451 2.174a3.649 3.649 0 01-.121 2.267 3.42 3.42 0 01-1.42 1.703M197.329 270.757a35.358 35.358 0 0115.287-4.049M252.554 263.223l-4.579 1.688"
      ></path>
      <path
        stroke="#2C59A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M270.153 199.003c3.516 0 6.365-3.009 6.365-6.72s-2.849-6.72-6.365-6.72c-3.515 0-6.364 3.009-6.364 6.72s2.849 6.72 6.364 6.72z"
      ></path>
      <path
        fill="#E18D76"
        d="M264.907 199.281c-5.641 1.31-11.188-5.202-13.736-9.291-7.314-11.782-.705-24.865 5.641-25.43 6.346-.566 10.276 4.357 13.284 14.521.179.606.339 1.211.489 1.807a29.61 29.61 0 01.771 5.28c.329 6.899-2.143 12.12-6.449 13.113z"
      ></path>
      <path
        fill="#604730"
        d="M252.356 165.939l-9.58 4.755c-1.702 2.154-4.814 2.829-5.256 0 0 0-3.262 2.511-5.293-.645-1.664-2.571.941-4.576.941-4.576a2.091 2.091 0 01-1.044-.218c-1.626-.874-1.081-3.901 1.175-3.296a4.456 4.456 0 01-2.622-2.093 4.892 4.892 0 01-.527-3.426c.071-.581.254-1.14.536-1.645a4.27 4.27 0 011.105-1.29 3.998 3.998 0 011.497-.728 3.894 3.894 0 011.647-.049c-1.391-2.055 0-4.874 2.068-3.583-.47-4.229 6.516-6.194 8.208-1.539 1.391-1.985 4.437-1.578 4.4 2.184.846-2.978 4.917-2.402 4.381 1.787 0 0 1.598-2.571 3.967 1.608 2.369 4.178-5.603 12.754-5.603 12.754z"
      ></path>
      <path
        fill="#473526"
        d="M252.356 165.94l-9.58 4.754c-1.702 2.154-4.814 2.829-5.255 0 0 0-3.263 2.511-5.294-.645-1.664-2.571.941-4.576.941-4.576 1.532 1.221 1.607 3.782 4.164 2.462 2.558-1.32-1.588-6.561.762-7.365 2.35-.804 2.003 3.553 3.864 3.265 1.862-.288-6.703-7.643-1.796-10.114 4.908-2.472 7.634 2.769 9.928 3.345 2.294.576 2.407-5.956-.451-7.236.846-2.978 4.917-2.402 4.381 1.786 0 0 1.598-2.57 3.968 1.608 2.369 4.179-5.632 12.716-5.632 12.716z"
      ></path>
      <path
        fill="#683F1D"
        d="M266.195 177.196c0 .196.055.388.159.551.103.163.25.29.421.366a.9.9 0 001.025-.216 1.037 1.037 0 00.204-1.081.983.983 0 00-.346-.446.908.908 0 00-.523-.167.919.919 0 00-.665.291 1.021 1.021 0 00-.275.702zM262.227 179.687c.514 0 .931-.44.931-.983 0-.542-.417-.982-.931-.982s-.93.44-.93.982c0 .543.416.983.93.983z"
      ></path>
      <path
        fill="#604730"
        d="M263.656 170.972c0 1.211 4.287 1.509 4.569.814.282-.695-4.569-3.732-4.569-.814zM255.957 174.347c0 .893 5.321-.506 4.973-2.62-.348-2.115-5.058 1.052-4.973 2.62z"
      ></path>
      <path
        stroke="#C87862"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M264.211 174.803c-.113 3.127 1.466 3.911 3.149 4.656.94.417 1.119 1.985.696 2.839"
      ></path>
      <path
        fill="#fff"
        stroke="#C87862"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M262.002 186.714c1.777.11 6.054-1.141 6.054-1.141s0 2.352-1.88 2.869c-1.88.516-3.892-.705-4.174-1.728z"
      ></path>
      <path
        fill="#E18D76"
        d="M252.177 188.958c-2.153 2.362-6.581 3.107-7.925-1.281-1.683-5.38 4.879-8.367 7.521-3.365l.404 4.646z"
      ></path>
      <path
        stroke="#C87862"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M250.776 190.079a5.567 5.567 0 01-2.369.765M245.916 186.665c.432-2.611 3.337-2.978 4.155-.616M249.668 185.285c-2.614-.774-3.084 3.445.216 2.333"
      ></path>
      <path
        fill="#604730"
        d="M264.606 168.352c-.281.046-.568.03-.844-.047a2.02 2.02 0 01-.755-.398 2.16 2.16 0 01-.542-.685 2.264 2.264 0 01-.237-.856c-.254 3.058-3.517 3.107-4.485.387-.103 2.978-2.82 3.296-3.855.993 1.326 3.097-1.541 4.308-3.29 3.693 1.88 1.141 1.579 3.077-.31 3.265a2.95 2.95 0 011.455.827c.402.414.686.939.82 1.516.385 1.836-1.476 3.394-1.476 3.394s.536-.119 1.551 1.321c1.015 1.439-1.091 2.789-1.824 2.273-.733-.517-.545-2.075-2.096-2.194a5.337 5.337 0 00-1.74.129 3.694 3.694 0 00-1.759.949 4.003 4.003 0 00-1.061 1.761c-2.232-3.325-3.128-7.446-2.494-11.466.634-4.02 2.747-7.612 5.878-9.994a9.795 9.795 0 016.581-1.737c1.741.131 3.451.55 5.068 1.241.545.218.846.377.846.377s-.179-3.167 2.82-2.869c3.338.338 1.693 3.633 1.693 3.633 2.2.278 2.143 4.149.056 4.487z"
      ></path>
      <path
        fill="#DD7272"
        d="M253.889 181.89c-.63 1.34.347 3.216 2.199 4.189 1.853.973 3.855.655 4.485-.685.63-1.34-.348-3.216-2.2-4.188-1.852-.973-3.855-.665-4.484.684zM271.328 186.169c-.369.054-.745.01-1.094-.127a2.235 2.235 0 01-.908-.658c-.874-1.151-.32-3.146 1.232-4.496.415 1.73.673 3.498.77 5.281z"
      ></path>
      <path
        fill="#2C59A6"
        d="M246.978 182.01a3.697 3.697 0 00-1.76.949 4.005 4.005 0 00-1.06 1.761c-2.304-16.537 5.481-21.639 10.003-23.197 1.74.131 3.45.55 5.067 1.24-10.868.447-12.664 11.147-12.25 19.247z"
      ></path>
      <path
        fill="#2C59A6"
        d="M254.678 162.227c-.451-3.434-.112-9.529 3.761-12.665 0 0 3.761 9.638-2.623 12.665h-1.138zM241.516 156.421c4.644-.358 10.266 3.841 11.761 6.491-.037 0-6.901 4.427-11.761-6.491z"
      ></path>
      <path
        stroke="#2C59A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M255.317 161.602a13.417 13.417 0 001.937-5.181M247.157 160.173c1.053.337 6.074 2.709 6.074 2.709"
      ></path>
      <path
        fill="#2C59A6"
        d="M252.883 161.443c1.88-1.3 3.638-.248 3.714.993.075 1.241-3.047 2.253-3.761 1.558a1.59 1.59 0 01-.428-.573 1.67 1.67 0 01-.144-.715 1.66 1.66 0 01.17-.707c.107-.219.261-.409.449-.556z"
      ></path>
      <path
        stroke="#2C59A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
        d="M252.883 161.443c1.88-1.3 3.638-.248 3.714.993.075 1.241-3.047 2.253-3.761 1.558a1.59 1.59 0 01-.428-.573 1.67 1.67 0 01-.144-.715 1.66 1.66 0 01.17-.707c.107-.219.261-.409.449-.556z"
      ></path>
      <path
        stroke="#2C59A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M244.75 189.325c-1.651.569-3.055 1.744-3.956 3.312a8.024 8.024 0 00-.953 5.234c.286 1.809 1.177 3.45 2.511 4.624a7.024 7.024 0 004.76 1.777c1.736-.028 3.406-.713 4.705-1.93 1.3-1.217 2.142-2.886 2.375-4.704a8.008 8.008 0 00-1.104-5.201c-.946-1.538-2.383-2.667-4.051-3.181"
      ></path>
    </svg>
  );
}

export default BackgroundIcon;
