import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

const LegendsTable = () => {
  return (
    <Flex alignItems={'center'} justifyContent="center" gap={2}>
      <Text
        fontSize={'10px'}
        fontWeight="normal"
        textwrap="break-word"
        bg="#A6F5FFFF"
        p={0.5}
        boxShadow={'md'}
        borderRadius={'3px'}
      >
        Preguntas
      </Text>
      <Text fontSize={'10px'} fontWeight="normal" bg="#38D300FF" p={0.5} boxShadow={'md'} borderRadius={'3px'}>
        Asignado
      </Text>

      <Text
        fontSize={'10px'}
        fontWeight="normal "
        bg="#B2FF99FF"
        p={0.5}
        boxShadow={'md'}
        borderRadius={'3px'}
        whiteSpace="nowrap"
      >
        Con dispo
      </Text>

      <Text
        fontSize={'10px'}
        fontWeight="normal"
        bg="#7F7F7FFF"
        p={0.5}
        boxShadow={'md'}
        borderRadius={'3px'}
        whiteSpace="nowrap"
      >
        Sin dispo
      </Text>

      <Text
        fontSize={'10px'}
        fontWeight="normal"
        bg="#F7FAFC"
        p={0.5}
        boxShadow={'md'}
        borderRadius={'3px'}
        whiteSpace="nowrap"
      >
        Sin Respuesta
      </Text>
    </Flex>
  );
};

export default LegendsTable;
