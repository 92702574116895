import {
  QUEUE_LIST_REQUEST,
  QUEUE_LIST_SUCCESS,
  QUEUE_LIST_FAIL,
  QUEUE_DETAILS_REQUEST,
  QUEUE_DETAILS_SUCCESS,
  QUEUE_DETAILS_FAIL,
} from '../constants/queueConstants';

export const queueListReducer = (state = { queues: [] }, action) => {
  switch (action.type) {
    case QUEUE_LIST_REQUEST:
      return { loading: true, queues: [] };

    case QUEUE_LIST_SUCCESS:
      return { loading: false, queues: action.payload };

    case QUEUE_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const queueDetailsReducer = (state = { queue: {} }, action) => {
  switch (action.type) {
    case QUEUE_DETAILS_REQUEST:
      return { loading: true, ...state };

    case QUEUE_DETAILS_SUCCESS:
      return { loading: false, queue: action.payload };

    case QUEUE_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
