import {
  ACTION_CREATE_REQUEST,
  ACTION_CREATE_SUCCESS,
  ACTION_CREATE_FAIL,
  ACTION_DELETE_REQUEST,
  ACTION_DELETE_SUCCESS,
  ACTION_DELETE_FAIL,
  ACTION_FORM_REQUEST,
  ACTION_FORM_SUCCESS,
  ACTION_FORM_FAIL,
} from '../constants/actionConstants';

export const actionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_CREATE_REQUEST:
      return { loading: true };

    case ACTION_CREATE_SUCCESS:
      return { loading: false, actionDetails: action.payload };

    case ACTION_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const actionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_REQUEST:
      return { loading: true };

    case ACTION_DELETE_SUCCESS:
      return { loading: false, success: true };

    case ACTION_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const actionFormReducer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_FORM_REQUEST:
      return { loading: true };

    case ACTION_FORM_SUCCESS:
      return { loading: false, actionForm: action.payload };

    case ACTION_FORM_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
