/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { CheckIcon, TimeIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Container, Text, Tooltip } from '@chakra-ui/react';

import { filterByStatus, filterStatusesByColor, filterStatusesByColorOpacity, statusesSteps } from '../utils';

const StepsLabel = ({ currentStatus, statusResponse, rejectReason }) => {
  const [stepsState, setStepsState] = useState([]);
  useEffect(() => {
    if (statusResponse.is_rejected) {
      setStepsState([
        {
          value: 'is_rejected',
          label: rejectReason,
          icon: <WarningTwoIcon color={'white'} />,
          bgColor: 'red.400',
        },
      ]);
      return;
    }
    if (!currentStatus) {
      setStepsState([
        {
          value: '',
          label: 'Sin antender',
          icon: <WarningTwoIcon color={'yellow.400'} />,
          bgColor: 'yellow.100',
        },
      ]);
      return;
    }

    const updatedSteps = statusesSteps.map((step) => {
      if (statusResponse[step.value]) {
        return {
          ...step,
          icon: <CheckIcon color={'white'} />,
          bgColor: 'teal.500',
        };
      } else {
        return { ...step, icon: <TimeIcon color="white" />, bgColor: 'gray' };
      }
    });

    setStepsState(updatedSteps);
  }, [currentStatus, statusResponse]);
  return (
    <Container>
      {stepsState.map((step, index) => {
        return (
          <Box
            key={index}
            mt={2}
            mb={4}
            display="flex"
            alignItems="center"
            gap={3}
            opacity={step.bgColor === 'gray' ? 0.5 : 1}
          >
            <Box
              rounded={4}
              bg={step.bgColor}
              m={0}
              w={5}
              h={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {step.icon}
            </Box>
            <Text mb={0} fontSize={14} cy={step.value}>
              {step.label}
            </Text>
          </Box>
        );
      })}
    </Container>
  );
};
const StatusesToolTip = ({ row }) => {
  const statusResponse = {
    is_approved: row?.original?.is_approved || row?.is_approved,
    suppliers_have_been_contacted: row?.original?.suppliers_have_been_contacted || row?.suppliers_have_been_contacted,
    is_approved_by_traffic: row?.original?.is_approved_by_traffic || row?.is_approved_by_traffic,
    assigned_transporter: !!row?.original?.assigned_transporter || row?.assigned_transporter,
    is_complete: row?.original?.is_complete || row?.is_complete,
    is_rejected: row?.original?.is_rejected || row?.is_rejected,
  };
  return (
    <Tooltip
      label={
        <StepsLabel
          currentStatus={row?.original?.status || row?.status}
          statusResponse={statusResponse}
          rejectReason={row?.original?.rejection_reason || row?.rejection_reason}
        />
      }
      hasArrow
      bg="white"
      color="black"
      arrowSize={15}
      fontSize={12}
      fontWeight="medium"
      placement="top"
      shadow="xl"
      border="1px solid gray.400"
      borderRadius="md"
    >
      <div>
        <Text
          color={() => filterStatusesByColor(row?.original?.status || row?.status)}
          fontWeight="medium"
          fontSize={12}
          textDecoration={'none'}
          border={'none'}
          isTruncated
          m={0}
          maxW={300}
          display={{ base: 'block', xl: 'none' }}
          cy={row?.original?.status || row?.status}
        >
          {filterByStatus(row?.original?.status || row?.status)}
        </Text>

        <Text
          bg={() => filterStatusesByColorOpacity(row?.original?.status || row?.status)}
          fontSize={8}
          textDecoration={'none'}
          border={'none'}
          textAlign={'center'}
          display={{ base: 'none', xl: 'block' }}
          isTruncated
          m={0}
          p={0}
          h={15}
          px={1}
          pt={'1px'}
          cy={row?.original?.status || row?.status}
          fontWeight="bold"
          color={() => filterStatusesByColor(row?.original?.status || row?.status)}
          borderRadius={100}
        >
          {filterByStatus(row?.original?.status || row?.status)}
        </Text>
      </div>
    </Tooltip>
  );
};

export default StatusesToolTip;
