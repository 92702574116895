/* eslint-disable no-unused-vars */
import axios from 'axios';

import { BASE_URL } from '../../appSettings';

const httpClient = axios.create({
  baseURL: BASE_URL,
});
httpClient.interceptors.request.use(
  async (config) => {
    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));
    if (config.headers) {
      config.headers['Content-Type'] = 'application/json';
      config.headers['Accept'] = 'application/json';
      config.headers['Authorization'] = `Bearer ${userInfoFromStorage?.access}`;
      return config;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const decodeToken = () => {
  const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));

  if (!userInfoFromStorage) return;
  const tokenSections = userInfoFromStorage.access?.split('.');
  const encodedPayload = tokenSections[1];
  const decodedPayload = atob(encodedPayload); // Decodifica en base64
  const decodedPayloadObj = JSON.parse(decodedPayload);
  return decodedPayloadObj;
};
const getUnixTimeStamp = (_) => Math.floor(new Date().getTime() / 1000);
const SECONDS = 4000;

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const userInfoFromStorage = JSON.parse(localStorage.getItem('userInfo'));
    const payload = decodeToken();
    if (error.response && error.response.status === 401) {
      const currentTimeStamp = getUnixTimeStamp();
      if (!userInfoFromStorage) return Promise.reject(error);
      if (currentTimeStamp > payload.exp - SECONDS) {
        try {
          const { data } = await axios.post(`${BASE_URL}/api/v1/token/refresh/`, {
            refresh: userInfoFromStorage?.refresh,
          });
          localStorage.setItem('userInfo', JSON.stringify(data));

          httpClient.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
          return httpClient.request(error.config);
        } catch (refreshError) {
          console.error('Error al actualizar el token:', refreshError);
          localStorage.removeItem('userInfo');
          window.location.href = '/login'; // Redirig
        }
      } else {
        console.error('Token expired, please login again');
        localStorage.removeItem('userInfo');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default httpClient;
