/**
 * BFBFBD GRAY
 * F55252 red
 * 9AD56B green con dispo
 * FFDA55 yellow
 * 6BD5D5 PENDING
 * 46A000 asignado
 *  //   status_colors = {
  //     "assigned": "dark_green", # dark green
  //     "accepted": "green", # green
  //     "rejected": "dark_gray", # dark gray
  //     "pending": "light_gray", # light gray
  //     "question": "light_blue", # light blue
  //     "not_sent": "white", # white
  // }
 */
export const assingProcesStatuses = (status) => {
  switch (status) {
    case 'pending':
      return {
        color: '#6BD5D5',
        text: 'Pendiente',
      };
    case 'assigned':
      return {
        color: '#46A000',
        text: 'Asignado',
      };
    case 'accepted':
      return {
        color: '#9AD56B',
        text: 'Con Dispo',
      };
    case 'rejected':
      return {
        color: '#F55252',
        text: 'Sin Dispo',
      };
    case 'question':
      return {
        color: '#FFDA55',
        text: 'Con preguntas',
      };
    case 'is_message':
      return {
        color: '#344D99',
        text: 'Asignar',
      };
    default:
      return {
        color: '#BFBFBD',
        text: 'No enviado',
      };
  }
};
