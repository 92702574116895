/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@chakra-ui/react';

import MessageContainer from '../../tripRequest/MessageContainer';
import TripRequestDetailsModal from '../../TripRequestDetails';
import ModalContainer from '../ModalContainer';
const customStyles = {
  backgroundColor: 'white',
  color: 'darkblue',
  // Add more custom styles as needed
};
const DetailsMessagesModal = ({ isOpen, onClose, currentTripId, refreshTripRequests, isABidSet }) => {
  const { error } = useSelector((state) => state?.tripRequestMessages);
const showMsg = !isABidSet && !error
  return (
    <ModalContainer
      customStyles={customStyles}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="100%"
      height={'650px'}
      overflowY="auto"
      maxWidth={!showMsg ? '650px' : '1220px'}
      bodyContent={
        <Box
          display={'flex'}
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
          justifyContent={{ base: 'items-center', lg: 'space-between' }}
          alignItems={{ base: 'center', lg: 'flex-start' }}
          gap={{ base: 2, lg: 4 }}
          width={'100%'}
        >
          {showMsg && <MessageContainer id={currentTripId} isOpen={isOpen} />}

          <TripRequestDetailsModal
            id={currentTripId}
            isOpen={true}
            onClose={onClose}
            style={{ zIndex: 500 }}
            postUpdateCallback={refreshTripRequests}
          />
        </Box>
      }
    />
  );
};

export default DetailsMessagesModal;
