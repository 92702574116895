import React from 'react';

import { Box, Flex, Grid, Button, Text, useDisclosure } from '@chakra-ui/react';

import GridTripSkeleton from '../utils/skeletons.jsx/GridTripSkeleton';
import StatusesToolTip from '../utils/tooltips/StatusesToolTip';
export const colorStatus = (color) => {
  switch (color) {
    case 'light_gray':
      return '#F7FAFC';
    case 'dark_green':
      return '#38D300FF';
    case 'green':
      return '#B2FF99FF';
    case 'dark_gray':
      return '#7F7F7FFF';
    case 'light_blue':
      return '#A6F5FFFF';
    case 'white':
      return '#FFFFFFFF';
    default:
      return '#FFFFFFFF';
  }
};

const CardText = ({ name, description, onClick }) => {
  return (
    <Box display={'flex'} fontSize="xs" m={0} p={1} pl={0} onClick={onClick} cursor={'pointer'}>
      <Text m={0} p={0} pr={1} fontWeight="bold">
        {name}:
      </Text>
      <Text m={0} p={0}>
        {description}
      </Text>
    </Box>
  );
};
const CardItem = ({ client, origin, destination, loading_date, unit, item }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box
      bg={'white'}
      border="1px solid"
      shadow={'md'}
      borderColor={'gray.200'}
      rounded={5}
      px={4}
      pt={2}
      pb={2}
      color="gray.900"
      fontWeight={'normal'}
      width={'100%'}
      m={0}
      mt={1}
    >
      <Flex gap={1}>
        <StatusesToolTip row={item} />
      </Flex>

      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Cliente" description={client} />
        <CardText name="Origen" description={origin} />
      </Grid>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Destino" description={destination} />
        <CardText name="Unidad" description={unit || ' -'} />
      </Grid>
      {isOpen && (
        <Box>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText name="Fecha de carga" description={`${loading_date || ''} / ${item.loading_time || ''}`} />
            <CardText
              name="Fecha de descarga"
              description={`${item?.unloading_date || ''} / ${item.unloading_time || ''}`}
            />
          </Grid>

          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText name="Número de confirmación" description={item?.folio_number || ' -'} />
          </Grid>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText name="Asignado a" description={item?.content_object?.supplier || ' -'} />
          </Grid>
        </Box>
      )}
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={2}>
        <Button
          onClick={onToggle}
          variant="ghost"
          color="gray.500"
          p={0}
          m={0}
          textAlign={'center'}
          size="sm"
          textDecoration={'underline'}
          _hover={{
            backgroundColor: 'transparent',
            color: 'blue.500',
          }}
        >
          {!isOpen ? 'Ver más' : 'Ver menos'}
        </Button>
        <Flex alignItems={'center'} gap={1}>
          <Text m={0} p={0} pr={1} fontWeight="medium" fontSize={'sm'}>
            Resumen respuestas
          </Text>
          <Box
            boxShadow={'md'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            w={10}
            h={10}
            fontSize={'xs'}
            bg={colorStatus(item?.status?.details?.[0]?.status_box?.color)}
          >
            {item?.status?.details?.length}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
const GridBidRequestSet = ({ data, isLoading }) => {
  return (
    <Box
      overflowY="auto"
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      height={{ base: '90%', xl: '0px' }}
      width={'100%'}
    >
      {isLoading ? (
        <GridTripSkeleton />
      ) : data.length === 0 ? (
        <Box
          ml="10px"
          w={'100%'}
          p={0}
          m={0}
          pt={4}
          height={240}
          justifyContent="center"
          alignItems="center"
          bg="white"
          shadow={'md'}
          rounded={5}
          mb={3}
          display={{ base: 'flex', xl: 'none' }}
        >
          <Text fontWeight="normal">No hay solicitudes de viaje</Text>
        </Box>
      ) : (
        <Grid
          templateColumns={{ base: '1fr' }}
          width={'100%'}
          display={{ base: 'block', xl: 'none' }}
          m={0}
          p={0}
          mb={4}
        >
          {data?.map((detail, i) => (
            <CardItem
              key={i}
              client={detail?.content_object?.client} //check
              origin={detail?.content_object?.origin} // check
              destination={detail?.content_object?.destination} //check
              requested_at={detail?.content_object?.requested_at} //date
              loading_date={detail?.content_object?.loading_date} //charge date
              unit={detail?.content_object?.unit_type} // check
              item={detail}
              index={i}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GridBidRequestSet;
