import {
  UNIT_TYPE_DROPDOWN_LIST_REQUEST,
  UNIT_TYPE_DROPDOWN_LIST_SUCCESS,
  UNIT_TYPE_DROPDOWN_LIST_FAIL,
  UNIT_TYPE_CREATE_REQUEST,
  UNIT_TYPE_CREATE_SUCCESS,
  UNIT_TYPE_CREATE_FAIL,
  UNIT_TYPE_DELETE_REQUEST,
  UNIT_TYPE_DELETE_SUCCESS,
  UNIT_TYPE_DELETE_FAIL,
} from '../constants/unitTypeConstants';

export const unitTypeDropdownListReducer = (state = { unitTypes: [] }, action) => {
  switch (action.type) {
    case UNIT_TYPE_DROPDOWN_LIST_REQUEST:
      return { loading: true, unitTypes: [] };

    case UNIT_TYPE_DROPDOWN_LIST_SUCCESS:
      return { loading: false, unitTypes: action.payload };

    case UNIT_TYPE_DROPDOWN_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const unitTypeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIT_TYPE_CREATE_REQUEST:
      return { loading: true };

    case UNIT_TYPE_CREATE_SUCCESS:
      return { loading: false, unitTypeDetails: action.payload };

    case UNIT_TYPE_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const unitTypeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIT_TYPE_DELETE_REQUEST:
      return { loading: true };

    case UNIT_TYPE_DELETE_SUCCESS:
      return { loading: false, success: true };

    case UNIT_TYPE_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
