import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <footer style={{ backgroundColor: '#F5F5F5', width: '100%' }}>
      <Container>
        <Row>
          <Col className="py-3 text-center">Copyright &copy; trebu</Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
