import { CONVERSATIONS_FAIL, CONVERSATIONS_REQUEST, CONVERSATIONS_SUCCESS } from '../constants/conversationsConstants';

export const conversationsForwadersReducer = (state = { conversations: {} }, action) => {
  switch (action.type) {
    case CONVERSATIONS_REQUEST:
      return { loading: true, ...state };

    case CONVERSATIONS_SUCCESS:
      return { loading: false, conversations: action.payload };

    case CONVERSATIONS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
