import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form } from 'formik'; // Import Formik components

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Flex,
  Text,
} from '@chakra-ui/react';

import { updateItemResponseBidRequest } from '../../actions/bidRequestSetAction';

const customStyles = {
  backgroundColor: 'white',
  color: 'darkblue',
  // Add more custom styles as needed
};
const EditResponseModal = ({ isOpen, onClose, isCurrentId, getBidRequests }) => {
  const dispatch = useDispatch();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    rate: getBidRequests?.rate || '',
    supplier_response: getBidRequests?.supplier_response || '',
    is_accepted: getBidRequests?.is_accepted || false,
  });
  const { loading } = useSelector((state) => state.bidRequestDetail);
  const [isAccepted, setIsAccepted] = useState(getBidRequests?.is_accepted);
  const onToggleChange = (e) => {
    const { checked } = e.target;
    setIsAccepted(checked);
  };
  const onSubmit = async (values) => {
    setUpdateLoading(true);
    const params = {
      ...values,
      is_accepted: isAccepted,
    };
    try {
      dispatch(updateItemResponseBidRequest(isCurrentId, params));
      onClose();
      toast.success('Respuesta actualizada correctamente');
    } catch (error) {
      console.error('Error updating bid request:', error);
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    setFormValues({
      rate: getBidRequests?.rate,
      supplier_response: getBidRequests?.supplier_response,
      is_accepted: getBidRequests?.is_accepted,
    });
  }, [getBidRequests, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent style={customStyles}>
        <ModalCloseButton />
        <ModalHeader mb={0}>
          <Text color="gray.900" mb={0} fontSize="lg">
            Cliente: {getBidRequests?.client}
          </Text>
        </ModalHeader>
        <ModalBody maxH="70vh" overflowY={'auto'} sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
          <Formik initialValues={formValues} onSubmit={onSubmit} enableReinitialize>
            <Form style={{ margin: 0, padding: 0 }}>
              <FormControl id="rate" color="gray.900" mb={3}>
                <FormLabel color="gray.900">Precio</FormLabel>
                <Field
                  style={{ width: '100%' }}
                  name="rate"
                  render={({ field, meta, helpers }) => {
                    return <Input {...field} {...meta} {...helpers} borderColor={'gray.400'} />;
                  }}
                />
              </FormControl>
              <FormControl id="supplier_response" color="gray.900" mb={3}>
                <FormLabel color="gray.900">Respuesta</FormLabel>
                <Field
                  style={{ width: '100%' }}
                  name="supplier_response"
                  render={({ field, meta, helpers }) => {
                    return <Input {...field} {...meta} {...helpers} borderColor={'gray.400'} />;
                  }}
                />
              </FormControl>
              <FormControl marginY={4} display="flex" alignItems="center" gap={2} color="gray.900">
                <Checkbox
                  name="is_accepted"
                  isChecked={isAccepted}
                  onChange={onToggleChange}
                  style={{
                    borderColor: 'gray',
                    backgroundColor: 'white',
                  }}
                />
                <FormLabel mt={1} color="gray.900">
                  Aceptado
                </FormLabel>
              </FormControl>
              <Flex pb={5} justifyContent={'space-between'}>
                <Button
                  bg="gray.200"
                  color="gray.900"
                  size="sm"
                  onClick={onClose}
                  isDisabled={updateLoading || loading}
                >
                  Cancelar
                </Button>
                <Button bg="blue.500" textColor="white" isLoading={loading || updateLoading} size="sm" type="submit">
                  Enviar
                </Button>
              </Flex>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditResponseModal;
