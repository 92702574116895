import toast from 'react-hot-toast';
import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_DROPDOWN_LIST_REQUEST,
  CLIENT_DROPDOWN_LIST_SUCCESS,
  CLIENT_DROPDOWN_LIST_FAIL,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAIL,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  CLIENT_DETAILS_REQUEST_CLEAN,
  CONTACTS_LIST_FAIL,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_LIST_REQUEST,
  CONTACTS_LIST_CLEAN,
  CLIENT_TRANSPORTATION_DROPDOWN_LIST_FAIL,
  CLIENT_TRANSPORTATION_DROPDOWN_LIST_SUCCESS,
  CLIENT_TRANSPORTATION_DROPDOWN_LIST_REQUEST,
} from '../constants/clientConstants';
import httpClient from './utils/axiosInstance';

export const listClients = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_LIST_REQUEST });

    const { data } = await httpClient.get('/api/v1/clients/');

    dispatch({
      type: CLIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listClientDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_DETAILS_REQUEST });

    const { data } = await httpClient.get(`/api/v1/clients/${id}`);

    dispatch({
      type: CLIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listAsignmentsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_DETAILS_REQUEST });

    const { data } = await httpClient.get(`/api/v1/channels/client/${id}/assignments/`);

    dispatch({
      type: CLIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listContacts = (id) => async (dispatch) => {
  try {
    dispatch({ type: CONTACTS_LIST_REQUEST });

    const { data } = await httpClient.get(`/api/v1/clients/${id}/contacts/`);

    dispatch({
      type: CONTACTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTS_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
export const cleanListContacts = () => async (dispatch) => {
  dispatch({ type: CONTACTS_LIST_CLEAN });
};
export const cleanContactsDetails = () => async (dispatch) => {
  dispatch({ type: CLIENT_DETAILS_REQUEST_CLEAN });
};
export const createClient = () => async (dispatch) => {
  try {
    dispatch({
      type: CLIENT_CREATE_REQUEST,
    });

    const { data } = await httpClient.post('/api/v1/clients/add/', {});

    dispatch({
      type: CLIENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const duplicateTrip =
  (id, url = 'trip-availabilities') =>
  async (dispatch) => {
    try {
      dispatch({
        type: CLIENT_CREATE_REQUEST,
      });

      const { data } = await httpClient.post(`api/v1/${url}/${id}/replicate/`);

      dispatch({
        type: CLIENT_CREATE_SUCCESS,
        payload: data,
      });
      toast.success('Viaje duplicado correctamente');
    } catch (error) {
      toast.success('Hubo un error al duplicar el viaje');

      dispatch({
        type: CLIENT_CREATE_FAIL,
        payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
      });
    }
  };

export const listClientsDropdown = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_DROPDOWN_LIST_REQUEST });

    const { data } = await httpClient.get(`/api/v1/clients/`);
    data?.sort((a, b) => a?.name?.localeCompare(b?.name));
    dispatch({
      type: CLIENT_DROPDOWN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DROPDOWN_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const listTransportationClientsDropdown = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_TRANSPORTATION_DROPDOWN_LIST_REQUEST });

    const { data } = await httpClient.get(`/api/v1/clients/transportation/`);

    data?.sort((a, b) => a?.name?.localeCompare(b?.name));
    dispatch({
      type: CLIENT_TRANSPORTATION_DROPDOWN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_TRANSPORTATION_DROPDOWN_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const deleteClient = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_DELETE_REQUEST });

    await httpClient.delete(`/api/v1/clients/delete/${id}`);
    dispatch({
      type: CLIENT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
