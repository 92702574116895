export const TRIP_AVAILABILITY_LIST_REQUEST = 'TRIP_AVAILABILITY_LIST_REQUEST';
export const TRIP_AVAILABILITY_LIST_REFRESH = 'TRIP_AVAILABILITY_LIST_REFRESH';
export const TRIP_AVAILABILITY_LIST_SUCCESS = 'TRIP_AVAILABILITY_LIST_SUCCESS';
export const TRIP_AVAILABILITY_LIST_FAIL = 'TRIP_AVAILABILITY_LIST_FAIL';

export const TRIP_AVAILABILITY_DETAILS_REQUEST = 'TRIP_AVAILABILITY_DETAILS_REQUEST';
export const TRIP_AVAILABILITY_DETAILS_SUCCESS = 'TRIP_AVAILABILITY_DETAILS_SUCCESS';
export const TRIP_AVAILABILITY_DETAILS_FAIL = 'TRIP_AVAILABILITY_DETAILS_FAIL';

export const TRIP_AVAILABILITY_DELETE_REQUEST = 'TRIP_AVAILABILITY_DELETE_REQUEST';
export const TRIP_AVAILABILITY_DELETE_SUCCESS = 'TRIP_AVAILABILITY_DELETE_SUCCESS';
export const TRIP_AVAILABILITY_DELETE_FAIL = 'TRIP_AVAILABILITY_DELETE_FAIL';

export const TRIP_AVAILABILITY_UPDATE_REQUEST = 'TRIP_AVAILABILITY_UPDATE_REQUEST';
export const TRIP_AVAILABILITY_UPDATE_SUCCESS = 'TRIP_AVAILABILITY_UPDATE_SUCCESS';
export const TRIP_AVAILABILITY_UPDATE_FAIL = 'TRIP_AVAILABILITY_UPDATE_FAIL';
