/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
import {
  CHAT_VISUALIZER_FAIL,
  CHAT_VISUALIZER_GROUP_FAIL,
  CHAT_VISUALIZER_GROUP_LOADING,
  CHAT_VISUALIZER_GROUP_SUCCESS,
  CHAT_VISUALIZER_LOADING,
  CHAT_VISUALIZER_SUCCESS,
  MESSAGE_DETAILS_SUCCESS,
} from '../constants/chatVisualizerConstants';

export const chatGroupVisualizerReducer = (state = { chats: [] }, action) => {
  switch (action.type) {
    case CHAT_VISUALIZER_GROUP_LOADING:
      return { loading: true, chats: [] };

    case CHAT_VISUALIZER_GROUP_SUCCESS:
      return {
        loading: false,
        chats: action.payload,
      };

    case CHAT_VISUALIZER_GROUP_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const messagesVisualizerReducer = (state = { messages: [] }, action) => {
  switch (action.type) {
    case CHAT_VISUALIZER_LOADING:
      return { loading: true, messages: [] };

    case CHAT_VISUALIZER_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload,
      };

    case MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        messageDetails: action.payload,
      };

    case CHAT_VISUALIZER_FAIL:
      return {
        loading: false,
        error: action?.payload,
        messages: [],
      };

    default:
      return state;
  }
};
