export const DECISION_TREE_LIST_REQUEST = 'DECISION_TREE_LIST_REQUEST';
export const DECISION_TREE_LIST_SUCCESS = 'DECISION_TREE_LIST_SUCCESS';
export const DECISION_TREE_LIST_FAIL = 'DECISION_TREE_LIST_FAIL';

export const DECISION_TREE_DETAILS_REQUEST = 'DECISION_TREE_DETAILS_REQUEST';
export const DECISION_TREE_DETAILS_SUCCESS = 'DECISION_TREE_DETAILS_SUCCESS';
export const DECISION_TREE_DETAILS_FAIL = 'DECISION_TREE_DETAILS_FAIL';

export const DECISION_TREE_CREATE_REQUEST = 'DECISION_TREE_CREATE_REQUEST';
export const DECISION_TREE_CREATE_SUCCESS = 'DECISION_TREE_CREATE_SUCCESS';
export const DECISION_TREE_CREATE_FAIL = 'DECISION_TREE_CREATE_FAIL';

export const DECISION_TREE_DELETE_REQUEST = 'DECISION_TREE_DELETE_REQUEST';
export const DECISION_TREE_DELETE_SUCCESS = 'DECISION_TREE_DELETE_SUCCESS';
export const DECISION_TREE_DELETE_FAIL = 'DECISION_TREE_DELETE_FAIL';
