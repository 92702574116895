import React from 'react';

import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import { assingProcesStatuses } from '../../utils/AssingProcesStatuses';

const PendingStatusProcess = ({ row, isMessageTrip, contactedSupplier, isAssignedATrasnporter }) => {
  const bidSet = row?.original?.bid_set || row?.bid_set;
  const status = bidSet && bidSet?.length === 0;
  const checkStatus = status || status === undefined ? 'is_message' : bidSet;
  const checkStatusData = bidSet;

  let assigned = 0;
  let question = 0;
  let pending = 0;
  let rejected = 0;
  let accepted = 0;

  checkStatus?.length > 0 &&
    checkStatusData.forEach((item) => {
      if (item.is_taken === true && item.item_status === 'assigned') {
        assigned++;
      }
      if (item.item_status === 'question') {
        question++;
      }
      if (item.item_status === 'pending' && item.is_taken === false) {
        pending++;
      }
      if (item.item_status === 'rejected') {
        rejected++;
      }
      if (item.item_status === 'accepted') {
        accepted++;
      }
      if (isAssignedATrasnporter) {
        assigned = 1;
      }
    });

  if (contactedSupplier && bidSet?.length === 0) {
    pending++;
  }

  let labelText = {
    text: '',
    number: '',
    textTooltip: 'Sin disponibilidad',
  };

  if (assigned > 0) {
    labelText = {
      text: `assigned`,
      number: assigned,
      textTooltip: `Asignado`,
    };
  } else if (accepted > 0) {
    labelText = {
      text: `accepted`,
      number: accepted,
      textTooltip: `Con dispo`,
    };
  } else if (question > 0) {
    labelText = {
      text: `question`,
      number: question,
      textTooltip: `Con preguntas`,
    };
  } else if (pending > 0) {
    labelText = {
      text: `pending`,
      number: pending,
      textTooltip: `Pendiente`,
    };
  } else if (rejected > 0) {
    labelText = {
      text: `rejected`,
      number: rejected,
      textTooltip: `Sin disponibilidad`,
    };
  } else if (isMessageTrip) {
    labelText = {
      text: `is_message`,
      number: 0,
      textTooltip: ``,
    };
  }
  if (labelText?.text === 'is_message') return null;
  return (
    <Tooltip hasArrow label={labelText?.textTooltip}>
      <Flex
        flexDirection={{ base: 'row-reverse', xl: 'column-reverse' }}
        justifyContent="center"
        alignItems={'center'}
        gap={1}
      >
        <Box rounded={'xl'} px={2} cursor={'pointer'} bg={assingProcesStatuses(labelText.text).color}>
          <Text textAlign={'center'} fontWeight={'bold'} fontSize="13px" color="white" m={0} cursor={'pointer'}>
            {assingProcesStatuses(labelText.text).text}
          </Text>
        </Box>
        {labelText?.number > 0 && !isAssignedATrasnporter && (
          <Box
            bg={assingProcesStatuses(labelText.text).color}
            rounded={'xl'}
            cursor={'pointer'}
            w={5}
            h={5}
            justifyContent="center"
            alignItems={'center'}
            display={'flex'}
          >
            <Text fontSize="10px" color="white" textAlign={'center'} fontWeight={'bold'} m={0}>
              {labelText?.number}
            </Text>
          </Box>
        )}
      </Flex>
    </Tooltip>
  );
};

export default PendingStatusProcess;
