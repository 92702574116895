import React from 'react';

import { AlertIcon, Alert } from '@chakra-ui/react';

function Message({ variant, children }) {
  return (
    <Alert status={variant}>
      <AlertIcon />
      {children}
    </Alert>
  );
}

export default Message;
