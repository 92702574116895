import React from 'react';

import { motion } from 'framer-motion';

import { Flex, Text } from '@chakra-ui/react';

import LogoIcon from '../../icons/LogoIcon';

const AnimatedText = motion(Text);
const AppLoader = () => {
  return (
    <Flex
      position="fixed"
      width="100%"
      height="100%"
      backgroundColor="rgba(255, 255, 255, 0.8)"
      justifyContent={'center'}
      m={0}
    >
      <AnimatedText
        fontSize="2xl"
        textAlign="center"
        mt="50vh"
        fontWeight={'900'}
        animate={{ y: [-5, 5, -5] }}
        transition={{ duration: 1, repeat: Infinity }}
      >
        <LogoIcon />
      </AnimatedText>
    </Flex>
  );
};

export default AppLoader;
