import React from 'react';
import { Box, Flex, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

const ChatSkeletons = () => {
  return (
    <Box padding="6" width={'60%'} boxShadow="lg" bg="white" pt={20}>
      <Flex alignItems="center">
        <SkeletonCircle size="10" />
        <SkeletonText ml="4" noOfLines={2} spacing="4" width="80%" />
      </Flex>
      <Flex alignItems="center" my={10} justifyContent={'flex-end'}>
        <SkeletonText mr="2" noOfLines={3} spacing="4" width="80%" />
        <SkeletonCircle size="10" />
      </Flex>
      <Flex alignItems="center">
        <SkeletonCircle size="10" />
        <SkeletonText ml="2" noOfLines={2} spacing="4" width="80%" />
      </Flex>
      <Flex alignItems="center" my={10} justifyContent={'flex-end'}>
        <SkeletonText mr="2" noOfLines={3} spacing="4" width="80%" />
        <SkeletonCircle size="10" />
      </Flex>
      <Flex alignItems="center" my={10} justifyContent={'flex-end'}>
        <SkeletonText mr="2" noOfLines={3} spacing="4" width="80%" />
        <SkeletonCircle size="10" />
      </Flex>
      <Flex alignItems="center">
        <SkeletonCircle size="10" />
        <SkeletonText ml="2" noOfLines={2} spacing="4" width="80%" />
      </Flex>
      <Flex alignItems="center" my={10}>
        <SkeletonCircle size="10" />
        <SkeletonText ml="2" noOfLines={3} spacing="4" width="80%" />
      </Flex>
    </Box>
  );
};

export default ChatSkeletons;
