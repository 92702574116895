export const MUNICIPALITY_LIST_REQUEST = 'MUNICIPALITY_LIST_REQUEST';
export const MUNICIPALITY_LIST_SUCCESS = 'MUNICIPALITY_LIST_SUCCESS';
export const MUNICIPALITY_LIST_FAIL = 'MUNICIPALITY_LIST_FAIL';

export const MUNICIPALITY_DROPDOWN_LIST_REQUEST = 'MUNICIPALITY_DROPDOWN_LIST_REQUEST';
export const MUNICIPALITY_DROPDOWN_LIST_SUCCESS = 'MUNICIPALITY_DROPDOWN_LIST_SUCCESS';
export const MUNICIPALITY_DROPDOWN_LIST_FAIL = 'MUNICIPALITY_DROPDOWN_LIST_FAIL';

export const MUNICIPALITY_DETAILS_REQUEST = 'MUNICIPALITY_DETAILS_REQUEST';
export const MUNICIPALITY_DETAILS_SUCCESS = 'MUNICIPALITY_DETAILS_SUCCESS';
export const MUNICIPALITY_DETAILS_FAIL = 'MUNICIPALITY_DETAILS_FAIL';

export const MUNICIPALITY_CREATE_REQUEST = 'MUNICIPALITY_CREATE_REQUEST';
export const MUNICIPALITY_CREATE_SUCCESS = 'MUNICIPALITY_CREATE_SUCCESS';
export const MUNICIPALITY_CREATE_FAIL = 'MUNICIPALITY_CREATE_FAIL';

export const MUNICIPALITY_DELETE_REQUEST = 'MUNICIPALITY_DELETE_REQUEST';
export const MUNICIPALITY_DELETE_SUCCESS = 'MUNICIPALITY_DELETE_SUCCESS';
export const MUNICIPALITY_DELETE_FAIL = 'MUNICIPALITY_DELETE_FAIL';

export const MUNICIPALITY_DROPDOWN_REQUEST = 'MUNICIPALITY_DROPDOWN_REQUEST';
export const MUNICIPALITY_DROPDOWN_SUCCESS = 'MUNICIPALITY_DROPDOWN_SUCCESS';
export const MUNICIPALITY_DROPDOWN_FAIL = 'MUNICIPALITY_DROPDOWN_FAIL';