/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import { getBidRequestsSet, refreshBidRequestsSet } from '../../../actions/bidRequestSetAction';
import httpClientFormData from '../../../actions/utils/axiosInstanceFormData';
import AppLayout from '../../../components/utils/layouts/AppLayout';
import BidRequestSetTemplate from '../../../templates/BidRequestSetTemplate';

const BidRequestSet = () => {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [getBidRequests, setGetBidRequests] = useState(null);
  const [suppliers, setSuppliers] = useState({});
  const dispatch = useDispatch();
  const { loading, bidRequestsSet, error } = useSelector((state) => state?.bidRequestSet);
  const { loading: isLoadingDetail } = useSelector((state) => state.bidRequestDetail);
  const bidRequestsSetFunc = () => dispatch(getBidRequestsSet());
  useEffect(() => {
    bidRequestsSetFunc();
  }, []);

  useEffect(() => {
    if (!!error || loading || isOpen) return;
    refreshBidRequestsSetFunc();
  }, [error, loading, isLoadingDetail, isOpen]);

  const refreshBidRequestsSetFunc = () => dispatch(refreshBidRequestsSet());
  useEffect(() => {
    const suppliersTmp = {};
    data.map((bidRequest) => {
      bidRequest.suppliers.map((supplier) => {
        if (!suppliersTmp[supplier.id]) {
          suppliersTmp[supplier.id] = {
            id: supplier.id,
            name: supplier.name,
          };
        }
      });
    });
    setSuppliers(suppliersTmp);
  }, [data]);

  useEffect(() => {
    if (bidRequestsSet?.length === 0) return;

    // const objectWithHighestId = bidRequestsSet.reduce((previous, current) => {
    //   return current.id > previous.id ? current : previous;
    // });

    setData(bidRequestsSet || []);
  }, [bidRequestsSet]);

  const [isLoadingUploaded, setIsLoadingUploaded] = useState(false);
  const [fileName, setFileName] = useState('');

  const handleFileInputChange = async (event) => {
    event.preventDefault();
    const file = event?.target?.files[0];
    setFileName(file?.name);
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    setIsLoadingUploaded(true);
    try {
      await httpClientFormData.post('api/v1/bid-request-set/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Archivo subido exitosamente');
      setIsLoadingUploaded(false);
      setFileName('');
      bidRequestsSetFunc();
      event.target.value = null;
    } catch (error) {
      setIsLoadingUploaded(false);
      toast.error('Error al subir archivo🥲');
      setFileName('');
    }
  };

  return (
    <AppLayout>
      <BidRequestSetTemplate
        data={data}
        suppliers={suppliers}
        setData={setData}
        isLoading={loading}
        isLoadingUploaded={isLoadingUploaded}
        fileName={fileName}
        handleFileInputChange={handleFileInputChange}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getBidRequests={getBidRequests}
        setGetBidRequests={setGetBidRequests}
      />
    </AppLayout>
  );
};

export default BidRequestSet;
