/* eslint-disable react/jsx-no-comment-textnodes */

import { Text, Box, Button } from '@chakra-ui/react';

const TextCell = ({ value }) => {
  return (
    <Text
      alignItems={'center'}
      value={value}
      variant="filled"
      size="sm"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      border="none" // Remove border
      outline="none" // Remove outline on focus
      fontFamily="Arial"
      m={0}
      fontSize="12px"
      verticalAlign={'middle'}
      w="100%"
    >
      {value || '-'}
    </Text>
  );
};
export const columns = (handleAcceptedBidRequest) => [
  {
    accessorKey: 'client', // client_name_tag
    header: 'Cliente',
    isInteractive: false,
    size: 170,
    cell: ({ row }) => {
      return <TextCell value={row?.original?.item?.content_object?.client} />;
    },
    enableColumnFilter: true,
    filterFn: 'includesString',
  },

  {
    accessorKey: 'origin',
    header: 'Origen',
    isInteractive: false,
    size: 170,
    cell: ({ row }) => {
      return <TextCell value={row?.original?.item?.content_object?.origin} />;
    },
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'destination',
    header: 'Destino',
    isInteractive: false,
    size: 170,
    cell: ({ row }) => {
      return <TextCell value={row?.original?.item?.content_object?.destination} />;
    },
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'unloading_date',
    header: 'Fecha descarga',
    isInteractive: false,
    size: 170,
    cell: ({ row }) => {
      return (
        <>
          <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
            {row?.original?.item?.content_object?.unloading_date || '-'}
          </Text>
          <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
            {row?.original?.item?.content_object?.unloading_time?.split(':').slice(0, 2).join(':')}
          </Text>
        </>
      );
    },
  },
  {
    accessorKey: 'folio_number',
    header: 'N. confirmación',
    isInteractive: false,
    size: 170,
    cell: ({ row }) => {
      return <TextCell value={row?.original?.item?.content_object?.folio_number} />;
    },
    enableColumnFilter: true,
    filterFn: 'includesString',
  },

  {
    accessorKey: 'loading_date',
    header: 'Fecha Carga',
    isInteractive: false,
    size: 170,
    cell: ({ row }) => {
      return (
        <>
          <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
            {row?.original?.item?.content_object?.loading_date}
          </Text>
          <Text m={0} fontSize="12px" verticalAlign={'middle'} whiteSpace="nowrap" fontWeight={'normal'}>
            {row?.original?.item?.content_object?.loading_time}
          </Text>
        </>
      );
    },
  },

  {
    accessorKey: 'unit_type',
    header: 'Unidad',
    isInteractive: false,
    size: 170,
    cell: ({ row }) => {
      return <TextCell value={row?.original?.item?.content_object?.unit_type} />;
    },
    enableColumnFilter: true,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'rate',
    header: 'Tarifa',
    isInteractive: true,
    size: 170,
    cell: () => {
      return <Box></Box>;
    },
    enableColumnFilter: true,
    filterFn: 'includesString',
  },

  {
    accessorKey: 'respond',
    header: 'Aceptación',
    size: 130, // Set the desired size for the column
    isInteractive: true,
    cell: ({ row }) => {
      return (
        <Button
          size="sm"
          colorScheme={row?.original?.is_accepted ? 'green' : 'blue'}
          m={0}
          borderRadius={4}
          onClick={() => handleAcceptedBidRequest(row.original.id, row?.index)}
          // isLoading={isAcceptedLoading}
          isDisabled={row?.original?.button_settings?.disabled}
        >
          {row?.original?.button_settings?.text || 'ACEPTAR'}
        </Button>
      );
    },
  },
];
