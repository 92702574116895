import React from 'react';

import { Box, Skeleton } from '@chakra-ui/react';

const GridTripSkeleton = () => {
  return (
    <Box
      width="100%"
      display={{ base: 'block', lg: 'none' }}
      justifyContent={{ base: 'center' }}
      gap={{ base: 4, lg: 0 }}
    >
      <Box flex="none" width={{ base: '100%', lg: '50%' }} marginRight="10px" bg="gray.200" borderRadius={10} p={2}>
        <Skeleton height="200px" />
        <Skeleton height="200px" my={3} />
        <Skeleton height="200px" />
      </Box>
    </Box>
  );
};

export default GridTripSkeleton;
