import React from 'react';

import { Box, Skeleton } from '@chakra-ui/react';

const DetailSkeleton = () => {
  return (
    <Box flex="none" width={{ base: '100%' }} marginRight="10px" height="600px">
      <Skeleton height="50px" bg="gray.200" />
      <Skeleton height="400px" bg="gray.200" mt={5} />
      <Skeleton height="60px" bg="gray.200" mt={5} />
    </Box>
  );
};

export default DetailSkeleton;
