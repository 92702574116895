/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';

import { ArrowUpIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Collapse, Container, IconButton, Text, Textarea } from '@chakra-ui/react';

import { sendCommentTripRequest } from '../../actions/tripRequestActions';

const BurbleMessage = ({ text, author, created_at, expandedCommentId, handleToggle, id }) => {
  const charLimit = 100;
  return (
    <Box
      overflowY="auto"
      display="flex"
      bg="blue.50"
      maxHeight="300px"
      px={2}
      py={1}
      borderBottom="1px solid"
      borderColor="blue.300"
    >
      <Box w="100%" m={0}>
        <Text fontWeight="normal" color="gray.900" fontSize={14} m={0} p={0} isTruncated={id !== expandedCommentId}>
          {text}
        </Text>
        {text.length > charLimit && (
          <Button
            onClick={() => handleToggle(id)}
            border="none"
            color={'blue.500'}
            bg="transparent"
            m={0}
            py={1}
            px={1}
            textDecoration={'underline'}
            fontSize={10}
            _hover={{
              backgroundColor: 'transparent',
            }}
          >
            {id !== expandedCommentId ? 'Show More' : 'Show Less'}
          </Button>
        )}
        <Box
          mt={1}
          w="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="flex-end"
          m={0}
          p={0}
        >
          <Text fontWeight="normal" color="gray.900" fontSize="10px" m={0} p={0}>
            {created_at}
          </Text>
          <Text fontWeight="normal" color="gray.900" fontSize="10px" m={0} p={0}>
            {author?.email}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
const CommentsDetailTrip = ({ tripRequest, id, isLoading, setIsloading }) => {
  const [expandedCommentId, setExpandedCommentId] = useState(null);
  const [show, setShow] = useState(false);
  const [outgoinComment, setOutgoingComment] = useState('');
  const [comments, setComments] = useState([]);
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  const dispatch = useDispatch();
  const commentsReverse = tripRequest?.comments?.length > 0 ? tripRequest?.comments : [];
  const shortArray = comments?.slice(0, 5);
  const shortTabletArray = comments?.slice(0, 1);

  const validateLength = commentsReverse?.length > 5;
  const validateLengthTablet = commentsReverse?.length > 0;

  useEffect(() => {
    if (commentsReverse?.length === 0) return;
    setComments(commentsReverse);
  }, [commentsReverse]);

  const handleToggle = (id) => {
    setExpandedCommentId(id !== expandedCommentId ? id : null);
  };

  const handleOutgoingMessageChange = (e) => {
    setOutgoingComment(e.target.value);
  };

  const handleSendComments = async () => {
    setIsloading(true);
    try {
      const messageData = {
        text: outgoinComment,
      };
      await dispatch(sendCommentTripRequest(id, messageData));
      setComments((prevComments) => [
        {
          text: outgoinComment,
          created_at: format(new Date(), 'yyyy-MM-dd HH:mm'),
          author: { email: userDetails?.email },
        },
        ...prevComments,
      ]);
      setOutgoingComment('');
      setIsloading(false);
    } catch (error) {
      console.error('Error sending comment:', error);
      console.error('Error sending comment:', error.response.data);
      setIsloading(false);
    }
  };

  return (
    <Box mb={6}>
      <Text fontSize="2xl" fontWeight="bold" color="gray.900" m={0} p={0} mb={2}>
        Comentarios
      </Text>
      {comments?.length > 0 && (
        <>
          {!show && (
            <>
              <Box display={{ base: 'none', lg: 'block' }}>
                {shortArray?.map((comment) => (
                  <BurbleMessage
                    text={comment.text}
                    author={comment.author}
                    created_at={comment.created_at}
                    expandedCommentId={expandedCommentId}
                    handleToggle={handleToggle}
                    id={comment.id}
                    show={show}
                    key={comment.id}
                  />
                ))}
              </Box>
              <Box display={{ base: 'block', lg: 'none' }}>
                {shortTabletArray?.map((comment) => (
                  <BurbleMessage
                    text={comment.text}
                    author={comment.author}
                    created_at={comment.created_at}
                    expandedCommentId={expandedCommentId}
                    handleToggle={handleToggle}
                    id={comment.id}
                    key={comment.id}
                    show={show}
                  />
                ))}
              </Box>
            </>
          )}

          <Collapse in={show}>
            {comments?.map((comment) => (
              <BurbleMessage
                text={comment.text}
                author={comment.author}
                created_at={comment.created_at}
                expandedCommentId={expandedCommentId}
                handleToggle={handleToggle}
                id={comment.id}
                show={show}
                key={comment.id}
              />
            ))}
          </Collapse>
          {validateLengthTablet && !validateLength && (
            <Box display={{ base: 'block', lg: 'none' }} mt={0}>
              <Button
                onClick={() => setShow(!show)}
                bg="transparent"
                _hover={{
                  backgroundColor: 'transparent',
                }}
                px={2}
                gap={2}
                mb={0}
                fontSize={14}
              >
                Ver todos los comentarios{`(${comments?.length})`}
                {show ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </Box>
          )}
          {validateLength && (
            <Box display={{ base: 'none', lg: 'block' }}>
              <Button
                onClick={() => setShow(!show)}
                bg="transparent"
                _hover={{
                  backgroundColor: 'transparent',
                }}
                px={2}
                gap={2}
                mb={3}
                fontSize={14}
              >
                Ver todos los comentarios{`(${comments?.length})`}
                {show ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </Box>
          )}
        </>
      )}

      <Container
        display="flex"
        alignItems="center"
        w="100%"
        m={0}
        p={0}
        border="1px solid"
        borderColor={'gray.400'}
        borderRadius={10}
        pr={2}
        mt={{ base: 0, lg: 3 }}
      >
        <Textarea
          value={outgoinComment}
          onChange={handleOutgoingMessageChange}
          resize="vertical"
          minHeight="10px"
          color={'gray.900'}
          m={0}
          p={1}
          pl={2}
          mx={0}
          cy="comment-trip-detail-input"
          mr="5px"
          autoFocus={false}
          _hover={{
            border: 'none',
            outline: 'none',
          }}
          _focus={{
            border: 'none',
            outline: 'none',
            outlineOffset: 'none',
          }}
          _focusVisible={{
            borderColor: 'transparent',
          }}
          border={'none'}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setOutgoingComment(e.target.value);
              handleSendComments();
            }
          }}
        />

        <IconButton
          backgroundColor="orange.300"
          color={'white'}
          aria-label="Call Segun"
          size="sm"
          icon={<ArrowUpIcon fontSize="xl" fontWeight="bold" />}
          isLoading={isLoading}
          onClick={handleSendComments}
          cy="comment-trip-detail-button"
        />
      </Container>
    </Box>
  );
};

export default CommentsDetailTrip;
